import { Box, useTheme } from '@mui/material'
import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react'
import AnnouncementBox from '../../components/AnnouncementBox';
import Header from '../../components/Header';
import { UserContext } from '../../context/UserContext';
import { tokens } from '../../theme';


function Announcements({ announcementType }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user, token } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [announcements, setAnnouncements] = useState([]);

    const fetchAnnouncements = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/announcement/getAllAnnouncements?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setAnnouncements([]);
                setLoading(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setAnnouncements(response.data.announcements.filter(({ type }) => type === announcementType));
                    setLoading(false);
                }
            });
    }

    useEffect(() => {
        fetchAnnouncements();
    }, []);


    return (
        <Box mt="20px">
            <Box ml="20px" display="flex">
                <Header title="Duyurular" subtitle="Güncel duyuruları buradan görüntüleyebilirsiniz." />
            </Box>
            <Box ml="20px" mr="100px">
                {announcements && announcements.length > 0 &&
                    (announcements.map((announcement) =>
                        <AnnouncementBox key={announcement.id} announcement={announcement} />
                    ))}
            </Box>
        </Box>
    )
}

export default Announcements;