import { Box, Button, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useContext, useState } from 'react';
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import axios from 'axios';
import { useEffect } from 'react';
import QuestionBox from '../../../../components/QuestionBox';
import QuestionDialog from './questionDialog';

function Questions() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, token } = useContext(UserContext);
  const [questions, setQuestions] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
    fetchQuestions();
  }

  const fetchQuestions = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandQuestions?token=${token}&brandId=${user.id}`, {
      headers: {
        "content-type": "application/json"
      },
    })
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setQuestions(response.data.questions)
        }
      });
  }

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <Box m="20px 0 20px 20px">
      <Box>
        <Header title="Sorular" subtitle="Sorular sayfasına hoşgeldiniz" />
      </Box>
      <Box>
        <Box mb="20px" ml="10px">
          <Button sx={{ background: colors.primary[400], borderRadius: "10px", p: "10px" }} startIcon={<AddCircleOutlineOutlinedIcon sx={{ color: colors.greenAccent[400], width: "30px", height: "30px" }} />} onClick={() => { setOpenDialog(true) }}>
            <Typography variant='h6' color={colors.greenAccent[400]}>Yeni Soru Ekle</Typography>
          </Button>
        </Box>
        <Box>
          {questions.map((q) => <QuestionBox key={q.id} question={q} fetchQuestions={fetchQuestions} />)}
        </Box>
      </Box>
      <QuestionDialog open={openDialog} handleClose={handleDialogClose} type="new" question={null} />
    </Box>

  )
}

export default Questions