import { Box, FormControl, IconButton, InputAdornment, InputLabel, Select, TextField, Tooltip, Typography, useTheme, MenuItem } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfiniteScroll from 'react-infinite-scroll-component';

function Users() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(UserContext);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const influencer = window.location.pathname.split("/") && window.location.pathname.split("/").length === 5 ? { id: window.location.pathname.split("/")[window.location.pathname.split("/").length - 2], name: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1].replaceAll("%20", " ") } : null;
  const brand = window.location.pathname.split("/") && window.location.pathname.split("/").length === 6 ? { id: window.location.pathname.split("/")[window.location.pathname.split("/").length - 2], name: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1].replaceAll("%20", " ") } : null;
  const [refs, setRefs] = useState([]);
  const [refId, setRefId] = useState(-1);

  useEffect(() => {
    fetchUsers(0);
    if (influencer || brand) {
      fetchRefs();
    }
  }, []);

  useEffect(() => {
    if (influencer || brand) {
      setUsers([]);
      fetchUsers(0)
    }
  }, [refId]);

  const fetchUsers = (startIndex) => {
    if (influencer) {
      axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencer/getInfluencerUsers?token=${token}&${refId && refId !== -1 ? `refId=${refId}` : `influencerId=${influencer.id}`}&searchKey=${searchKey}&startIndex=${startIndex}&limit=50`)
        .catch((err) => {
          console.log("err: " + err);
          setHasMore(false);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 200) {
            setHasMore(response.data.users && response.data.users.length === 50);
            if (startIndex === 0) {
              setUsers([...response.data.users]);
            } else {
              setUsers((prev) => [...prev, ...response.data.users]);
            }
          }
        });
    } else if (brand) {
      axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandUsers?token=${token}&${refId && refId !== -1 ? `refId=${refId}` : `brandId=${brand.id}`}&searchKey=${searchKey}&startIndex=${startIndex}&limit=50`)
        .catch((err) => {
          console.log("err: " + err);
          setHasMore(false);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 200) {
            setHasMore(response.data.users && response.data.users.length === 50);
            if (startIndex === 0) {
              setUsers([...response.data.users]);
            } else {
              setUsers((prev) => [...prev, ...response.data.users]);
            }
          }
        });

    } else {
      axios.get(`${process.env.REACT_APP_API_URL}api/alcago/user/getAllUsers?token=${token}&searchKey=${searchKey}&startIndex=${startIndex}&limit=50`)
        .catch((err) => {
          console.log("err: " + err);
          setUsers([]);
          setHasMore(false);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 200) {
            setHasMore(response.data.users && response.data.users.length === 50);
            if (startIndex === 0) {
              setUsers([...response.data.users]);
            } else {
              setUsers((prev) => [...prev, ...response.data.users]);
            }
          }
        });
    }
  }

  const fetchRefs = () => {
    if (influencer) {
      axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencer/getInfluencerRefCodes?token=${token}&influencerId=${influencer.id}`)
        .catch((err) => {
          console.log("err: " + err);
          setRefs([]);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 200) {
            setRefs([...response.data.refCodes]);
          }
        });
    } else {
      axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandRefCodes?token=${token}&brandId=${brand.id}`)
        .catch((err) => {
          console.log("err: " + err);
          setRefs([]);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 200) {
            setRefs([...response.data.refCodes]);
          }
        });
    }
  }

  return (
    <Box m="20px">
      <Box display="flex" gap="20px" alignItems="center">
        <Box>
          <Header title={influencer ? (influencer.name + " Kullanıcıları") : (brand ? (brand.name + " Kullanıcıları") : "Kullanıcılar")} subtitle="Kullanıcıları buradan görebilir ve düzenleyebilirsiniz." />
        </Box>
        <TextField
          id="outlined-basic"
          label="Kullanıcı ara"
          variant="outlined"
          onChange={(event) => {
            setSearchKey(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setSearchKey(event.target.value);
              setUsers([]);
              fetchUsers(0)
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => {
                  setUsers([]);
                  fetchUsers(0)
                }}
                  sx={{ marginLeft: "10px" }}>
                  <SearchOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }} />
        {(influencer || brand) &&
          <Box width="200px">
            <FormControl fullWidth variant="filled">
              <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Referans Kodu:</InputLabel>
              <Select
                labelId="filter-label"
                label="Referans Kodu"
                onChange={(e) => {
                  console.log(e.target.value);
                  setRefId(e.target.value);
                }}
                name="refId"
                value={refId}
              >
                <MenuItem value={-1}>Tümü</MenuItem>
                {refs.map((refCode) => <MenuItem key={"refCode" + refCode.id} value={refCode.id}>{refCode.ref_code}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
        }
      </Box>
      <Box>
        <InfiniteScroll
          dataLength={users.length}
          next={() => fetchUsers(users.length)}
          hasMore={hasMore}
          loader={<h4>Yükleniyor...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Tüm kullanıcıları görüntülüyorsunuz.</b>
            </p>
          }
        >
          {users.map((user, i) =>
          (<Box key={i} display="inline-block" p="20px" mr="20px" mt="20px" sx={{
            background: colors.primary[400], borderRadius: "20px", width: "310px",
            '&:hover': {
              backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.grey[700],
              opacity: [0.9, 0.8, 0.7, 0.6, 0.5],
              cursor: "pointer",
              borderRadius: "20px"
            }
          }}
            onClick={() => {
              navigate(`/admin-panel/user/${user.id}`);
            }}>
            <Box textAlign="center">
              <img
                alt="profile-user"
                src={user.profile_photo_link}
                width="100px"
                height="100px"
                style={{ cursor: "pointer", borderRadius: "50%" }} />
              <Tooltip title={(user.name + " " + user.surname).length >= 18 ? user.name + " " + user.surname : ""}>
                <Typography variant='h3' mt="15px">{((user.name + " " + user.surname).length >= 18 ? (user.name + " " + user.surname).slice(0, 15) + "..." : user.name + " " + user.surname) + " - " + (new Date().getFullYear() - new Date(user.birth_date).getFullYear())}</Typography>
              </Tooltip>
            </Box>
            <hr />
            <Box display="flex" gap="10px" alignItems="center" mt="20px">
              <LocalPhoneOutlinedIcon />
              <Typography variant='h5'>{user.phone_number}</Typography>
            </Box>
            <Box display="flex" gap="10px" alignItems="center" mt="10px">
              <AttachEmailOutlinedIcon />
              <Typography variant='h5'>{user.email}</Typography>
            </Box>
            <Box display="flex" gap="10px" alignItems="center" mt="10px">
              <Box padding="5px 10px" sx={{ background: (user.gender === "Erkek" ? colors.blueAccent[700] : colors.redAccent[700]), borderRadius: "10px" }}>
                <Typography variant='h6'>{(user.gender !== "Belirtmek İstemiyorum" ? user.gender : "Bel.İst.")}</Typography>
              </Box>
              <Box padding="5px 10px" sx={{ background: colors.greenAccent[600], borderRadius: "10px" }}>
                <Typography variant='h6'>{user.marital_status}</Typography>
              </Box>
              <Box padding="5px 10px" sx={{ background: colors.grey[600], borderRadius: "10px" }}>
                <Typography variant='h6'>{user.city_of_residence.charAt(0) + user.city_of_residence.toLowerCase().slice(1, user.city_of_residence.length)}</Typography>
              </Box>
              <Box padding="5px 10px" sx={{ background: colors.grey[600], borderRadius: "10px" }}>
                <Typography variant='h6'>{new Date(user.sign_up_date).toLocaleDateString()}</Typography>
              </Box>
            </Box>
          </Box>)
          )}
        </InfiniteScroll>
      </Box>
    </Box >
  )
}

export default Users