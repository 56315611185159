import React, { useContext, useState, useEffect } from 'react'
import { Alert, Autocomplete, Box, Button, Checkbox, Chip, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { Formik } from "formik";
import * as yup from "yup";
import YupPassword from 'yup-password';
import axios from 'axios';
import { tokens } from '../../../../theme';
import { UserContext } from '../../../../context/UserContext';
import ProgressDialog from '../../../../components/ProgressDialog';
YupPassword(yup);

function RegisterDialog({ open, handleClose, handleCloseWithAccesDialogOpen, type, brand, isAddingFromAdminPanel }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.colors);
  const { token } = useContext(UserContext);
  const [alertMessage, setAlertMessage] = useState(null);
  const [data, setData] = useState(null);
  const [usingProvider, setUsingProvider] = useState(false);

  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);

  useEffect(() => {
    if (progress === 100) {
      if (type === "new" && !isAddingFromAdminPanel && data && data.brand.webservice_provider === "ideasoft") {
        setProgressDialogOpen(false);
        handleCloseWithAccesDialogOpen(data);
      } else {
        setProgressDialogOpen(false);
        handleClose();
      }
    }
  }, [progress]);

  var initialValues = {}

  if (type === "new") {
    if (isAddingFromAdminPanel) {
      initialValues = {
        brandName: "",
        website: "",
        webservice_provider: "",
        webservice_address: "",
        webservice_username: "",
        webservice_password: "",
        brandDescription: "",
        keywords: [],
        commission: 0,
        logoSquare: null,
        logoHorizontal: null,
        logoHorizontalBig: null,
        logoVertical: null,
        logoSquareLink: "",
        logoHorizontalLink: "",
        logoHorizontalBigLink: "",
        logoVerticalLink: "",
        refCode: "",
        name: "",
        surname: "",
        email: "",
        password: "",
        passwordCheck: ""
      }
    } else {
      initialValues = {
        brandName: "",
        website: "",
        webservice_provider: "",
        webservice_address: "",
        webservice_username: "",
        webservice_password: "",
        brandDescription: "",
        keywords: [],
        logoSquare: null,
        logoHorizontal: null,
        logoHorizontalBig: null,
        logoVertical: null,
        logoSquareLink: "",
        logoHorizontalLink: "",
        logoHorizontalBigLink: "",
        logoVerticalLink: "",
        refCode: "",
        name: "",
        surname: "",
        email: "",
        password: "",
        passwordCheck: ""
      }
    }
  }

  if (type === "edit" || type === "approve") {
    initialValues = {
      brandName: brand.name,
      website: brand.website,
      brandDescription: brand.description,
      keywords: brand.keywords.split(";"),
      commission: brand.commission_percentage ? brand.commission_percentage : 0,
      logoSquare: null,
      logoHorizontal: null,
      logoHorizontalBig: null,
      logoVertical: null,
      logoSquareLink: brand.logo_link,
      logoHorizontalLink: brand.horizontal_logo_link,
      logoHorizontalBigLink: brand.big_horizontal_logo_link,
      logoVerticalLink: brand.vertical_logo_link
    }
  }

  var validationObject = {}

  if (type === "new") {
    validationObject = {
      brandName: yup.string().required("zorunlu"),
      website: yup.string().url("Geçersiz url! (örn: https://yourbrand.com/").required("zorunlu"),
      webservice_provider: usingProvider ? yup.string().required() : yup.string(),
      webservice_address: yup.string().url("Geçersiz url! (örn: https://yourbrand.com/").when('webservice_provider', {
        is: (webservice_provider) => webservice_provider && webservice_provider === "tsoft",
        then: yup.string().url("Geçersiz url! (örn: https://yourbrand.com/")
          .required('zorunlu')
      }),
      webservice_username: yup.string().when('webservice_provider', {
        is: (webservice_provider) => webservice_provider && webservice_provider === "tsoft",
        then: yup.string()
          .required('zorunlu')
      }),
      webservice_password: yup.string().when('webservice_provider', {
        is: (webservice_provider) => webservice_provider && webservice_provider === "tsoft",
        then: yup.string()
          .required('zorunlu')
      }),
      brandDescription: yup.string().required("zorunlu"),
      keywords: yup.array().min(15, "En az 15 kelime girilmesi gerekmektedir. Kelimeyi girdikten sonra enter tuşuna basarak onaylayabilirsiniz.").required("zorunlu"),
      logoSquareLink: yup.string(),
      logoSquare: yup.mixed().when('logoSquareLink', {
        is: (logoSquareLink) => !logoSquareLink || logoSquareLink === "",
        then: yup.string()
          .required('zorunlu')
      }),
      logoHorizontalLink: yup.string(),
      logoHorizontal: yup.mixed().when('logoHorizontalLink', {
        is: (logoHorizontalLink) => !logoHorizontalLink || logoHorizontalLink === "",
        then: yup.string()
          .required('zorunlu')
      }),
      logoHorizontalBigLink: yup.string(),
      logoHorizontalBig: yup.mixed().when('logoHorizontalBigLink', {
        is: (logoHorizontalBigLink) => !logoHorizontalBigLink || logoHorizontalBigLink === "",
        then: yup.string()
          .required('zorunlu')
      }),
      logoVerticalLink: yup.string(),
      logoVertical: yup.mixed().when('logoVerticalLink', {
        is: (logoVerticalLink) => !logoVerticalLink || logoVerticalLink === "",
        then: yup.string()
          .required('zorunlu')
      }),
      refCode: yup.string(),
      name: yup.string().required("zorunlu"),
      surname: yup.string().required("zorunlu"),
      email: yup.string().email("Geçersiz eposta adresi").required("zorunlu"),
      password: yup.string()
        .required('zorunlu')
        .min(8, 'Şifre çok kısa - en az 8 karakter olmalı')
        .minLowercase(1, 'Şifreniz en az 1 küçük harf içermelidir')
        .minUppercase(1, 'Şifreniz en az 1 büyük harf içermelidir')
        .minNumbers(1, 'Şifreniz en az 1 rakam içermelidir')
        .minSymbols(1, 'Şifreniz en az 1 özel karakter içermelidir'),
      passwordCheck: yup.string()
        .oneOf([yup.ref('password'), null], 'Şifreler uyuşmuyor')
    }

    if (isAddingFromAdminPanel) {
      validationObject.commission = yup.number().min(0, "En az 0 olabilir").max(100, "En fazla 100 olabilir").required("zorunlu");
    }
  }

  if (type === "edit" || type === "approve") {
    validationObject = {
      brandName: yup.string().required("zorunlu"),
      website: yup.string().url("Geçersiz url! (örn: https://yourbrand.com/").required("zorunlu"),
      brandDescription: yup.string().required("zorunlu"),
      keywords: yup.array().min(15, "En az 15 kelime girilmesi gerekmektedir. Kelimeyi girdikten sonra enter tuşuna basarak onaylayabilirsiniz.").required("zorunlu"),
      commission: yup.number().min(0, "En az 0 olabilir").max(100, "En fazla 100 olabilir").required("zorunlu"),
      logoSquareLink: yup.string(),
      logoSquare: yup.mixed().when('logoSquareLink', {
        is: (logoSquareLink) => !logoSquareLink || logoSquareLink === "",
        then: yup.string()
          .required('zorunlu')
      }),
      logoHorizontalLink: yup.string(),
      logoHorizontal: yup.mixed().when('logoHorizontalLink', {
        is: (logoHorizontalLink) => !logoHorizontalLink || logoHorizontalLink === "",
        then: yup.string()
          .required('zorunlu')
      }),
      logoHorizontalBigLink: yup.string(),
      logoHorizontalBig: yup.mixed().when('logoHorizontalBigLink', {
        is: (logoHorizontalBigLink) => !logoHorizontalBigLink || logoHorizontalBigLink === "",
        then: yup.string()
          .required('zorunlu')
      }),
      logoVerticalLink: yup.string(),
      logoVertical: yup.mixed().when('logoVerticalLink', {
        is: (logoVerticalLink) => !logoVerticalLink || logoVerticalLink === "",
        then: yup.string()
          .required('zorunlu')
      })
    }
  }
  const brandSchema = yup.object().shape(validationObject);


  const handleFormSubmit = async (values) => {
    setProgressDialogOpen(true);
    if (type === "new") {
      if (values.website.charAt(values.website.length - 1) !== "/") {
        values.website = values.website + "/";
      }
      const data = {
        brandLogo: values.logoSquare,
        horizontalLogo: values.logoHorizontal,
        bigHorizontalLogo: values.logoHorizontalBig,
        verticalLogo: values.logoVertical,
        brand: {
          name: values.brandName,
          description: values.brandDescription,
          website: values.website,
          webservice_provider: values.webservice_provider,
          webservice_address: values.webservice_address,
          webservice_username: values.webservice_username,
          webservice_password: values.webservice_password,
          keywords: values.keywords.join(";")
        },
        brand_account: {
          name: values.name,
          surname: values.surname,
          email: values.email,
          password: values.password,
        }
      };

      if (isAddingFromAdminPanel) {
        data.brand.is_active = 1;
        data.brand_account.is_approved = 1;
        data.brand.commission_percentage = values.commission;
      } else {
        data.brand.commission_percentage = 0;
      }

      let canContinue = true;
      if (values.refCode && values.refCode !== "") {
        await axios.get(`${process.env.REACT_APP_API_URL}api/alcago/partner/checkRefCodeExists?refCode=${values.refCode}`, data)
          .catch((err) => {
            console.log("err: " + err);
            setAlertMessage("Referans kodu hatalı.");
            setTimeout(() => {
              setAlertMessage(null)
            }, 3000);
            canContinue = false;
            setProgressDialogOpen(false);
          })
          .then((response) => {
            if (response && response.data.success === true && response.data.status === 200 && response.data.isExists) {
              data.brand.partner_ref_id = response.data.refCodeId;
            }
          });
      }

      if (canContinue) {
        await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/validateUserData`, {
          name: data.brand.name,
          website: data.brand.website,
          email: data.brand_account.email
        })
          .catch((err) => {
            console.log("err: " + err);
            setProgressDialogOpen(false);
          })
          .then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
              if (response.data.isValid) {
                setData(data);
                axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/`, data, {
                  headers: {
                    "content-type": "multipart/form-data"
                  },
                  onUploadProgress: (progressEvent) => {
                    const progressOnUpload = (progressEvent.loaded / progressEvent.total) * 100;
                    setTimeout(() => {
                      setProgress(progressOnUpload / 3);
                    }, 500);
                    setTimeout(() => {
                      setProgress(progressOnUpload * 2 / 3);
                    }, 1000);
                    setTimeout(() => {
                      setProgress(progressOnUpload);
                    }, 1500);
                  }
                })
                  .catch((err) => {
                    console.log("err: " + err);
                    setAlertMessage("Bir hata oluştu");
                    setProgressDialogOpen(false);
                    setTimeout(() => {
                      setAlertMessage(null)
                    }, 3000);
                  })
                  .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {

                    }
                  });
              } else {
                setAlertMessage(response.data.unvalidField + ' ("' + response.data.unvalidValue + '") zaten kullanılıyor.');
                setProgressDialogOpen(false);
              }
            }
          });
      }
    } else if (type === "edit" || type === "approve") {
      const data = {
        brand: {
          name: values.brandName,
          description: values.brandDescription,
          website: values.website,
          keywords: values.keywords.join(";"),
          commission_percentage: values.commission
        }
      }
      if (type === "approve") {
        data.brand.is_active = 1
      }
      if (values.logoSquare) {
        await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/uploadBrandLogo?token=${token}`, { brandLogo: values.logoSquare }, {
          headers: {
            "content-type": "multipart/form-data"
          },
        }).then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            data.brand.logo_path = response.data.logoPath;
          }
        });
      }
      if (values.logoHorizontal) {
        await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/uploadHorizontalLogo?token=${token}`, { horizontalLogo: values.logoHorizontal }, {
          headers: {
            "content-type": "multipart/form-data"
          },
        }).then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            data.brand.horizontal_logo_path = response.data.logoPath;
          }
        });
      }
      if (values.logoHorizontalBig) {
        await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/uploadBigHorizontalLogo?token=${token}`, { bigHorizontalLogo: values.logoHorizontalBig }, {
          headers: {
            "content-type": "multipart/form-data"
          },
        }).then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            data.brand.big_horizontal_logo_path = response.data.logoPath;
          }
        });
      }
      if (values.logoVertical) {
        await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/uploadVerticalLogo?token=${token}`, { verticalLogo: values.logoVertical }, {
          headers: {
            "content-type": "multipart/form-data"
          },
          onUploadProgress: (progressEvent) => {
            const progressOnUpload = (progressEvent.loaded / progressEvent.total) * 100;
            setTimeout(() => {
              setProgress(progressOnUpload / 3);
            }, 500);
            setTimeout(() => {
              setProgress(progressOnUpload * 2 / 3);
            }, 1000);
            setTimeout(() => {
              setProgress(progressOnUpload);
            }, 1500);
          }
        }).then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            data.brand.vertical_logo_path = response.data.logoPath;
          }
        });
      } else {
        setTimeout(() => {
        }, 500);
        setTimeout(() => {
          setProgress(66);
        }, 1000);
        setTimeout(() => {
          setProgress(100);
        }, 1500);
      }

      await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/updateBrand?token=${token}&brandId=${brand.id}`, data)
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {

          }
        });
    }
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Box minWidth="500px" m="20px" mb="5px" textAlign="center">
          {type === "new" && !isAddingFromAdminPanel &&
            <Typography variant='h2'>Kayıt</Typography>
          }
          {type === "new" && isAddingFromAdminPanel &&
            <Typography variant='h2'>Yeni Marka</Typography>
          }
          {type === "edit" &&
            <Typography variant='h2'>Markayı Düzenle</Typography>
          }
          {type === "approve" &&
            <Typography variant='h2'>Markayı Onayla</Typography>
          }

          {alertMessage && <Alert severity="error" sx={{ marginTop: "20px" }}>{alertMessage}</Alert>}

        </Box>

        <DialogContent>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={brandSchema}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                  <Button variant="contained" component="label" style={{ gridColumn: "span 1", textAlign: "center" }}>
                    {(!values.logoSquare && !(values.logoSquareLink && values.logoSquareLink !== "")) && <span>Logo Yükle</span>}
                    <br />
                    {(!values.logoSquare && !(values.logoSquareLink && values.logoSquareLink !== "")) && <span>(1080 x 1080)</span>}
                    {(values.logoSquare || (values.logoSquareLink && values.logoSquareLink !== "")) && <img alt='square brand logo' height="100px" width="100px" src={values.logoSquare ? URL.createObjectURL(values.logoSquare) : values.logoSquareLink} />}
                    <input id="logoSquare" name="logoSquare" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("logoSquare", event.target.files[0]); }} hidden />
                  </Button>
                  <Button variant="contained" component="label" style={{ gridColumn: "span 1", textAlign: "center" }}>
                    {(!values.logoHorizontal && !(values.logoHorizontalLink && values.logoHorizontalLink !== "")) && <span>Logo Yükle</span>}
                    <br />
                    {(!values.logoHorizontal && !(values.logoHorizontalLink && values.logoHorizontalLink !== "")) && <span>(350 x 300)</span>}
                    {(values.logoHorizontal || (values.logoHorizontalLink && values.logoHorizontalLink !== "")) && <img alt='horizontal brand logo' height="85px" width="100px" src={values.logoHorizontal ? URL.createObjectURL(values.logoHorizontal) : values.logoHorizontalLink} />}
                    <input id="logoHorizontal" name="logoHorizontal" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("logoHorizontal", event.target.files[0]); }} hidden />
                  </Button>
                  <Button variant="contained" component="label" style={{ gridColumn: "span 1", textAlign: "center" }}>
                    {(!values.logoHorizontalBig && !(values.logoHorizontalBigLink && values.logoHorizontalBigLink !== "")) && <span>Logo Yükle</span>}
                    <br />
                    {(!values.logoHorizontalBig && !(values.logoHorizontalBigLink && values.logoHorizontalBigLink !== "")) && <span>(1080 x 720)</span>}
                    {(values.logoHorizontalBig || (values.logoHorizontalBigLink && values.logoHorizontalBigLink !== "")) && <img alt='big horiziontal brand logo' height="66px" width="100px" src={values.logoHorizontalBig ? URL.createObjectURL(values.logoHorizontalBig) : values.logoHorizontalBigLink} />}
                    <input id="logoHorizontalBig" name="logoHorizontalBig" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("logoHorizontalBig", event.target.files[0]); }} hidden />
                  </Button>
                  <Button variant="contained" component="label" style={{ gridColumn: "span 1", textAlign: "center" }}>
                    {(!values.logoVertical && !(values.logoVerticalLink && values.logoVerticalLink !== "")) && <span>Logo Yükle</span>}
                    <br />
                    {(!values.logoVertical && !(values.logoVerticalLink && values.logoVerticalLink !== "")) && <span>(270 x 400)</span>}
                    {(values.logoVertical || (values.logoVerticalLink && values.logoVerticalLink !== "")) && <img alt='vertical brand logo' height="100px" width="67px" src={values.logoVertical ? URL.createObjectURL(values.logoVertical) : values.logoVerticalLink} />}
                    <input id="logoVertical" name="logoVertical" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("logoVertical", event.target.files[0]); }} hidden />
                  </Button>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Marka Adı"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.brandName}
                    name="brandName"
                    error={!!touched.brandName && !!errors.brandName}
                    helperText={touched.brandName && errors.brandName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Website"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.website}
                    name="website"
                    error={!!touched.website && !!errors.website}
                    helperText={touched.website && errors.website}
                    sx={{ gridColumn: "span 2" }}
                  />
                  {(type === "approve" || type === "edit" || (type === "new" && isAddingFromAdminPanel)) &&
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Komisyon Yüzdesi"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        if (e.target.value >= 0 && e.target.value <= 100) {
                          handleChange(e);
                        }
                      }}
                      value={values.commission}
                      name="commission"
                      error={!!touched.commission && !!errors.commission}
                      helperText={touched.commission && errors.commission}
                      sx={{ gridColumn: "span 2" }}
                    />
                  }
                  {(type === "approve" || type === "edit" || (type === "new" && isAddingFromAdminPanel)) &&
                    <Box gridColumn="span 2"></Box>
                  }
                  <TextField
                    fullWidth
                    multiline
                    variant="filled"
                    type="text"
                    label="Marka Tanıtımı"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.brandDescription}
                    name="brandDescription"
                    rows={4}
                    error={!!touched.brandDescription && !!errors.brandDescription}
                    helperText={touched.brandDescription && errors.brandDescription}
                    sx={{ gridColumn: "span 4" }}
                  />

                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    onChange={(e, value) => setFieldValue("keywords", value)}
                    value={values.keywords}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        multiline
                        variant="filled"
                        type="text"
                        label={<><span>Anahtar Kelimeler</span><span style={{ fontSize: "10px" }}> (Kelimeyi girdikten sonra enter tuşuna basarak onaylayabilirsiniz.)</span></>}
                        error={!!touched.keywords && !!errors.keywords}
                        helperText={touched.keywords && errors.keywords}
                      />
                    )}
                    sx={{ gridColumn: "span 4" }}
                  />

                  {type === "new" &&
                    <>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Referans Kodu (İsteğe Bağlı)"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.refCode}
                        name="refCode"
                        error={!!touched.refCode && !!errors.refCode}
                        helperText={touched.refCode && errors.refCode}
                        sx={{ gridColumn: "span 2" }}
                        inputProps={{ maxLength: 8 }}
                      />
                      <br />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="İsim"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        name="name"
                        error={!!touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Soyisim"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.surname}
                        name="surname"
                        error={!!touched.surname && !!errors.surname}
                        helperText={touched.surname && errors.surname}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <Box display="flex" alignItems="center" justifyContent="center" padding="1px 10px" gridColumn={"span 2"}>
                        <Checkbox
                          checked={usingProvider}
                          size="small"
                          sx={{
                            padding: "5px 2px 5px 0",
                            '&.Mui-checked': {
                              color: (colors.greenAccent[200])
                            }
                          }}
                          onClick={(event) => {
                            setUsingProvider(!usingProvider);
                          }}
                        />
                        <Typography variant='h6'>Bir altyapı sağlayıcısı kullanıyorum</Typography>
                      </Box>
                      {usingProvider && <Box gridColumn="span 2">
                        <FormControl fullWidth variant="filled"
                          sx={{ gridColumn: "span 2" }}>
                          <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Altyapı Sağlayıcısı</InputLabel>
                          <Select
                            labelId="filter-label"
                            label="Altyapı Sağlayıcısı"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="webservice_provider"
                            error={!!touched.webservice_provider && !!errors.webservice_provider}
                            helperText={touched.webservice_provider && errors.webservice_provider}
                            value={values.webservice_provider}
                          >
                            <MenuItem value={"tsoft"}>T-Soft</MenuItem>
                            <MenuItem value={"ideasoft"}>IdeaSoft</MenuItem>
                            <MenuItem value={"other"}>Diğer</MenuItem>

                          </Select>
                        </FormControl>
                      </Box>}
                      {!usingProvider && <Box gridColumn="span 2"></Box>}
                      {usingProvider && values.webservice_provider && values.webservice_provider === "tsoft" &&
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label="Webservis Kullanıcı Adı"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.webservice_username}
                          name="webservice_username"
                          error={!!touched.webservice_username && !!errors.webservice_username}
                          helperText={touched.webservice_username && errors.webservice_username}
                          sx={{ gridColumn: "span 2" }}
                        />
                      }
                      {usingProvider && values.webservice_provider && values.webservice_provider === "tsoft" &&
                        <TextField
                          fullWidth
                          variant="filled"
                          type="password"
                          label="Webservis Kullanıcı Şifresi"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.webservice_password}
                          name="webservice_password"
                          error={!!touched.webservice_password && !!errors.webservice_password}
                          helperText={touched.webservice_password && errors.webservice_password}
                          sx={{ gridColumn: "span 2" }}
                        />
                      }
                      {usingProvider && values.webservice_provider && values.webservice_provider === "tsoft" &&
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label="Webservis adresi"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.webservice_address}
                          name="webservice_address"
                          error={!!touched.webservice_address && !!errors.webservice_address}
                          helperText={touched.webservice_address && errors.webservice_address}
                          sx={{ gridColumn: "span 2" }}
                        />
                      }
                      {usingProvider && values.webservice_provider && values.webservice_provider === "tsoft" && <Box gridColumn="span 2"></Box>}
                      <TextField
                        fullWidth
                        variant="filled"
                        type="email"
                        label="Eposta"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        error={!!touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <br />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="password"
                        label="Şifre"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        name="password"
                        error={!!touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="password"
                        label="Şifre"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.passwordCheck}
                        name="passwordCheck"
                        error={!!touched.passwordCheck && !!errors.passwordCheck}
                        helperText={touched.passwordCheck && errors.passwordCheck}
                        sx={{ gridColumn: "span 2" }}
                      />
                    </>
                  }
                </Box>
                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                  <Button type="submit" color="secondary" variant="contained">
                    {type === "new" && !isAddingFromAdminPanel &&
                      <Typography variant='h5'>Başvur</Typography>
                    }
                    {((type === "new" && isAddingFromAdminPanel) || type === "edit") &&
                      <Typography variant='h5'>Kaydet</Typography>
                    }
                    {type === "approve" &&
                      <Typography variant='h5'>Onayla</Typography>
                    }
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog >
      <ProgressDialog open={progressDialogOpen} progress={progress} />
    </>
  )
}

export default RegisterDialog;