import { Box, Button, IconButton, InputAdornment, TextField, useTheme } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DetailedPartnerBox from '../../../../components/DetailedPartnerBox';
import RegisterDialog from '../../../loginPages/partnerLogin/registerDialog';
import { useNavigate } from 'react-router-dom';

function Partners() {
  const theme = useTheme();
  const { token } = useContext(UserContext);
  const [partners, setPartners] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchPartners()
  }, []);

  const fetchPartners = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/partner/getPartners?token=${token}&searchKey=${searchKey}`)
      .catch((err) => {
        console.log("err: " + err);
        setPartners([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setPartners(response.data.partners);
        }
      });
  }

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
    fetchPartners()
  }

  const brandsButtonClick = (partner) => {
    navigate(`/admin-panel/brands/${partner.id}/${partner.name + " " + partner.surname}`);
  }

  return (
    <Box m="20px">
      <RegisterDialog open={openDialog} handleClose={handleDialogClose} type="new" isAddingFromAdminPanel={true} />
      <Box display="flex" gap="30px" alignItems="center">
        <Box>
          <Header title="Brand Inviterlar" subtitle="Brand Inviterları buradan görebilir ve düzenleyebilirsiniz." />
        </Box>
        <TextField
          id="outlined-basic"
          label="Partner ara"
          variant="outlined"
          onChange={(event) => {
            setSearchKey(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setSearchKey(event.target.value);
              fetchPartners()
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => {
                  fetchPartners()
                }}
                  sx={{ marginLeft: "10px" }}>
                  <SearchOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }} />
        <Button variant='contained' color='success' sx={{ margin: "auto 0" }} onClick={() => { setOpenDialog(true) }}>
          Yeni Brand Inviter Ekle
        </Button>
      </Box>
      <Box>
        {partners.map(partner =>
          <DetailedPartnerBox key={partner.id} partner={partner} fetchPartners={fetchPartners} brandsButtonClick={brandsButtonClick} />)
        }
      </Box>
    </Box>
  )
}

export default Partners