import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react'
import Header from '../../../../../components/Header';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommentIcon from '@mui/icons-material/Comment';
import { useNavigate } from 'react-router-dom';
import StatCard from '../../../../../components/StatCard';
import { tokens } from '../../../../../theme';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../../../../context/UserContext';
import CommentBox from '../../../../../components/CommentBox';
import AlertDialogSlide from '../../../../../components/AlertDialogSlide';
import EditUserDialog from './editUserDialog';
import CouponBox from '../../../../../components/CouponBox';
import InfiniteScroll from 'react-infinite-scroll-component';
import NCSBox from '../../../../../components/NCSBox';


function UserDetails() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const userId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];

    const [user, setUser] = useState(null);
    const [coupons, setCoupons] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [comments, setComments] = useState([]);
    const [notComplatedSeries, setNotComplatedSeries] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [hasMoreNCS, setHasMoreNCS] = useState(true);
    const [hasMoreAnswers, setHasMoreAnswers] = useState(true);

    const [earningStatistics, setEarningStatistics] = useState({
        today: 0,
        thisMonth: 0,
        thisYear: 0,
        total: 0
    });


    function convertTime(timeString) {
        const timeArr = timeString.split(':');
        const hours = parseInt(timeArr[0]);
        const minutes = parseInt(timeArr[1]);
        const seconds = parseInt(timeArr[2]);
        const days = Math.floor(hours / 24);
        const remainingHours = hours % 24;

        return `${days > 0 ? days + ' gün, ' : ''}${remainingHours > 0 ? remainingHours + ' saat, ' : ''}${minutes > 0 ? minutes + ' dakika, ' : ''}${seconds > 0 ? seconds + ' saniye' : ''}`;
    }

    const getEarningStatistics = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getDashboardEarningStatisticsWithField?token=${token}&id=${userId}&field=user`)
            .catch((err) => {
                console.log("err: " + err);
                setEarningStatistics({
                    today: 0,
                    thisMonth: 0,
                    thisYear: 0,
                    total: 0
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setEarningStatistics(response.data.statistics);
                }
            });
    }

    const approveComment = (commentId) => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/approveComment?token=${token}&commentId=${commentId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = comments.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        comments[ix].is_approved = 1;
                        setComments(Array.from(comments));
                    }
                }
            });
    }
    const deleteComment = (commentId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/brand/deleteComment?token=${token}&commentId=${commentId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = comments.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        comments.splice(ix, 1);
                        setComments(Array.from(comments));
                    }
                }
            });
    }

    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertDialogClose = () => {
        setOpenAlertDialog(false);
    }
    const clickPositiveAnswer = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/user/deleteUserFromPanel?token=${token}&userId=${user.id}&userFirebaseId=${user.firebase_id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    navigate("/admin-panel/users");
                }
            });
    }
    const clickNegativeAnswer = () => { }



    useEffect(() => {
        fetchUserData();
        fetchCoupons(0);
        fetchNotComplatedSeries(0);
        fetchAnswers(0);
        getEarningStatistics();
    }, []);

    const fetchUserData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/user/getUserDetailsWithId?token=${token}&userId=${userId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    if (response.data.user && response.data.user.user) {
                        setUser(response.data.user.user);
                        if (response.data.user && response.data.user.favorites) {
                            setFavorites(response.data.user.favorites);
                        }
                        if (response.data.user && response.data.user.comments) {
                            setComments(response.data.user.comments);
                        }
                    }
                }
            });
    }

    const fetchCoupons = (startIndex) => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/user/getUserCouponsWithId?token=${token}&userId=${userId}&startIndex=${startIndex}&limit=10`)
            .catch((err) => {
                console.log("err: " + err);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.coupons && response.data.coupons.length === 10);
                    if (startIndex === 0) {
                        setCoupons([...response.data.coupons]);
                    } else {
                        setCoupons((prev) => [...prev, ...response.data.coupons]);
                    }
                }
            });
    }

    const fetchNotComplatedSeries = (startIndex) => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAllNotComplatedSeries?token=${token}&userId=${userId}&startIndex=${startIndex}&limit=10`)
            .catch((err) => {
                console.log("err: " + err);
                setHasMoreNCS(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMoreNCS(response.data.series && response.data.series.length === 10);
                    if (startIndex === 0) {
                        setNotComplatedSeries([...response.data.series]);
                    } else {
                        setNotComplatedSeries((prev) => [...prev, ...response.data.series]);
                    }
                }
            });
    }

    const fetchAnswers = (startIndex) => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/answer/getAnswersWithFilter?token=${token}&userId=${userId}&startIndex=${startIndex}&limit=10`)
            .catch((err) => {
                console.log("err: " + err);
                setHasMoreAnswers(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMoreAnswers(response.data.answers && response.data.answers.length === 10);
                    if (startIndex === 0) {
                        setAnswers([...response.data.answers]);
                    } else {
                        setAnswers((prev) => [...prev, ...response.data.answers]);
                    }
                }
            });
    }


    const [openEditDialog, setOpenEditDialog] = useState(false);
    const handleEditDialogClose = () => {
        fetchUserData();
        setOpenEditDialog(false);
    }

    return (
        <Box mt="20px">
            {!user &&
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                </Box>
            }
            {user &&
                <Box>
                    <AlertDialogSlide
                        open={openAlertDialog} handleClose={handleAlertDialogClose}
                        clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer}
                        positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                        title={"Bu kullanıcıyı silmek istediğinize emin misiniz?"}
                        description={"Bu kullanıcı ile ilişkili tüm veriler silinecektir. (kazançlar, kazanılan kuponlar, yorumlar vb.)"}
                    />
                    <EditUserDialog open={openEditDialog} handleClose={handleEditDialogClose} user={user} />
                    <Box display="flex">
                        <Box display="flex">
                            <IconButton style={{ width: "40px", height: "40px", marginRight: "5px" }}
                                onClick={() => {
                                    navigate("/admin-panel/users");
                                }}>
                                <ArrowBackIosNewOutlinedIcon />
                            </IconButton>
                            <Box display="flex" mr="5px">

                                <img
                                    alt="profile-user"
                                    src={user.profile_photo_link}
                                    width="40px"
                                    height="40px"
                                    style={{ cursor: "pointer", borderRadius: "50%" }} />
                            </Box>
                            <Box display="flex">
                                <Header title={user.name + " " + user.surname + " - " + (new Date().getFullYear() - new Date(user.birth_date).getFullYear())} subtitle={""} />
                            </Box>
                        </Box>

                        <Box display="flex" gap="20px" mb="30px" ml="20px" alignItems="center">
                            <Button variant="contained" style={{ background: colors.blueAccent[500] }} onClick={() => setOpenEditDialog(true)}>
                                Düzenle
                            </Button>
                            <Button variant="contained" style={{ background: colors.redAccent[500] }} onClick={() => setOpenAlertDialog(true)}>
                                Sil
                            </Button>
                        </Box>

                    </Box>
                    <Box mx="30px" pt="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Kayıt Tarihi</b> <hr />{new Date(user.sign_up_date).toLocaleTimeString("tr-TR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Telefon Numarası</b> <hr />{user.phone_number}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Eposta Adresi</b> <hr />{user.email}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Doğum Tarihi</b> <hr />{new Date(user.birth_date).toLocaleString("tr-TR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Cinsiyet</b> <hr />{user.gender}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Yaşadığı Şehir</b> <hr />{user.city_of_residence}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Doğduğu Şehir</b> <hr />{user.city_of_birth}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Eğitim Durumu</b> <hr />{user.education}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Meslek</b> <hr />{user.job}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Gelir Durumu</b> <hr />{user.income_status}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Araba</b> <hr />{user.have_a_car === 0 ? "Yok" : "Var"}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Medeni Hal</b> <hr />{user.marital_status}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Bizi Nereden Buldu</b> <hr />{user.find_us_from}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Influencer</b> <hr />{user.influencer}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Referans Kodu</b> <hr />{user.ref_code}</Typography>
                        {user.first_coupon_time && convertTime(user.first_coupon_time) !== "" && <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>İlk Kuponu Alma Süresi</b> <hr />{convertTime(user.first_coupon_time)}</Typography>}
                    </Box>
                    <Box display="grid" gap="20px" gridTemplateColumns="repeat(5, minmax(0, 1fr))" marginRight="60px" mt="20px">
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.today.toFixed(2) + " ₺"} content="Bugün" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.thisMonth.toFixed(2) + " ₺"} content="Bu ay" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.thisYear.toFixed(2) + " ₺"} content="Bu yıl" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.total.toFixed(2) + " ₺"} content="Toplam" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                    </Box>
                    <Box display="grid" gap="20px" gridTemplateColumns="repeat(5, minmax(0, 1fr))" marginRight="60px" mt="10px" gridColumn="span 6">
                        <StatCard icon={<FavoriteOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={favorites.length.toString()} content="Favori Marka" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<RemoveRedEyeOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={coupons.length.toString()} content="Alınan Kupon" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<CommentIcon sx={{ scale: "1.5", ml: "10px" }} />} count={comments.length.toString()} content="Yorum" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                    </Box>
                    <Box m="30px 20px 0 20px" pb="30px">
                        <Accordion disabled={favorites.length < 1}>
                            <AccordionSummary id='ad-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Favoriler</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0">
                                    <Box m="10px 0 0 0">
                                        {favorites.map((fav) =>
                                        (
                                            <Box key={fav.id + "fav"}
                                                display="inline-block"
                                                m="10px"
                                                padding="20px"
                                                sx={{
                                                    background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500],
                                                    borderRadius: "20px"
                                                }}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    <img
                                                        alt="brand-logo"
                                                        src={fav.logo_link}
                                                        width="40px"
                                                        height="40px"
                                                        style={{ cursor: "pointer", borderRadius: "50%" }} />
                                                    <Typography variant='h4' ml="10px">{fav.name}</Typography>
                                                </Box>
                                            </Box>
                                        )
                                        )}
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={coupons.length < 1}>
                            <AccordionSummary id='coupon-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Kuponlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InfiniteScroll
                                    dataLength={coupons.length}
                                    next={() => fetchCoupons(coupons.length)}
                                    hasMore={hasMore}
                                    loader={<h4>Yükleniyor...</h4>}
                                    height={510}
                                >
                                    <Box m="10px 0 0 0" maxHeight="500px">
                                        {coupons.map((coupon) => <CouponBox key={coupon.id + "coupon"} coupon={coupon} userId={userId} />)}
                                    </Box>
                                </InfiniteScroll>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={notComplatedSeries.length < 1}>
                            <AccordionSummary id='ncs-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Yarıda Kalanlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InfiniteScroll
                                    dataLength={notComplatedSeries.length}
                                    next={() => fetchNotComplatedSeries(notComplatedSeries.length)}
                                    hasMore={hasMoreNCS}
                                    loader={<h4>Yükleniyor...</h4>}
                                    height={510}
                                >
                                    <Box m="10px 0 0 0" maxHeight="500px">
                                        {notComplatedSeries.map((ncs) => <NCSBox key={ncs.id + "ncs"} ncs={ncs} />)}
                                    </Box>
                                </InfiniteScroll>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={typeof comments === "string" || comments.length < 1}>
                            <AccordionSummary id='comment-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Yorumlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0">
                                    {typeof comments !== "string" && comments.map((comment) =>
                                    (
                                        <Box key={comment.id + "comment"}>
                                            <CommentBox comment={comment} isEditable={true} approveComment={approveComment} deleteComment={deleteComment} />
                                        </Box>
                                    )
                                    )}

                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={answers.length < 1}>
                            <AccordionSummary id='answers-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Cevaplar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InfiniteScroll
                                    dataLength={answers.length}
                                    next={() => fetchAnswers(answers.length)}
                                    hasMore={hasMoreAnswers}
                                    loader={<h4>Yükleniyor...</h4>}
                                    height={510}
                                >
                                    <Box m="10px 0 0 0" maxHeight="500px">
                                        {answers.map((answer) =>
                                            <Box key={"ans" + answer.id} m="10px" mb="20px" p="20px" display="inline-block"
                                                sx={{
                                                    background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500],
                                                    borderRadius: "20px"
                                                }}
                                            >
                                                <Box display="flex" justifyContent="space-between">
                                                    <Box>
                                                        <Typography variant='h3'>{answer.question}</Typography>
                                                    </Box>
                                                </Box>

                                                <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
                                                <Box display="flex" gap="10px" mt="20px">
                                                    <Box padding="1px 10px" sx={{ background: (answer.answer === answer.answer1 ? colors.greenAccent[700] : colors.blueAccent[700]), borderRadius: "10px" }}>
                                                        <Typography variant='h6'>{answer.answer1}</Typography>
                                                    </Box>
                                                    <Box padding="1px 10px" sx={{ background: (answer.answer === answer.answer2 ? colors.greenAccent[700] : colors.blueAccent[700]), borderRadius: "10px" }}>
                                                        <Typography variant='h6'>{answer.answer2}</Typography>
                                                    </Box>
                                                    <Box padding="1px 10px" sx={{ background: (answer.answer === answer.answer3 ? colors.greenAccent[700] : colors.blueAccent[700]), borderRadius: "10px" }}>
                                                        <Typography variant='h6'>{answer.answer3}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}

                                    </Box>
                                </InfiniteScroll>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default UserDetails;