import { Button, Rating, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios';
import { Formik } from 'formik';
import React from 'react'
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';
import * as yup from "yup";
import { useState } from 'react';

function ShortcutBox({ shortcut, fetchShortcuts, deleteAddition }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isLoading, setIsLoading] = useState(false);
    const { token } = useContext(UserContext);

    const handleDeleteClick = () => {
        if (shortcut && shortcut.id) {
            axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/shortcut/deleteShortcut?token=${token}&shortcutId=${shortcut.id}`)
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        fetchShortcuts();
                    }
                });
        } else {
            deleteAddition();
        }

    }

    const initialValues = {
        value: shortcut.value ? shortcut.value : "",
        shortcutImage: null,
        shortcutImageLink: shortcut.image_link ? shortcut.image_link : ""
    }

    const shortcutSchema = yup.object().shape({
        value: yup.string().required("zorunlu"),
        shortcutImageLink: yup.string(),
        shortcutImage: yup.mixed().when('shortcutImageLink', {
            is: (shortcutImageLink) => !shortcutImageLink || shortcutImageLink === "",
            then: yup.string()
                .required('zorunlu')
        })
    });

    const handleFormSubmit = async (values) => {
        setIsLoading(true);
        var data = {
            shortcut: {
                value: values.value
            }
        }
        if (values.shortcutImage) {
            await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/shortcut/uploadShortcutImage?token=${token}`, { shortcutImage: values.shortcutImage }, {
                headers: {
                    "content-type": "multipart/form-data"
                },
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    data.shortcut.image_path = response.data.imagePath;
                }
            });
        }
        if (shortcut && shortcut.id) {
            //update
            await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/shortcut/updateShortcut?token=${token}&shortcutId=${shortcut.id}`, data)
                .catch((err) => {

                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {

                    }
                });
        } else {
            //new
            await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/shortcut?token=${token}`, data)
                .catch((err) => {

                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        shortcut.id = response.data.shortcutId;
                    }
                });
        }


        setIsLoading(false);
        fetchShortcuts();
    }

    return (
        <Box mb="20px" p="20px" sx={{ background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={shortcutSchema}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="grid" gridTemplateColumns="repeat(1, minmax(0, 1fr))">
                            <Button component="label" style={{ gridColumn: "span 1", textAlign: "center" }}>
                                {(values.shortcutImage || (values.shortcutImageLink && values.shortcutImageLink !== "")) && <img alt='square brand logo' height="150px" width="150px" src={values.shortcutImage ? URL.createObjectURL(values.shortcutImage) : values.shortcutImageLink} />}
                                {(!values.shortcutImage && !(values.shortcutImageLink && values.shortcutImageLink !== "")) &&
                                    <Typography variant='h5' color={colors.grey[100]} sx={{ height: "50px", width: "150px", my: "50px" }}>Yükle</Typography>}
                                <input id="shortcutImage" name="shortcutImage" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("shortcutImage", event.target.files[0]); }} hidden />
                            </Button>
                            <TextField
                                variant="filled"
                                type="text"
                                label="Kısayol"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.value}
                                name="value"
                                error={!!touched.value && !!errors.value}
                                helperText={touched.value && errors.value}
                                sx={{ gridColumn: "span 1", mx: "10px" }}
                            />
                        </Box>
                        <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black"), marginTop: "20px" }} />

                        <Box display="flex" justifyContent="center" gap="20px" m="20px 20px 0px 20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Kaydet
                            </Button>
                            <Button variant='contained' color='error' onClick={() => { handleDeleteClick() }}>
                                Sil
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default ShortcutBox