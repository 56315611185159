import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react'
import Header from '../../../../components/Header';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import StatCard from '../../../../components/StatCard';
import { tokens } from '../../../../theme';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import BubbleBox from '../../../../components/BubbleBox';
import QuestionBox from '../../../../components/QuestionBox';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

function CouponDetails() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const adId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];
    const userId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 2] === "coupon-details" ? null : window.location.pathname.split("/")[window.location.pathname.split("/").length - 2];

    const [ad, setAd] = useState(null);
    const [bubbles, setBubbles] = useState([]);
    const [adQuestions, setAdQuestions] = useState([]);
    const [userWatches, setUserWatches] = useState([]);
    const [brandQuestions, setBrandQuestions] = useState([]);
    const [generalQuestions, setGeneralQuestions] = useState([]);
    const [data, setData] = useState(null);

    const options = {
        responsive: true,
        scales: {
            y: {
                ticks: { color: colors.grey[400], stepSize: 100 },
                grid: {
                    color: colors.grey[400]
                },
                beginAtZero: true, max: 1080
            },
            x: {
                ticks: { color: colors.grey[400], stepSize: 100 },
                grid: {
                    color: colors.grey[400]
                },
                beginAtZero: true, max: 1920
            }
        }
    };


    useEffect(() => {
        fetchAdData();
        getStatistics();
    }, []);

    const fetchAdData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAdDetails?token=${token}&id=${adId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    if (response.data.ad) {
                        setAd(response.data.ad);
                        if (response.data.ad.bubbles) {
                            if (userId) {
                                axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getBubbles?token=${token}&userId=${userId}&adId=${adId}`)
                                    .catch((err) => {
                                        console.log("err: " + err);
                                        setBubbles([]);
                                    })
                                    .then((response) => {
                                        if (response && response.data.success === true && response.data.status === 200) {
                                            if (response.data.bubbles) {
                                                setBubbles(response.data.bubbles);
                                            }
                                        }
                                    });
                            } else {
                                setBubbles(response.data.ad.bubbles);
                            }
                        }
                        if (response.data.ad.questions) {
                            if (userId) {
                                axios.get(`${process.env.REACT_APP_API_URL}api/alcago/answer/getQuestionsWithAnswers?token=${token}&userId=${userId}&adId=${adId}`)
                                    .catch((err) => {
                                        console.log("err: " + err);
                                        setAdQuestions([]);
                                    })
                                    .then((response) => {
                                        if (response && response.data.success === true && response.data.status === 200) {
                                            if (response.data.questions) {
                                                setAdQuestions(response.data.questions);
                                            }
                                        }
                                    });
                            } else {
                                setAdQuestions(response.data.ad.questions);
                            }
                        }
                        if (userId) {
                            axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getWatchData?token=${token}&userId=${userId}&adId=${adId}`)
                                .catch((err) => {
                                    console.log("err: " + err);
                                    setUserWatches([]);
                                })
                                .then((response) => {
                                    if (response && response.data.success === true && response.data.status === 200) {
                                        if (response.data.watchData) {
                                            setUserWatches(response.data.watchData);
                                        }
                                    }
                                });
                        }
                    }
                }
            });
    }

    const getStatistics = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getBubbleTouches?token=${token}&adId=${adId}`)
            .catch((err) => {
                console.log("err: " + err);
                setData({
                    labels: ["x kordinatı", "y kordinatı"],
                    datasets: [
                        {
                            label: 'Patlatılan Baloncuk Pozisyonları',
                            backgroundColor: "#868dfb",
                            borderColor: "#868dfb",
                            data: [{ x: 0, y: 0 }],
                            pointRadius: 10
                        }
                    ],
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setData({
                        labels: ["x kordinatı", "y kordinatı"],
                        datasets: [
                            {
                                label: 'Patlatılan Baloncuk',
                                backgroundColor: "rgba(40, 161, 44, 0.35)",
                                data: response.data.bubbleTouches.filter((bt) => bt.type === 1 && bt.touched === 1),
                                pointRadius: 10
                            },
                            {
                                label: 'Patlatılmayan Baloncuk',
                                backgroundColor: "rgba(186, 22, 22, 0.35)",
                                data: response.data.bubbleTouches.filter((bt) => bt.type === 1 && bt.touched === 0),
                                pointRadius: 10
                            }, {
                                label: 'Patlatılmayan Bomba',
                                backgroundColor: "rgba(40, 161, 44, 0.35)",
                                data: response.data.bubbleTouches.filter((bt) => bt.type === 0 && bt.touched === 0),
                                pointRadius: 10
                            }, {
                                label: 'Patlatılan Bomba',
                                backgroundColor: "rgba(186, 22, 22, 0.35)",
                                data: response.data.bubbleTouches.filter((bt) => bt.type === 0 && bt.touched === 1),
                                pointRadius: 10
                            }
                        ],
                    });

                }
            });
    }

    const getFindFromStr = (key) => {
        const findFroms = {
            wallet_page_featured: "Cüzdan Sayfası - Öne Çıkarılmış Reklamlar",
            main_page_featured: "Ana Sayfa - Öne Çıkarılmış Reklamlar",
            main_page_not_complated: "Ana Sayfa - Yarıda Kalanlar",
            brand_profile_coupons: "Marka Profili - Marka Kuponları",
            all_ads_page: "İnteraktif Reklamlar Sayfası",
            brand_other_coupons: "Reklam Detay Sayfası - Markanın Diğer Kuponları",
            saved_ads_featured: "Kaydedilen Reklamlar Sayfası - Öne Çıkarılmış Reklamlar",
            saved_ads_saveds: "Kaydedilen Reklamlar",
        }

        return findFroms[key];
    }

    return (
        <Box mt="20px">
            {!ad &&
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                </Box>
            }
            {ad &&
                <Box>
                    <Box display="flex">
                        <Box display="flex">
                            <IconButton style={{ width: "40px", height: "40px", marginRight: "5px" }}
                                onClick={() => {
                                    navigate("/admin-panel/users");
                                }}>
                                <ArrowBackIosNewOutlinedIcon />
                            </IconButton>
                            <Box display="flex" mr="5px">
                                <img
                                    alt="profile-user"
                                    src={ad.thumbnail_image_link}
                                    width="40px"
                                    height="40px"
                                    style={{ cursor: "pointer", borderRadius: "50%" }} />
                            </Box>
                            <Box display="flex">
                                <Header title={ad.brand_name} subtitle={ad.title} />
                            </Box>
                        </Box>

                    </Box>
                    <Box mx="30px" pt="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Yayınlanma Tarihi</b> <hr />{new Date(ad.created_date).toLocaleTimeString("tr-TR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="100px" maxWidth="250px" ml="20px" mb="20px"><b>Kupon Sayısı</b> <hr />{ad.remaining_coupon_count + "/" + ad.starting_coupon_count}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="100px" maxWidth="250px" ml="20px" mb="20px"><b>İzlenme Sayısı</b> <hr />{ad.watch_count}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="100px" maxWidth="250px" ml="20px" mb="20px"><b>Baloncuk Sayısı</b> <hr />{bubbles.length}</Typography>
                        <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="100px" maxWidth="250px" ml="20px" mb="20px"><b>Reklama Özel Soru Sayısı</b> <hr />{adQuestions.length}</Typography>
                        {userId &&
                            <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>Kullanıcının İzleme Sayısı</b> <hr />{userWatches.length}</Typography>
                        }
                        {userId && userWatches && userWatches.length > 0 &&
                            <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px">
                                <b>Kullanıcının Kuponu Bulduğu Yer</b>
                                <hr />
                                {userWatches.map((watch) => getFindFromStr(watch.find_from))}
                            </Typography>
                        }
                        {/* 
                        
                        {userId &&
                            <Typography display="inline-block" border={"1px solid " + colors.primary[100]} borderRadius="10px" p="10px" variant='h6' minWidth="150px" maxWidth="250px" ml="20px" mb="20px"><b>İzlenme Sayısı</b> <hr />{ad.watch_count}</Typography>
                        } */}
                    </Box>
                    {/* <Box display="grid" gap="20px" gridTemplateColumns="repeat(5, minmax(0, 1fr))" marginRight="60px" mt="20px">
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.today.toFixed(2) + " ₺"} content="Bugün" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.thisMonth.toFixed(2) + " ₺"} content="Bu ay" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.thisYear.toFixed(2) + " ₺"} content="Bu yıl" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.total.toFixed(2) + " ₺"} content="Toplam" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                    </Box> */}
                    {/* <Box display="grid" gap="20px" gridTemplateColumns="repeat(5, minmax(0, 1fr))" marginRight="60px" mt="10px" gridColumn="span 6">
                        <StatCard icon={<FavoriteOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={favorites.length.toString()} content="Favori Marka" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<RemoveRedEyeOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={coupons.length.toString()} content="Alınan Kupon" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<CommentIcon sx={{ scale: "1.5", ml: "10px" }} />} count={comments.length.toString()} content="Yorum" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                    </Box> */}
                    <Box m="30px 20px 0 20px" pb="30px">
                        <Accordion disabled={bubbles.length < 1}>
                            <AccordionSummary id='coupon-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Baloncuklar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0">
                                    <Box>
                                        {bubbles.map((bubble) => <BubbleBox key={bubble.id + "bubble"} bubble={bubble} />)}
                                    </Box>
                                    <Box>
                                        <Bubble options={options} data={data} />
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={adQuestions.length < 1}>
                            <AccordionSummary id='coupon-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Reklam Soruları</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0" maxHeight="500px">
                                    {adQuestions.map((adQuestion) => <QuestionBox key={adQuestion.id + "adQuestion"} question={adQuestion} />)}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        {/* <Accordion disabled={notComplatedSeries.length < 1}>
                            <AccordionSummary id='ncs-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Yarıda Kalanlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <InfiniteScroll
                                    dataLength={notComplatedSeries.length}
                                    next={() => fetchNotComplatedSeries(notComplatedSeries.length)}
                                    hasMore={hasMore}
                                    loader={<h4>Yükleniyor...</h4>}
                                    height={510}
                                >
                                    <Box m="10px 0 0 0" maxHeight="500px">
                                        {notComplatedSeries.map((ncs) => <NCSBox key={ncs.id + "ncs"} ncs={ncs} />)}
                                    </Box>
                                </InfiniteScroll>
                            </AccordionDetails>
                        </Accordion> */}
                        {/* <Accordion disabled={typeof comments === "string" || comments.length < 1}>
                            <AccordionSummary id='comment-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Yorumlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0">
                                    {typeof comments !== "string" && comments.map((comment) =>
                                    (
                                        <Box key={comment.id + "comment"}>
                                            <CommentBox comment={comment} isEditable={true} approveComment={approveComment} deleteComment={deleteComment} />
                                        </Box>
                                    )
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion> */}
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default CouponDetails;