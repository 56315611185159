import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useTheme } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext'
import RefCodeBox from '../../../../components/RefCodeBox'
import { tokens } from '../../../../theme'
import axios from 'axios'


function RefCodes() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token, user } = useContext(UserContext);
  const [refCodes, setRefCodes] = useState([]);

  useEffect(() => {
    fetchRefCodes();
  }, []);

  const fetchRefCodes = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandRefCodes?token=${token}&brandId=${user.id}`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setRefCodes(response.data.refCodes);
        }
      });
  }

  const [open, setOpenDialog] = useState(false);
  const [newCode, setNewCode] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const handleDialogClose = () => {
    fetchRefCodes();
    setOpenDialog(false);
    setNewCode("");
    setAlertMessage("");
  }

  const addNewCode = () => {
    if (newCode && newCode !== "") {
      if (newCode.length < 6) {
        setAlertMessage("Kod uzunluğu en az 6 karakter olmalıdır.");
      } else {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/user/checkRefCodeExists?refCode=${newCode}`)
          .catch((err) => {
            console.log("err: " + err);
            if (err.response.data.status === 404) {
              axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/createNewRefCode?token=${token}`, { brand_id: user.id, ref_code: newCode })
                .catch((err) => {
                  console.log("err: " + err);
                })
                .then((response) => {
                  if (response && response.data.success === true && response.data.status === 201) {
                    fetchRefCodes();
                    setOpenDialog(false);
                    setNewCode("");
                  }
                });
            }
          })
          .then((response) => {
            if (response && response.data.success === true && response.data.status === 200 && response.data.isExists) {
              setAlertMessage("Bu referans kodu kullanımda.");
            }
          });
      }
    } else {
      setAlertMessage("Lütfen kodu giriniz.");
    }
  }

  return (
    <Box mt="20px">
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Yeni Kod Ekle</DialogTitle>
        <DialogContent
          sx={{ textAlign: "center" }}>
          {alertMessage && alertMessage !== "" && <Alert severity="error" sx={{ mb: "20px" }}>{alertMessage}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            label="Kod"
            type="text"
            value={newCode}
            onChange={(e) => {
              setAlertMessage("");
              if (/^[a-zA-Z0-9]+$/.test(e.target.value) || e.target.value === "") {
                setNewCode(e.target.value)
              }
            }}
            inputProps={{ maxLength: 8 }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={addNewCode} sx={{ m: "0 auto 10px auto" }}>Ekle</Button>
        </DialogActions>
      </Dialog>
      <Box ml="20px" display="flex" alignItems="center">
        <Header title="Referans Kodları" subtitle="Referans kodlarınızı görüntüleyebilir ve yeni kod oluşturabilirsiniz." />
        <Button variant='contained' color='success' sx={{ ml: "30px" }} onClick={() => setOpenDialog(true)}>Yeni Ekle</Button>
      </Box>
      <Box>
        {refCodes.map((refCode, i) =>
          <Box key={i} display="inline-block" p="20px">
            <RefCodeBox refCode={refCode} type={"influencer_brand"} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default RefCodes;