import { Button, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext } from 'react'
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';
import axios from 'axios';

function AdditionalApCampaignBox({ campaign, fetchCampaigns }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);

    const deleteCampaign = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/ad/deleteAdditionalApCampaign?token=${token}&campaignId=${campaign.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    fetchCampaigns();
                }
            });
    }

    return (
        <Box mb="20px" p="20px" width="970px" sx={{ background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box display="flex">
                {campaign.influencer_name &&
                    <Box minWidth="190px" maxWidth="190px" textAlign="center" m="0 10px" p="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                        <Typography variant='h5' mb="25px">Catcher Inviter</Typography>
                        <img
                            alt="profile-influencer"
                            src={campaign.influencer_profile_photo_link}
                            width="40px"
                            height="40px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />

                        <Typography variant='h4' mt="5px" noWrap>{campaign.influencer_name}</Typography>
                    </Box>
                }

                {campaign.influencer_name &&
                    <Box minWidth="190px" maxWidth="190px" textAlign="center" m="0 10px" p="20px" pb="0" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                        <Typography variant='h5'>Referans Kodu</Typography>
                        <Typography variant='h4' mt="35px" noWrap>{campaign.influencer_ref_code ? campaign.influencer_ref_code : "Tümü"}</Typography>
                    </Box>
                }

                {campaign.brand_name &&
                    <Box minWidth="190px" maxWidth="190px" textAlign="center" m="0 10px" p="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                        <Typography variant='h5' mb="25px">Catcher Inviter(Brand)</Typography>


                        <img
                            alt="profile-brand"
                            src={campaign.brand_logo_link}
                            width="40px"
                            height="40px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />
                        <Typography variant='h4' mt="5px" noWrap>{campaign.brand_name ? campaign.brand_name : "-"}</Typography>
                    </Box>
                }

                {campaign.brand_name &&
                    <Box minWidth="190px" maxWidth="190px" textAlign="center" m="0 10px" p="20px" pb="0" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                        <Typography variant='h5'>Referans Kodu</Typography>
                        <Typography variant='h4' mt="50px" noWrap>{campaign.brand_ref_code ? campaign.brand_ref_code : "Tümü"}</Typography>
                    </Box>
                }

                <Box minWidth="190px" maxWidth="190px" textAlign="center" m="0 10px" p="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                    <Typography variant='h5' mb="5px">Geçerli Olduğu Reklam</Typography>


                    <img
                        alt="profile-brand-ad"
                        src={campaign.brand_ad_logo_link}
                        width="40px"
                        height="40px"
                        style={{ cursor: "pointer", borderRadius: "50%" }} />
                    <Typography variant='h5' mt="5px" noWrap>{campaign.brand_ad_name}</Typography>
                    <Typography variant='h6' mt="5px" noWrap>{"%" + campaign.ad_percentage + " İndirim Kuponu"}</Typography>
                </Box>

                <Box minWidth="190px" maxWidth="190px" textAlign="center" m="0 10px" p="20px" pb="5px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                    <Typography variant='h5'>Kampanya Yüzdesi</Typography>
                    <Typography variant='h2' mt="35px" noWrap>{"%" + campaign.campaign_percentage}</Typography>
                    <Typography variant='h6' mt="30px" noWrap>{new Date(campaign.last_usage_date).toLocaleDateString()}</Typography>
                </Box>



                <Box m="10px">
                    <Button variant='contained' color='error' sx={{ margin: "auto 0" }} onClick={() => {
                        deleteCampaign();
                    }}>
                        sil
                    </Button>
                </Box>


            </Box>
        </Box>
    )
}

export default AdditionalApCampaignBox