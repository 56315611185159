import { Alert, Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { tokens } from '../../../theme';
import { useContext } from 'react';
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import { useState } from "react";
import RegisterDialog from "./registerDialog";
import BrandPanel from "../../panels/brandPanel";
import AccessDialog from "./accessDialog";

function BrandLogin() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { setToken, token, user, isLoading } = useContext(UserContext);
    const [brandData, setBrandData] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAccessDialog, setOpenAccessDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [messageType, setMessageType] = useState("");
    if (localStorage.getItem("userType") !== "brand") {
        setToken(null);
        localStorage.setItem("userType", "brand");
    }

    if (user && token) {
        return (<BrandPanel />)
    } else {
        const initialValues = {
            email: "",
            password: ""
        }

        const loginSchema = yup.object().shape({
            email: yup.string().email("Geçersiz eposta").required("zorunlu"),
            password: yup.string().required("zorunlu")
        });

        const handleFormSubmit = (values) => {
            axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/login`, values)
                .catch((err) => {
                    console.log("err: " + err);
                    if (err.response.data.status === 404) {
                        setMessageType("error");
                        setErrorMessage("Kullanıcı bulunamadı");
                    } else if (err.response.data.status === 401) {
                        setMessageType("error");
                        setErrorMessage("Parola hatalı");
                    } else if (err.response.data.status === 406) {
                        if (err.response.data.error.message === "Not approved.") {
                            setMessageType("error");
                            setErrorMessage("Markanız henüz onaylanmadı");
                        } else if (err.response.data.error.message === "Not entegrated.") {
                            setBrandData({
                                brand: {
                                    name: err.response.data.error.brand_name,
                                    website: err.response.data.error.brand_website
                                }
                            });
                            setOpenAccessDialog(true);
                        }
                    }
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 200) {
                        setToken(response.data.token);
                    }
                });
        }

        const handleDialogClose = () => {
            setOpenDialog(false);
        }

        const handleDialogCloseWithAccesDialogOpen = (brandData) => {
            setBrandData(brandData);
            setOpenDialog(false);
            setOpenAccessDialog(true);
        }

        const handleAccessDialogClose = (is_brand_active) => {
            if (is_brand_active) {
                setMessageType("success");
                setErrorMessage("Entegrasyon tamamlandı tekrar giriş yapabilirsiniz.");
                setOpenAccessDialog(false);
            } else {
                setMessageType("error");
                setErrorMessage("Markanız henüz onaylanmadı");
                setOpenAccessDialog(false);
            }
        }

        if (isLoading) {
            return (
                <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" />
            )
        }
        return (
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                <Box>
                    <Box p="10px 20px" borderRadius="5px" sx={{ background: colors.grey[600] }}>
                        <Typography variant='h3' color={colors.grey[100]} m="5px 0 20px 0"> Giriş </Typography>
                        {errorMessage !== "" && messageType !== "" && <Alert variant="outlined" severity={messageType} sx={{ mb: "10px" }} onClick={() => { setErrorMessage(""); setMessageType(""); }}>{errorMessage}</Alert>}

                        <Box>
                            <Formik
                                onSubmit={handleFormSubmit}
                                initialValues={initialValues}
                                validationSchema={loginSchema}
                            >
                                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box>
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="email"
                                                label="Eposta"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.email}
                                                name="email"
                                                error={!!touched.email && !!errors.email}
                                                helperText={touched.email && errors.email}
                                            />
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="password"
                                                label="Şifre"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.password}
                                                name="password"
                                                error={!!touched.password && !!errors.password}
                                                helperText={touched.password && errors.password}
                                                sx={{ margin: "10px 0 0 0" }}
                                            />
                                        </Box>
                                        <Box display="flex" justifyContent="end" m="20px 10px 5px 0">
                                            <Button type="submit" color="secondary" variant="contained">
                                                Giriş
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                    <Typography variant="h6" textAlign="center" mt="10px" onClick={() => setOpenDialog(true)} sx={{ cursor: "pointer" }}>Üye değil misiniz?</Typography>
                    <RegisterDialog open={openDialog} handleClose={handleDialogClose} handleCloseWithAccesDialogOpen={handleDialogCloseWithAccesDialogOpen} type="new" />
                    {brandData && <AccessDialog open={openAccessDialog} handleClose={handleAccessDialogClose} brandData={brandData} />}
                </Box>
            </Box>
        )
    }
}

export default BrandLogin;