import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import Header from '../../../../../components/Header'
import { UserContext } from '../../../../../context/UserContext';
import { tokens } from '../../../../../theme';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Doughnut } from 'react-chartjs-2';
import { Formik, useField, useFormikContext } from 'formik';
import CustomDoughnutChart from '../../../../../components/CustomDoughnutChart';
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DatePickerField = ({ ...props }) => {
  const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val);
        setFieldTouched(field.name);
      }}
      className={props.mode}
    />
  );
};

function CouponStatistics() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token, user } = useContext(UserContext);
  const [data, setData] = useState(null);
  const [graphs, setGraphs] = useState([]);
  const [profileFields, setProfileFields] = useState(null);
  const [ads, setAds] = useState([]);

  const getMaxD = () => {
    var maxD = new Date();
    maxD.setHours(0, 0, 0, 0);
    return maxD;
  }
  const getMinD = () => {
    var minD = new Date(new Date().setDate(new Date().getDate() - 15));
    minD.setHours(0, 0, 0, 0);
    return minD;
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom'
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
      tooltip: {
        enabled: true,
        intersect: false,
        mode: "index",
        callbacks: {
          label: function (context) {
            return context.dataset.label + ": " + context.parsed.y;
          },
        },
      }
    },
    scales: {
      y: {
        ticks: { color: colors.grey[400] },
        grid: {
          color: colors.grey[400]
        },
        beginAtZero: true
      },
      x: {
        ticks: { color: colors.grey[400] },
        grid: {
          color: colors.grey[400]
        }
      }
    }
  };

  const [filter, setFilter] = useState({
    brandId: user.id,
    adId: -1,
    releatedAdCount: 0,
    brandKeyword: "",
    isExpired: -1,
    status: -1,
    influencerPartnerId: -1,
    influencerId: -1,
    minAge: 0,
    maxAge: 100,
    gender: "-1",
    cityOfResidence: -1,
    cityOfBirth: -1,
    education: -1,
    job: -1,
    incomeStatus: -1,
    haveCar: -1,
    team: -1,
    maritalStatus: "-1",
    findUsFrom: -1,
    hobbyId: -1,
    interestId: -1,
    dateType: "daily",
    maxDate: getMaxD(),
    minDate: getMinD()
  });

  useEffect(() => {
    getStatistics({ brandId: user.id, dateType: "daily", maxDate: getMaxD(), minDate: getMinD() });
    getProfileFields();
    getAds();
    getGraphs();
  }, []);

  const getProfileFields = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/profileField/getProfileFields`)
      .catch((err) => {
        console.log("err: " + err);
        setProfileFields(null);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setProfileFields(response.data.profileFields);
        }
      });
  }

  const getAds = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAdsWithBrandId?token=${token}&brandId=${user.id}`)
      .catch((err) => {
        console.log("err: " + err);
        setAds([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setAds(response.data.ads);
        }
      });
  }

  const getGraphs = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/graph/getGraphs?token=${token}&type=coupon&open_for_brands=1`)
      .catch((err) => {
        console.log("err: " + err);
        setGraphs([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setGraphs(response.data.graphs);
        }
      });
  }

  const getStatistics = (filter) => {
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/coupon/getGivenCouponsStatistics?token=${token}`, { filter: filter })
      .catch((err) => {
        console.log("err: " + err);
        setData({
          labels: [0, 0],
          datasets: [
            {
              label: 'Kupon Sayısı',
              backgroundColor: "#868dfb",
              borderColor: "#868dfb",
              data: [0, 0]
            }
          ],
        });
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          let labels = [];
          let dataSet = [];
          if (filter.dateType === "daily") {
            let currentDate = new Date(filter.minDate);
            while (currentDate <= filter.maxDate) {
              labels.push(currentDate.toLocaleDateString("tr"));
              currentDate.setDate(currentDate.getDate() + 1);
              dataSet.push(0);
            }
            response.data.coupons.forEach(({ date, coupon_count }) => {
              dataSet[labels.findIndex((e) => e === new Date(date).toLocaleDateString("tr"))] = coupon_count;
            });
          } else if (filter.dateType === "monthly") {
            let currentDate = new Date(
              filter.minDate.getFullYear(),
              filter.minDate.getMonth(),
              1
            );
            const monthNames = [
              "Ocak",
              "Şubat",
              "Mart",
              "Nisan",
              "Mayıs",
              "Haziran",
              "Temmuz",
              "Ağustos",
              "Eylül",
              "Ekim",
              "Kasım",
              "Aralık"
            ];
            while (currentDate <= filter.maxDate) {
              const year = currentDate.getFullYear();
              const month = currentDate.getMonth();
              const monthName = monthNames[month];
              labels.push(`${monthName} ${year}`);
              currentDate.setMonth(currentDate.getMonth() + 1);
              dataSet.push(0);
            }
            response.data.coupons.forEach(({ date, coupon_count }) => {
              dataSet[labels.findIndex((e) => e === `${monthNames[new Date(date).getMonth()]} ${new Date(date).getFullYear()}`)] = coupon_count;
            });
          } else if (filter.dateType === "yearly") {
            let currentYear = filter.minDate.getFullYear();
            while (currentYear <= filter.maxDate.getFullYear()) {
              labels.push(currentYear);
              currentYear++;
              dataSet.push(0);
            }
            response.data.coupons.forEach(({ date, coupon_count }) => {
              dataSet[labels.findIndex((e) => e === new Date(date).getFullYear())] = coupon_count;
            });
          }
          setData({
            labels: labels,
            datasets: [
              {
                label: 'Kupon Sayısı',
                backgroundColor: "#868dfb",
                borderColor: "#868dfb",
                data: dataSet,
                pointRadius: (context) => {
                  const value = context.dataset.data[context.dataIndex];
                  return value === 0 ? 0 : 3;
                }
              }
            ],
          });
        }
      });
  }

  const validationSchema = yup.object().shape({
    brandId: yup.number().required("zorunlu"),
    adId: yup.number().required("zorunlu"),
    releatedAdCount: yup.number(),
    brandKeyword: yup.string(),
    isExpired: yup.number().required("zorunlu"),
    status: yup.number().required("zorunlu"),
    influencerPartnerId: yup.number().required("zorunlu"),
    influencerId: yup.number().required("zorunlu"),
    minAge: yup.number(),
    maxAge: yup.number(),
    gender: yup.string().required("zorunlu"),
    cityOfResidence: yup.number().required("zorunlu"),
    cityOfBirth: yup.number().required("zorunlu"),
    education: yup.number().required("zorunlu"),
    job: yup.number().required("zorunlu"),
    incomeStatus: yup.number().required("zorunlu"),
    haveCar: yup.number().required("zorunlu"),
    team: yup.number().required("zorunlu"),
    maritalStatus: yup.string().required("zorunlu"),
    findUsFrom: yup.number().required("zorunlu"),
    hobbyId: yup.number().required("zorunlu"),
    interestId: yup.number().required("zorunlu"),
    maxDate: yup.date()
      .required("zorunlu"),
    minDate: yup.date()
      .required("zorunlu"),
    dateType: yup.string().required("zorunlu"),
  });

  const initialValues = {
    brandId: user.id,
    adId: -1,
    releatedAdCount: 0,
    brandKeyword: "",
    isExpired: -1,
    status: -1,
    influencerPartnerId: -1,
    influencerId: -1,
    minAge: 0,
    maxAge: 100,
    gender: "-1",
    cityOfResidence: -1,
    cityOfBirth: -1,
    education: -1,
    job: -1,
    incomeStatus: -1,
    haveCar: -1,
    team: -1,
    maritalStatus: "-1",
    findUsFrom: -1,
    hobbyId: -1,
    interestId: -1,
    dateType: "daily",
    maxDate: getMaxD(),
    minDate: getMinD()
  }

  const handleFormSubmit = (values) => {
    getStatistics(values);
    setFilter(values);
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Kupon İstatistikleri" subtitle="İstatistikler sayfasına hoş geldiniz" />
      </Box>
      <Box display="grid" gap="50px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" paddingBottom="30px">
        {!profileFields &&
          <Box gridColumn="span 4" display="flex" justifyContent="center" alignItems="center" height="200px" >
            <CircularProgress color='success' variant="indeterminate" />
          </Box>
        }
        {profileFields &&
          <Box gridColumn="span 4" >
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="grid" gap="30px" gridTemplateColumns="repeat(10, minmax(0, 1fr))" mb="30px">
                    <Typography variant='h4' gridColumn="span 10" pt="25px">Kupon Filtreleri</Typography>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Reklam</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Reklam"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="adId"
                        value={values.adId}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {ads.map((ad) => <MenuItem key={"ad" + ad.id} value={ad.id}>{ad.brand_name + " - " + ad.coupon_title}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Minimum Reklam Sayısı"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.releatedAdCount}
                      name="releatedAdCount"
                      error={!!touched.releatedAdCount && !!errors.releatedAdCount}
                      helperText={touched.releatedAdCount && errors.releatedAdCount}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Kategori"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.brandKeyword}
                      name="brandKeyword"
                      error={!!touched.brandKeyword && !!errors.brandKeyword}
                      helperText={touched.brandKeyword && errors.brandKeyword}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Son Kullanma Tarihi</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Son Kullanma Tarihi"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="isExpired"
                        value={values.isExpired}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        <MenuItem value={1}>Geçmiş</MenuItem>
                        <MenuItem value={0}>Geçmemiş</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Kullanım Durumu</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Kullanım Durumu"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="status"
                        value={values.status}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        <MenuItem value={1}>Kullanılmış</MenuItem>
                        <MenuItem value={0}>Kullanılmamış</MenuItem>
                      </Select>
                    </FormControl>

                    <Typography variant='h4' gridColumn="span 10" pt="25px">Kullanıcı Demografisi</Typography>

                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Minimum Yaş"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.minAge}
                      name="minAge"
                      error={!!touched.minAge && !!errors.minAge}
                      helperText={touched.minAge && errors.minAge}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Maximum Yaş"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.maxAge}
                      name="maxAge"
                      error={!!touched.maxAge && !!errors.maxAge}
                      helperText={touched.maxAge && errors.maxAge}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Cinsiyet</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Cinsiyet"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="gender"
                        value={values.gender}
                      >
                        <MenuItem value={"-1"}>Tümü</MenuItem>
                        <MenuItem value={"Kadın"}>Kadın</MenuItem>
                        <MenuItem value={"Erkek"}>Erkek</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Yaşadığı Şehir</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Yaşadığı Şehir"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="cityOfResidence"
                        value={values.cityOfResidence}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.cities.map((city) => <MenuItem key={"cityr" + city.id} value={city.id}>{city.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Doğduğu Şehir</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Doğduğu Şehir"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="cityOfBirth"
                        value={values.cityOfBirth}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.cities.map((city) => <MenuItem key={"cityb" + city.id} value={city.id}>{city.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Öğrenim Durumu</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Öğrenim Durumu"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="education"
                        value={values.education}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.education_statuses.map((educationStatus) => <MenuItem key={"education" + educationStatus.id} value={educationStatus.id}>{educationStatus.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Meslek</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Meslek"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="job"
                        value={values.job}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.jobs.map((job) => <MenuItem key={"job" + job.id} value={job.id}>{job.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Gelir Durumu</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Gelir Durumu"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="incomeStatus"
                        value={values.incomeStatus}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.income_statuses.map((income_status) => <MenuItem key={"income_status" + income_status.id} value={income_status.id}>{income_status.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Araba</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Araba"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="haveCar"
                        value={values.haveCar}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        <MenuItem value={1}>Var</MenuItem>
                        <MenuItem value={0}>Yok</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Tuttuğu Takım</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Tuttuğu Takım"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="team"
                        value={values.team}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.teams.map((team) => <MenuItem key={"team" + team.id} value={team.id}>{team.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Medeni Hal</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Medeni Hal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="maritalStatus"
                        value={values.maritalStatus}
                      >
                        <MenuItem value={"-1"}>Tümü</MenuItem>
                        <MenuItem value={"Evli"}>Evli</MenuItem>
                        <MenuItem value={"Bekar"}>Bekar</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Bizi Nereden Buldun?</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Bizi Nereden Buldun?"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="findUsFrom"
                        value={values.findUsFrom}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.findable_areas.map((findable_area) => <MenuItem key={"findable_area" + findable_area.id} value={findable_area.id}>{findable_area.value}</MenuItem>)}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Hobi</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Hobi"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="hobbyId"
                        value={values.hobbyId}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.hobbies.map((hobby) => <MenuItem key={"hobby" + hobby.id} value={hobby.id}>{hobby.value}</MenuItem>)}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>İlgi Alanı</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="İlgi Alanı"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="interestId"
                        value={values.interestId}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.interests.map((interest) => <MenuItem key={"interest" + interest.id} value={interest.id}>{interest.value}</MenuItem>)}
                      </Select>
                    </FormControl>

                    <Typography variant='h4' gridColumn="span 10" pt="25px">Tarih</Typography>

                    <Box gridColumn="span 2">
                      <InputLabel size='normal' error={!!touched.minDate && !!errors.minDate} htmlFor="minDate">Başlangıç Tarihi:</InputLabel>
                      <DatePickerField name="minDate" mode={theme.palette.mode} />
                    </Box>

                    <Box gridColumn="span 2">
                      <InputLabel size='normal' error={!!touched.maxDate && !!errors.maxDate} htmlFor="maxDate">Bitiş Tarihi:</InputLabel>
                      <DatePickerField name="maxDate" mode={theme.palette.mode} />
                    </Box>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2", mt: "18px" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Gösterim Tipi</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Gösterim Tipi"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="dateType"
                        value={values.dateType}
                      >
                        <MenuItem value={"daily"}>Günlük</MenuItem>
                        <MenuItem value={"monthly"}>Aylık</MenuItem>
                        <MenuItem value={"yearly"}>Yıllık</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box m="20px 20px 0px 0px">
                    <Button type="submit" color="secondary" variant="contained">
                      <Typography variant='h6'>Uygula</Typography>
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        }
        <Box display="flex" gridColumn="span 4" justifyContent="center">
          {data && <Line options={options} data={data} />}
        </Box>

        <Typography variant='h2' mt="20px" gridColumn="span 4">Karşılaştırma Grafikleri</Typography>

        <Box display="grid" gap="10px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" gridColumn="span 4">
          {graphs.map((graph) =>
            <Box key={"graph" + graph.id} gridColumn="span 1">
              <CustomDoughnutChart filter={filter} graph={graph} profileFields={profileFields} />
            </Box>
          )}
        </Box>

        {/* <Box gridColumn="span 1">
          {data &&
            <Doughnut data={{
              labels: ['Red', 'Blue'],
              datasets: [
                {
                  label: '# of Votes',
                  data: [12, 19],
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                  ],
                  borderWidth: 1,
                },
              ]
            }} />}
        </Box> */}
      </Box>
    </Box>
  )
}

export default CouponStatistics