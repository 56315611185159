import { IconButton, Slide, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function NCSBox({ ncs }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);

    return (
        <Box m="10px" mb="20px" p="20px" display="inline-block"
            sx={{
                background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500],
                borderRadius: "20px"
            }}
        >
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center" >
                    <Box>
                        <img
                            alt="brand-logo"
                            src={ncs.brand_logo_link}
                            width="40px"
                            height="40px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />
                    </Box>
                    <Box ml="10px">
                        <Box display="flex" gap="5px">
                            <Typography variant='h4'>{ncs.brand_name}</Typography>
                        </Box>
                        <Typography variant='h6' >{ncs.title}</Typography>
                    </Box>
                </Box>
            </Box>

            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Box display="flex" gap="10px">
                <Box padding="1px 10px" sx={{ background: ncs.remaining_coupon_count <= 0 ? colors.redAccent[600] : colors.greenAccent[600], borderRadius: "10px" }}>
                    <Typography variant='h6'>{ncs.remaining_coupon_count + "/" + ncs.starting_coupon_count}</Typography>
                </Box>
                <Box padding="1px 10px" sx={{ background: ncs.is_expired ? colors.redAccent[600] : colors.greenAccent[600], borderRadius: "10px" }}>
                    <Typography variant='h6'>{(ncs.is_expired ? "Tarihi Geçmiş - " : "") + new Date(ncs.last_usage_date).toLocaleDateString()}</Typography>
                </Box>
            </Box>
        </Box >
    )
}

export default NCSBox