import { useContext } from 'react';
import { Box, IconButton, useTheme } from "@mui/material";
import { ColorModeContext, tokens } from '../../theme';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';

function Topbar() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const { setToken, user } = useContext(UserContext);
    const navigate = useNavigate();

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* LOGO */}
            <h2>ALCAGO</h2>


            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
                </IconButton>

                <IconButton onClick={() => navigate(`/${user.type}-panel/profile`)}>
                    <PersonOutlinedIcon />
                </IconButton>

                <IconButton>
                    <SettingsOutlinedIcon />
                </IconButton>

                <IconButton onClick={() => { setToken(null); }}>
                    <LogoutOutlinedIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default Topbar;