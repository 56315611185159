import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Box, Button, Dialog, DialogContent, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../../../../theme';
import { UserContext } from '../../../../../context/UserContext';
import * as yup from "yup";
import { Formik, useField, useFormikContext } from "formik";
import DatePicker from 'react-datepicker';

const DatePickerField = ({ ...props }) => {
    const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
    const [field] = useField(props);
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            onChange={val => {
                setFieldValue(field.name, val);
                setFieldTouched(field.name);
            }}
            className={props.mode}
        />
    );
};

function AddAdditionalApCampaignDialog({ open, handleClose }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.colors);
    const { token } = useContext(UserContext);
    const [ads, setAds] = useState([]);
    const [influencers, setInfluencers] = useState([]);
    const [influencerBrands, setInfluencerBrands] = useState([]);
    const [influencerRefCodes, setInfluencerRefCodes] = useState([]);
    const [influencerBrandRefCodes, setInfluencerBrandRefCodes] = useState([]);

    const getInfluencers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencer/getInfluencers?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setInfluencers([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setInfluencers(response.data.influencers);
                }
            });
    }

    const getBrands = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getAllBrands?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setInfluencerBrands([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setInfluencerBrands(response.data.brands.filter(({ is_influencer }) => is_influencer === 1));
                }
            });
    }

    const getInfluencerRefCodes = (influencerId) => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencer/getInfluencerRefCodes?token=${token}&influencerId=${influencerId}`)
            .catch((err) => {
                console.log("err: " + err);
                setInfluencerRefCodes([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setInfluencerRefCodes(response.data.refCodes);
                }
            });
    }

    const getInfluencerBrandRefCodes = (brandId) => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandRefCodes?token=${token}&brandId=${brandId}`)
            .catch((err) => {
                console.log("err: " + err);
                setInfluencerBrandRefCodes([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setInfluencerBrandRefCodes(response.data.refCodes);
                }
            });
    }

    const getAds = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAllAds?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setAds([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setAds(response.data.ads);
                }
            });
    }

    var initialValues = {
        influencer_id: null,
        influencer_ref_id: null,
        influencer_brand_id: null,
        influencer_brand_ref_id: null,
        ad_id: null,
        campaign_percentage: 0,
        user_based_usage_limit: 0,
        last_usage_date: new Date((new Date()).setDate(new Date().getDate() + 7))
    }

    var shapeObject = {
        ad_id: yup.number().required("zorunlu"),
        campaign_percentage: yup.number().min(1, "En az 1 olabilir").max(100, "En fazla 100 olabilir.").required("zorunlu"),
        user_based_usage_limit: yup.number().min(1, "En az 1 olabilir").required("zorunlu"),
        last_usage_date: yup.date().required("zorunlu").min(new Date(), "Bitiş tarihi bugünden eski olamaz")
    }

    const campaignSchema = yup.object().shape(shapeObject);

    useEffect(() => {
        getInfluencers();
        getBrands();
        getAds();
    }, [open]);

    const handleFormSubmit = async (values) => {
        if (values.influencer_id || values.influencer_brand_id) {
            values.influencer_ref_id = values.influencer_ref_id === -1 ? null : values.influencer_ref_id
            values.influencer_brand_ref_id = values.influencer_brand_ref_id === -1 ? null : values.influencer_brand_ref_id
            await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/ad/createAdditionalApCampaign?token=${token}`, { campaign: values }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                }
            });
        }
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={open}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    Ek AP Kampanyası Ekle
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={campaignSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>

                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px">
                                    {values.influencer_brand_id === null &&
                                        <FormControl fullWidth variant="filled"
                                            sx={{ gridColumn: "span 1" }}>
                                            <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Catcher Inviter</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Catcher Inviter"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    if (e.target.value === -1) {
                                                        setInfluencerRefCodes([]);
                                                        setFieldValue("refId", -1);
                                                    } else {
                                                        getInfluencerRefCodes(e.target.value);
                                                    }
                                                    setFieldValue("influencer_id", e.target.value);
                                                    setFieldValue("influencer_ref_id", -1);
                                                }}
                                                name="influencer_id"
                                                value={values.influencer_id}
                                            >
                                                {influencers.map((inf) => <MenuItem key={"inf" + inf.id} value={inf.id}>{inf.name + " " + inf.surname}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    }

                                    {(values.influencer_brand_id === null && values.influencer_id && values.influencer_id !== -1 && influencerRefCodes && influencerRefCodes.length > 0) &&
                                        <FormControl fullWidth variant="filled"
                                            sx={{ gridColumn: "span 1" }}>
                                            <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Referans Kodu (Catcher Inviter)</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Referans Kodu (Catcher Inviter)"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    setFieldValue("influencer_ref_id", e.target.value);
                                                }}
                                                name="influencer_ref_id"
                                                value={values.influencer_ref_id}
                                            >
                                                <MenuItem value={-1}>Tümü</MenuItem>
                                                {influencerRefCodes.map((infRef) => <MenuItem key={"infRef" + infRef.id} value={infRef.id}>{infRef.ref_code}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    }

                                    {values.influencer_id === null &&
                                        <FormControl fullWidth variant="filled"
                                            sx={{ gridColumn: "span 1" }}>
                                            <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Catcher Inviter (Brand)</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Catcher Inviter (Brand)"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    if (e.target.value === -1) {
                                                        setInfluencerBrandRefCodes([]);
                                                        setFieldValue("influencer_brand_ref_id", -1);
                                                    } else {
                                                        getInfluencerBrandRefCodes(e.target.value);
                                                    }
                                                    setFieldValue("influencer_brand_id", e.target.value);
                                                    setFieldValue("influencer_brand_ref_id", -1);
                                                }}
                                                name="influencer_brand_id"
                                                value={values.influencer_brand_id}
                                            >
                                                {influencerBrands.map((infBrand) => <MenuItem key={"infBrand" + infBrand.id} value={infBrand.id}>{infBrand.name}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    }


                                    {(values.influencer_id === null && values.influencer_brand_id && values.influencer_brand_id !== -1 && influencerBrandRefCodes && influencerBrandRefCodes.length > 0) &&
                                        <FormControl fullWidth variant="filled"
                                            sx={{ gridColumn: "span 1" }}>
                                            <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Referans Kodu (Catcher Inviter (Brand))</InputLabel>
                                            <Select
                                                labelId="filter-label"
                                                label="Referans Kodu (Catcher Inviter (Brand))"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    setFieldValue("influencer_brand_ref_id", e.target.value);
                                                }}
                                                name="influencer_brand_ref_id"
                                                value={values.influencer_brand_ref_id}
                                            >
                                                <MenuItem value={-1}>Tümü</MenuItem>
                                                {influencerBrandRefCodes.map((infBrandRef) => <MenuItem key={"infBrandRef" + infBrandRef.id} value={infBrandRef.id}>{infBrandRef.ref_code}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    }
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Kampanya Yüzdesi"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"campaign_percentage"}
                                        value={values.campaign_percentage}
                                        error={!!touched.campaign_percentage && !!errors.campaign_percentage}
                                        helperText={touched.campaign_percentage && errors.campaign_percentage}
                                        sx={{ gridColumn: "span 1" }}
                                    />

                                    <FormControl fullWidth variant="filled"
                                        sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Reklam</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Reklam"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name="ad_id"
                                            value={values.ad_id}
                                        >
                                            {ads.map((ad) => <MenuItem key={"ad" + ad.id} value={ad.id}>{ad.brand_name + " - " + ad.coupon_title}</MenuItem>)}
                                        </Select>
                                    </FormControl>



                                    <Box gridColumn="span 1">
                                        <InputLabel size='normal' error={!!touched.last_usage_date && !!errors.last_usage_date} htmlFor="last_usage_date">Kampanya Son Kullanma Tarihi:</InputLabel>
                                        <DatePickerField name="last_usage_date" mode={theme.palette.mode} />
                                    </Box>

                                    <Box gridColumn="span 1">
                                        <Tooltip title={"Kullanıcının Alacağı İlk Kaç Kuponda Geçerli"}>
                                            <InputLabel size='normal' error={!!touched.user_based_usage_limit && !!errors.user_based_usage_limit} htmlFor="user_based_usage_limit">Kullanıcının Alacağı İlk Kaç Kuponda Geçerli:</InputLabel>
                                        </Tooltip>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            label=""
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"user_based_usage_limit"}
                                            value={values.user_based_usage_limit}
                                            error={!!touched.user_based_usage_limit && !!errors.user_based_usage_limit}
                                            helperText={touched.user_based_usage_limit && errors.user_based_usage_limit}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="center" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AddAdditionalApCampaignDialog;