import React, { useContext, useState } from 'react'
import { Alert, Box, Button, Checkbox, Dialog, DialogContent, FormControl, MenuItem, InputLabel, Select, TextField, Typography, useTheme } from '@mui/material';
import { Formik } from "formik";
import * as yup from "yup";
import axios from 'axios';
import { UserContext } from '../../../../../context/UserContext';

function QuestionDialog({ open, handleClose, type, question }) {
  const { token } = useContext(UserContext);
  const [alertMessage, setAlertMessage] = useState(null);

  var initialValues = {}

  if (type === "new") {
    initialValues = {
      ap_count: 100,
      question: "",
      answer1: "",
      answer2: "",
      answer3: "",
      correct_answer: "",
      targetable: 0
    }
  }

  if (type === "edit") {
    initialValues = {
      ap_count: question.ap_count,
      question: question.question,
      answer1: question.answer1,
      answer2: question.answer2,
      answer3: question.answer3,
      correct_answer: "",
      targetable: question.targetable
    }
  }

  var validationObject = {
    question: yup.string().required("zorunlu"),
    answer1: yup.string().required("zorunlu"),
    answer2: yup.string().required("zorunlu"),
    answer3: yup.string().required("zorunlu"),
    targetable: yup.number().min(0, "").max(1, "").required("zorunlu")
  }

  const questionSchema = yup.object().shape(validationObject);


  const handleFormSubmit = (values) => {
    if (type === "new") {
      axios.post(`${process.env.REACT_APP_API_URL}api/alcago/ad/createQuestion?token=${token}`, values)
        .catch((err) => {
          console.log("err: " + err);
          setAlertMessage("Bir hata oluştu");
          setTimeout(() => {
            setAlertMessage(null)
          }, 3000);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            handleClose();
          }
        });
    } else if (type === "edit") {
      axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/ad/updateQuestion?token=${token}&questionId=${question.id}`, values)
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            handleClose();
          }
        });
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box minWidth="500px" m="20px" mb="5px" textAlign="center">
        {type === "new" &&
          <Typography variant='h2'>Yeni Soru</Typography>
        }
        {type === "edit" &&
          <Typography variant='h2'>Soruyu Düzenle</Typography>
        }
        {alertMessage && <Alert severity="error" sx={{ marginTop: "20px" }}>{alertMessage}</Alert>}

      </Box>

      <DialogContent>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={questionSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" mx="75px" mb="25px">
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Soru"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name={"question"}
                  value={values.question}
                  error={!!touched.question && !!errors.question}
                  helperText={touched.question && errors.question}
                  sx={{ gridColumn: "span 3", marginTop: "20px" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="A Şıkkı"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name={"answer1"}
                  value={values.answer1}
                  error={!!touched.answer1 && !!errors.answer1}
                  helperText={touched.answer1 && errors.answer1}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="B Şıkkı"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name={"answer2"}
                  value={values.answer2}
                  error={!!touched.answer2 && !!errors.answer2}
                  helperText={touched.answer2 && errors.answer2}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="C Şıkkı"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name={"answer3"}
                  value={values.answer3}
                  error={!!touched.answer3 && !!errors.answer3}
                  helperText={touched.answer3 && errors.answer3}
                  sx={{ gridColumn: "span 1" }}
                />
                <FormControl fullWidth variant="filled">
                  <InputLabel id="filter-label">Hedeflenebilir</InputLabel>
                  <Select
                    labelId="filter-label"
                    label="Hedeflenebilir"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name={"targetable"}
                    value={values.targetable}
                    error={!!touched.targetable && !!errors.targetable}
                    sx={{ gridColumn: "span 1" }}
                  >
                    <MenuItem value={1}>Evet</MenuItem>
                    <MenuItem value={0}>Hayır</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                <Button type="submit" color="secondary" variant="contained">
                  <Typography variant='h5'>Kaydet</Typography>
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog >
  )
}

export default QuestionDialog;