import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react'
import Header from '../../../../../components/Header';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import StatCard from '../../../../../components/StatCard';
import { tokens } from '../../../../../theme';
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../../../../context/UserContext';
import CommentBox from '../../../../../components/CommentBox';
import AdBox from '../../../../../components/AdBox';
import AlertDialogSlide from '../../../../../components/AlertDialogSlide';
import RegisterDialog from '../../../../loginPages/brandLogin/registerDialog';

function BrandDetails() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const brandId = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1];

    const [brand, setBrand] = useState(null);
    const [ads, setAds] = useState([]);
    const [coupons, setCoupons] = useState([]);


    const [comments, setComments] = useState([]);


    const [earningStatistics, setEarningStatistics] = useState({
        today: 0,
        thisMonth: 0,
        thisYear: 0,
        total: 0
    });

    const getEarningStatistics = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getDashboardEarningStatisticsWithField?token=${token}&id=${brandId}&field=brand`)
            .catch((err) => {
                console.log("err: " + err);
                setEarningStatistics({
                    today: 0,
                    thisMonth: 0,
                    thisYear: 0,
                    total: 0
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setEarningStatistics(response.data.statistics);
                }
            });
    }

    const approveComment = (commentId) => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/approveComment?token=${token}&commentId=${commentId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = comments.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        comments[ix].is_approved = 1;
                        var tempComments = JSON.stringify(comments);
                        setComments([]);
                        setComments(JSON.parse(tempComments));
                    }
                }
            });
    }
    const deleteComment = (commentId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/brand/deleteComment?token=${token}&commentId=${commentId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = comments.findIndex(({ id }) => id === commentId);
                    if (ix >= 0) {
                        comments.splice(ix, 1);
                        var tempComments = JSON.stringify(comments);
                        setComments([]);
                        setComments(JSON.parse(tempComments));
                    }
                }
            });
    }


    const [featured, setFeatured] = useState(false);
    const [active, setActive] = useState(false);
    const [isInfluencer, setIsInfluencer] = useState(false);
    const handleFeatured = () => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/changeFeaturedValue?token=${token}&brandId=${brand.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {

                }
            });
    }
    const handleActive = () => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/toggleIsActive?token=${token}&brandId=${brand.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {

                }
            });
    }
    const handleInfluencerStatus = () => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/changeInfluencerStatus?token=${token}&brandId=${brand.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {

                }
            });
    }
    useEffect(() => {
        setFeatured(brand ? brand.is_featured > 0 : false);
        setActive(brand ? brand.is_active > 0 : false);
        setIsInfluencer(brand ? brand.is_influencer > 0 : false);
    }, [brand]);

    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertDialogClose = () => {
        setOpenAlertDialog(false);
    }
    const clickPositiveAnswer = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/brand/deleteBrand?token=${token}&brandId=${brand.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    navigate("/admin-panel/brands");
                }
            });
    }
    const clickNegativeAnswer = () => { }



    useEffect(() => {
        fetchBrandData()
        getEarningStatistics();
    }, []);

    const fetchBrandData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandDetailsWithId?token=${token}&brandId=${brandId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    if (response.data.brand && response.data.brand.brand) {
                        setBrand(response.data.brand.brand);
                        if (response.data.brand && response.data.brand.ads) {
                            setAds(response.data.brand.ads);
                        }
                        if (response.data.brand && response.data.brand.coupons) {
                            setCoupons(response.data.brand.coupons);
                        }
                        if (response.data.brand && response.data.brand.comments) {
                            setComments(response.data.brand.comments);
                        }
                    }
                }
            });
    }

    const [openBrandDialog, setOpenBrandDialog] = useState(false);
    const handleBrandDialogClose = () => {
        fetchBrandData();
        setOpenBrandDialog(false);
    }

    const openBrandUsers = (brand) => {
        navigate(`/admin-panel/users/br/${brand.id}/${brand.name}`);
    }

    return (
        <Box mt="20px">
            {!brand &&
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                </Box>
            }
            {brand &&
                <Box>
                    <AlertDialogSlide
                        open={openAlertDialog} handleClose={handleAlertDialogClose}
                        clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer}
                        positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                        title={"Bu markayı silmek istediğinize emin misiniz?"}
                        description={"Bu marka ile ilişkili tüm veriler silinecektir. (kullanıcı kuponları, izleme istatistikleri, reklamlar, reklam grupları vb.)"}
                    />
                    {brand && brand.id && <RegisterDialog open={openBrandDialog} handleClose={handleBrandDialogClose} type="edit" brand={brand} />}
                    <Box display="flex">
                        <Box display="flex" width="70%">
                            <IconButton style={{ width: "40px", height: "40px", marginRight: "5px" }}
                                onClick={() => {
                                    navigate("/admin-panel/brands");
                                }}>
                                <ArrowBackIosNewOutlinedIcon />
                            </IconButton>
                            <Box display="flex" alignItems="center">
                                <Header title={brandId + " - " + brand.name} subtitle={brand.description} />
                            </Box>
                        </Box>

                        <Box display="flex" gap="10%" ml="20px" alignItems="center" width="30%">
                            <Button variant="contained" style={{ background: colors.blueAccent[500] }} onClick={() => setOpenBrandDialog(true)}>
                                Düzenle
                            </Button>
                            <Button variant="contained" style={{ background: colors.redAccent[500] }} onClick={() => setOpenAlertDialog(true)}>
                                Sil
                            </Button>
                        </Box>

                    </Box>
                    <Box display="grid" gap="20px" gridTemplateColumns="repeat(5, minmax(0, 1fr))" marginRight="60px">
                        <StatCard icon={<FavoriteOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={brand.fav_count.toString()} content="Takipçi" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<RemoveRedEyeOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={brand.view_count.toString()} content="Görüntülenme" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<FeaturedVideoOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={ads.length.toString()} content="Reklam" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<SlideshowOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={ads.length > 0 ? ((typeof ads.reduce((a, b) => a.watch_count + b.watch_count) === "object" ? ads.reduce((a, b) => a.watch_count + b.watch_count).watch_count : ads.reduce((a, b) => a.watch_count + b.watch_count)) + "") : "0"} content="Reklam İzlenmesi" contentVariant="h6" sx={{ gridColumn: "span 1" }} />
                        <Box width="100%" m="0 30px" p="5px 5px 5px 10px" sx={{ background: colors.primary[400], gridColumn: "span 1", borderRadius: "20px" }}>
                            <Box display="inline-block" mr="20px">
                                <Checkbox
                                    checked={active}
                                    size="small"
                                    sx={{
                                        '&.Mui-checked': {
                                            color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200])
                                        }
                                    }}
                                    onClick={(event) => {
                                        setActive(!active);
                                        handleActive();
                                    }}
                                />
                                <Typography variant='h7'>Aktif</Typography>
                            </Box>
                            <Box display="inline-block">
                                <Checkbox
                                    checked={featured}
                                    size="small"
                                    sx={{
                                        '&.Mui-checked': {
                                            color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200])
                                        }
                                    }}
                                    onClick={(event) => {
                                        setFeatured(!featured);
                                        handleFeatured();
                                    }}
                                />
                                <Typography variant='h7'>Öne Çıkarılmış</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    checked={isInfluencer}
                                    size="small"
                                    sx={{
                                        '&.Mui-checked': {
                                            color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200])
                                        }
                                    }}
                                    onClick={(event) => {
                                        setIsInfluencer(!isInfluencer);
                                        handleInfluencerStatus();
                                    }}
                                />
                                <Typography variant='h7'>Catcher Inviter</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="grid" gap="20px" gridTemplateColumns="repeat(5, minmax(0, 1fr))" marginRight="60px" mt="10px" gridColumn="span 6">
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.today.toFixed(2) + " ₺"} content="Bugün" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.thisMonth.toFixed(2) + " ₺"} content="Bu ay" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.thisYear.toFixed(2) + " ₺"} content="Bu yıl" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={earningStatistics.total.toFixed(2) + " ₺"} content="Toplam" contentVariant='h6' sx={{ gridColumn: "span 1" }} />
                        <Button variant="contained" color="secondary" sx={{ m: "20px 40px 20px 80px", gridColumn: "span 1" }} onClick={() => openBrandUsers(brand)}>
                            Catcherları Gör
                        </Button>
                    </Box>
                    <Box m="30px 20px 0 20px" pb="30px">
                        <Accordion disabled={ads.length < 1}>
                            <AccordionSummary id='ad-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Reklamlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0">
                                    <Box m="10px 0 0 0">
                                        {ads.map((ad) =>
                                        (
                                            <AdBox key={ad.id + "ads"}
                                                id={ad.id}
                                                discount_percentage={ad.discount_percentage}
                                                watch_count={ad.watch_count}
                                                last_usage_date={ad.last_usage_date}
                                                video_link={ad.video_link}
                                                is_active={ad.is_active}
                                                width="30%" />
                                        )
                                        )}
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={coupons.length < 1}>
                            <AccordionSummary id='coupon-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Kuponlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0">
                                    {coupons.map((coupon) =>
                                    (
                                        <Box key={coupon.id + "coupons"}
                                            m="10px" mb="20px" p="20px" minWidth="250px"
                                            sx={{
                                                background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500],
                                                borderRadius: "20px"
                                            }}
                                        >
                                            <Box display="flex" alignItems="center" mb="15px">
                                                <Box display="flex" alignItems="center">
                                                    <img
                                                        alt="brand-logo"
                                                        src={brand.logo_link}
                                                        width="40px"
                                                        height="40px"
                                                        style={{ cursor: "pointer", borderRadius: "50%" }} />
                                                    <Typography variant='h4' ml="10px">{coupon.title}</Typography>
                                                </Box>
                                                <Box padding="10px" ml="20px" mr="30px" sx={{
                                                    background: colors.blueAccent[500], border: "1px solid " + colors.grey[500],
                                                    borderRadius: "10px",
                                                    color: "white"
                                                }}>
                                                    {coupon.coupon_count} adet
                                                </Box>
                                            </Box>
                                            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
                                            <Typography variant='h6' mt="15px" >{coupon.description}</Typography>
                                        </Box>
                                    )
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disabled={typeof comments === "string" || comments.length < 1}>
                            <AccordionSummary id='comment-accordion' expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h4'>Yorumlar</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box m="10px 0 0 0">
                                    {typeof comments !== "string" && comments.map((comment) =>
                                    (
                                        <Box key={comment.id + "comments"} >
                                            <CommentBox comment={comment} isEditable={true} approveComment={approveComment} deleteComment={deleteComment} />
                                        </Box>
                                    )
                                    )}

                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default BrandDetails;