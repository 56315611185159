import React, { useContext, useState } from 'react'
import { Alert, Autocomplete, Box, Button, Chip, Dialog, DialogContent, TextField, Typography, useTheme } from '@mui/material';
import { Formik } from "formik";
import * as yup from "yup";
import YupPassword from 'yup-password';
import axios from 'axios';
import { tokens } from '../../../../theme';
import { UserContext } from '../../../../context/UserContext';
YupPassword(yup);

function RegisterDialog({ open, handleClose, type, influencer, isAddingFromAdminPanel }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.colors);
  const { token } = useContext(UserContext);
  const [alertMessage, setAlertMessage] = useState(null);

  var initialValues = {}

  if (type === "new") {

    initialValues = {
      name: "",
      surname: "",
      email: "",
      password: "",
      passwordCheck: "",
      links: [],
      refCode: "",
    }

    if (isAddingFromAdminPanel) {
      initialValues.commission = 0;
    }
  }

  if (type === "edit" || type === "approve") {
    initialValues = {
      name: influencer.name,
      surname: influencer.surname,
      email: influencer.email,
      commission: influencer.commission_percentage ? influencer.commission_percentage : 0,
      links: influencer.links
    }
  }

  var validationObject = {}

  if (type === "new") {
    validationObject = {
      name: yup.string().required("zorunlu"),
      surname: yup.string().required("zorunlu"),
      email: yup.string().email("Geçersiz eposta adresi").required("zorunlu"),
      links: yup.array().of(
        yup.string().url("Geçersiz url! (örn: https://sosyalmedya.com/profiliniz")
      ).min(1, "En az 1 profil linki girilmelidir. Kelimeyi girdikten sonra enter tuşuna basarak onaylayabilirsiniz.").required("zorunlu"),
      password: yup.string()
        .required('zorunlu')
        .min(8, 'Şifre çok kısa - en az 8 karakter olmalı')
        .minLowercase(1, 'Şifreniz en az 1 küçük harf içermelidir')
        .minUppercase(1, 'Şifreniz en az 1 büyük harf içermelidir')
        .minNumbers(1, 'Şifreniz en az 1 rakam içermelidir')
        .minSymbols(1, 'Şifreniz en az 1 özel karakter içermelidir'),
      passwordCheck: yup.string()
        .oneOf([yup.ref('password'), null], 'Şifreler uyuşmuyor'),
      refCode: yup.string()
    }
    if (isAddingFromAdminPanel) {
      validationObject.commission = yup.number().min(0, "En az 0 olabilir").max(100, "En fazla 100 olabilir").required("zorunlu")
    }
  }

  if (type === "edit" || type === "approve") {
    validationObject = {
      name: yup.string().required("zorunlu"),
      surname: yup.string().required("zorunlu"),
      email: yup.string().email("Geçersiz eposta adresi").required("zorunlu"),
      commission: yup.number().min(0, "En az 0 olabilir").max(100, "En fazla 100 olabilir").required("zorunlu"),
      links: yup.array().of(
        yup.string().url("Geçersiz url! (örn: https://sosyalmedya.com/profiliniz ")
      ).min(1, "En az 1 profil linki girilmelidir. Kelimeyi girdikten sonra enter tuşuna basarak onaylayabilirsiniz.").required("zorunlu"),
    }
  }
  const influencerSchema = yup.object().shape(validationObject);


  const handleFormSubmit = async (values) => {
    if (type === "new") {
      const data = {
        influencer: {
          name: values.name,
          surname: values.surname,
          email: values.email,
          password: values.password
        },
        links: values.links
      };

      if (isAddingFromAdminPanel) {
        data.influencer.is_approved = 1;
        data.influencer.commission_percentage = values.commission;
      } else {
        data.influencer.commission_percentage = 0;
      }

      var canContinue = true;
      if (values.refCode && values.refCode !== "") {
        await axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencerPartner/checkRefCodeExists?refCode=${values.refCode}`, data)
          .catch((err) => {
            console.log("err: " + err);
            setAlertMessage("Referans kodu hatalı.");
            setTimeout(() => {
              setAlertMessage(null)
            }, 3000);
            canContinue = false;
          })
          .then((response) => {
            if (response && response.data.success === true && response.data.status === 200 && response.data.isExists) {
              data.influencer.influencer_partner_ref_id = response.data.refCodeId;
            }
          });
      }

      if (canContinue) {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/influencer/checkEmailIsValid`, {
          email: data.influencer.email
        })
          .catch((err) => {
            console.log("err: " + err);
          })
          .then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
              if (response.data.isValid) {
                axios.post(`${process.env.REACT_APP_API_URL}api/alcago/influencer/`, data)
                  .catch((err) => {
                    console.log("err: " + err);
                    setAlertMessage("Bir hata oluştu");
                    setTimeout(() => {
                      setAlertMessage(null)
                    }, 3000);
                  })
                  .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                      handleClose();
                    }
                  });
              } else {
                setAlertMessage('Eposta adresi ("' + values.email + '") zaten kullanılıyor.');
              }
            }
          });
      }
    } else if (type === "edit" || type === "approve") {
      const data = {
        influencer: {
          name: values.name,
          surname: values.surname,
          email: values.email,
          commission_percentage: values.commission
        },
        links: values.links
      }
      if (type === "approve") {
        data.influencer.is_approved = 1
      }
      axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/influencer/updateInfluencer?token=${token}&influencerId=${influencer.id}`, data)
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            handleClose();
          }
        });
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box minWidth="500px" m="20px" mb="5px" textAlign="center">
        {type === "new" && !isAddingFromAdminPanel &&
          <Typography variant='h2'>Kayıt</Typography>
        }
        {type === "new" && isAddingFromAdminPanel &&
          <Typography variant='h2'>Yeni Influencer</Typography>
        }
        {type === "edit" &&
          <Typography variant='h2'>Influencer Hesabını Düzenle</Typography>
        }
        {type === "approve" &&
          <Typography variant='h2'>İnfluencer'ı Onayla</Typography>
        }

        {alertMessage && <Alert severity="error" sx={{ marginTop: "20px" }}>{alertMessage}</Alert>}

      </Box>

      <DialogContent>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={influencerSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="İsim"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Soyisim"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.surname}
                  name="surname"
                  error={!!touched.surname && !!errors.surname}
                  helperText={touched.surname && errors.surname}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Eposta"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />
                {(type === "approve" || type === "edit" || (type === "new" && isAddingFromAdminPanel)) &&
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Komisyon Yüzdesi"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      if (e.target.value >= 0 && e.target.value <= 100) {
                        handleChange(e);
                      }
                    }}
                    value={values.commission}
                    name="commission"
                    error={!!touched.commission && !!errors.commission}
                    helperText={touched.commission && errors.commission}
                    sx={{ gridColumn: "span 2" }}
                  />
                }
                {(type === "approve" || type === "edit") &&
                  <Box gridColumn="span 2"></Box>
                }
                {(type === "new" && !isAddingFromAdminPanel) &&
                  <br />
                }
                {type === "new" &&
                  <>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="password"
                      label="Şifre"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="password"
                      label="Şifre(Tekrar)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.passwordCheck}
                      name="passwordCheck"
                      error={!!touched.passwordCheck && !!errors.passwordCheck}
                      helperText={touched.passwordCheck && errors.passwordCheck}
                      sx={{ gridColumn: "span 2" }}
                    />
                  </>
                }
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  freeSolo
                  onChange={(e, value) => setFieldValue("links", value)}
                  value={values.links}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      multiline
                      variant="filled"
                      type="text"
                      label={<><span>Sosyal Medya Profil Linkleriniz</span><span style={{ fontSize: "10px" }}> (Girdikten sonra enter tuşuna basarak onaylayabilirsiniz.)</span></>}
                      error={!!touched.links && !!errors.links}
                      helperText={touched.links && errors.links}
                    />
                  )}
                  sx={{ gridColumn: "span 4" }}
                />
                {type === "new" &&
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Referans Kodu (İsteğe Bağlı)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.refCode}
                    name="refCode"
                    error={!!touched.refCode && !!errors.refCode}
                    helperText={touched.refCode && errors.refCode}
                    sx={{ gridColumn: "span 2" }}
                    inputProps={{ maxLength: 8 }}
                  />
                }
              </Box>
              <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                <Button type="submit" color="secondary" variant="contained">
                  {type === "new" && !isAddingFromAdminPanel &&
                    <Typography variant='h5'>Başvur</Typography>
                  }
                  {((type === "new" && isAddingFromAdminPanel) || type === "edit") &&
                    <Typography variant='h5'>Kaydet</Typography>
                  }
                  {type === "approve" &&
                    <Typography variant='h5'>Onayla</Typography>
                  }
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog >
  )
}

export default RegisterDialog;