import { Box, Button, FormControl, InputLabel, Select, MenuItem, TextField, Typography, useTheme, CircularProgress } from '@mui/material'
import React from 'react'
import { useContext, useState } from 'react';
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import axios from 'axios';
import { useEffect } from 'react';
import QuestionBox from '../../../../components/QuestionBox';
import QuestionDialog from './questionDialog';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import { Formik, useField, useFormikContext } from 'formik';


const DatePickerField = ({ ...props }) => {
  const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val);
        setFieldTouched(field.name);
      }}
      className={props.mode}
    />
  );
};


function Questions() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(UserContext);

  const getMaxD = () => {
    var maxD = new Date();
    maxD.setHours(0, 0, 0, 0);
    return maxD;
  }
  const getMinD = () => {
    var minD = new Date(new Date().setDate(new Date().getDate() - 15));
    minD.setHours(0, 0, 0, 0);
    return minD;
  }

  const [fetching, setFetching] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [filter, setFilter] = useState({ maxDate: getMaxD(), minDate: getMinD() });
  const [profileFields, setProfileFields] = useState(null);
  const [influencers, setInfluencers] = useState([]);
  const [influencerBrands, setInfluencerBrands] = useState([]);
  const [influencerRefCodes, setInfluencerRefCodes] = useState([]);
  const [influencerBrandRefCodes, setInfluencerBrandRefCodes] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
    fetchQuestions(filter);
  }

  const fetchQuestions = (values) => {
    setFetching(true);
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/ad/getGeneralQuestions?token=${token}`, { filter: values })
      .catch((err) => {
        console.log("err: " + err);
        setFetching(false);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setQuestions(response.data.questions);
          setFetching(false);
        }
      });
  }

  const getProfileFields = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/profileField/getProfileFields`)
      .catch((err) => {
        console.log("err: " + err);
        setProfileFields(null);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setProfileFields(response.data.profileFields);
        }
      });
  }

  const getInfluencers = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencer/getInfluencers?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        setInfluencers([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setInfluencers(response.data.influencers);
        }
      });
  }

  const getInfluencerBrands = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getAllBrands?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        setInfluencerBrands([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setInfluencerBrands(response.data.brands.filter(({ is_influencer }) => is_influencer === 1));
        }
      });
  }

  const getInfluencerRefCodes = (influencerId) => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencer/getInfluencerRefCodes?token=${token}&influencerId=${influencerId}`)
      .catch((err) => {
        console.log("err: " + err);
        setInfluencerRefCodes([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setInfluencerRefCodes(response.data.refCodes);
        }
      });
  }

  const getInfluencerBrandRefCodes = (brandId) => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandRefCodes?token=${token}&brandId=${brandId}`)
      .catch((err) => {
        console.log("err: " + err);
        setInfluencerBrandRefCodes([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setInfluencerBrandRefCodes(response.data.refCodes);
        }
      });
  }

  useEffect(() => {
    fetchQuestions({ maxDate: getMaxD(), minDate: getMinD() });
    getProfileFields();
    getInfluencers();
    getInfluencerBrands();
  }, []);



  const validationSchema = yup.object().shape({
    influencerId: yup.number().required("zorunlu"),
    influencerBrandId: yup.number().required("zorunlu"),
    influencerBrandRefId: yup.number().required("zorunlu"),
    refId: yup.number().required("zorunlu"),
    minAge: yup.number(),
    maxAge: yup.number(),
    gender: yup.string().required("zorunlu"),
    cityOfResidence: yup.number().required("zorunlu"),
    cityOfBirth: yup.number().required("zorunlu"),
    education: yup.number().required("zorunlu"),
    job: yup.number().required("zorunlu"),
    incomeStatus: yup.number().required("zorunlu"),
    haveCar: yup.number().required("zorunlu"),
    team: yup.number().required("zorunlu"),
    maritalStatus: yup.string().required("zorunlu"),
    findUsFrom: yup.number().required("zorunlu"),
    hobbyId: yup.number().required("zorunlu"),
    interestId: yup.number().required("zorunlu"),
    maxDate: yup.date()
      .required("zorunlu"),
    minDate: yup.date()
      .required("zorunlu"),
  });

  const initialValues = {
    influencerId: -1,
    refId: -1,
    influencerBrandId: -1,
    influencerBrandRefId: -1,
    minAge: 0,
    maxAge: 100,
    gender: "-1",
    cityOfResidence: -1,
    cityOfBirth: -1,
    education: -1,
    job: -1,
    incomeStatus: -1,
    haveCar: -1,
    team: -1,
    maritalStatus: "-1",
    findUsFrom: -1,
    hobbyId: -1,
    interestId: -1,
    maxDate: getMaxD(),
    minDate: getMinD()
  }

  const handleFormSubmit = (values) => {
    setFilter(values)
    fetchQuestions(values);
  }

  return (
    <Box m="20px 0 20px 20px" pb="20px">
      <Box>
        <Header title="Sorular" subtitle="Sorular sayfasına hoşgeldiniz" />
      </Box>
      <Box>
        <Box mb="20px" ml="10px">
          <Button sx={{ background: colors.primary[400], borderRadius: "10px", p: "10px" }} startIcon={<AddCircleOutlineOutlinedIcon sx={{ color: colors.greenAccent[400], width: "30px", height: "30px" }} />} onClick={() => { setOpenDialog(true) }}>
            <Typography variant='h6' color={colors.greenAccent[400]}>Yeni Soru Ekle</Typography>
          </Button>
        </Box>
        <Box mr="20px" mb="100px">
          {!profileFields &&
            <Box gridColumn="span 4" display="flex" justifyContent="center" alignItems="center" height="200px" >
              <CircularProgress color='success' variant="indeterminate" />
            </Box>
          }
          {profileFields &&
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="grid" gap="30px" gridTemplateColumns="repeat(10, minmax(0, 1fr))" mb="30px">
                    <Typography variant='h4' gridColumn="span 10" pt="25px">Kayıt Filtreleri</Typography>

                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Catcher Inviter</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Catcher Inviter"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (e.target.value === -1) {
                            setInfluencerRefCodes([]);
                            setFieldValue("refId", -1);
                          } else {
                            getInfluencerRefCodes(e.target.value);
                          }
                          setFieldValue("influencerId", e.target.value);
                          setFieldValue("refId", -1);
                        }}
                        name="influencerId"
                        value={values.influencerId}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {influencers.map((inf) => <MenuItem key={"inf" + inf.id} value={inf.id}>{inf.name + " " + inf.surname}</MenuItem>)}
                      </Select>
                    </FormControl>

                    {(values.influencerId && values.influencerId !== -1 && influencerRefCodes && influencerRefCodes.length > 0) &&
                      <FormControl fullWidth variant="filled"
                        sx={{ gridColumn: "span 2" }}>
                        <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Referans Kodu (Catcher Inviter)</InputLabel>
                        <Select
                          labelId="filter-label"
                          label="Referans Kodu (Catcher Inviter)"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("refId", e.target.value);
                          }}
                          name="refId"
                          value={values.refId}
                        >
                          <MenuItem value={-1}>Tümü</MenuItem>
                          {influencerRefCodes.map((infRef) => <MenuItem key={"infRef" + infRef.id} value={infRef.id}>{infRef.ref_code}</MenuItem>)}
                        </Select>
                      </FormControl>
                    }
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Catcher Inviter (Brand)</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Catcher Inviter (Brand)"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (e.target.value === -1) {
                            setInfluencerBrandRefCodes([]);
                            setFieldValue("influencerBrandRefId", -1);
                          } else {
                            getInfluencerBrandRefCodes(e.target.value);
                          }
                          setFieldValue("influencerBrandId", e.target.value);
                          setFieldValue("influencerBrandRefId", -1);
                        }}
                        name="influencerBrandId"
                        value={values.influencerBrandId}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {influencerBrands.map((infBrand) => <MenuItem key={"infBrand" + infBrand.id} value={infBrand.id}>{infBrand.name}</MenuItem>)}
                      </Select>
                    </FormControl>

                    {(values.influencerBrandId && values.influencerBrandId !== -1 && influencerBrandRefCodes && influencerBrandRefCodes.length > 0) &&
                      <FormControl fullWidth variant="filled"
                        sx={{ gridColumn: "span 2" }}>
                        <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Referans Kodu (Catcher Inviter (Brand))</InputLabel>
                        <Select
                          labelId="filter-label"
                          label="Referans Kodu (Catcher Inviter (Brand))"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("influencerBrandRefId", e.target.value);
                          }}
                          name="influencerBrandRefId"
                          value={values.influencerBrandRefId}
                        >
                          <MenuItem value={-1}>Tümü</MenuItem>
                          {influencerBrandRefCodes.map((infBrandRef) => <MenuItem key={"infBrandRef" + infBrandRef.id} value={infBrandRef.id}>{infBrandRef.ref_code}</MenuItem>)}
                        </Select>
                      </FormControl>
                    }

                    <Typography variant='h4' gridColumn="span 10" pt="25px">Kullanıcı Demografisi</Typography>

                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Minimum Yaş"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.minAge}
                      name="minAge"
                      error={!!touched.minAge && !!errors.minAge}
                      helperText={touched.minAge && errors.minAge}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      label="Maximum Yaş"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.maxAge}
                      name="maxAge"
                      error={!!touched.maxAge && !!errors.maxAge}
                      helperText={touched.maxAge && errors.maxAge}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Cinsiyet</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Cinsiyet"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="gender"
                        value={values.gender}
                      >
                        <MenuItem value={"-1"}>Tümü</MenuItem>
                        <MenuItem value={"Kadın"}>Kadın</MenuItem>
                        <MenuItem value={"Erkek"}>Erkek</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Yaşadığı Şehir</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Yaşadığı Şehir"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="cityOfResidence"
                        value={values.cityOfResidence}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.cities.map((city) => <MenuItem key={"cityr" + city.id} value={city.id}>{city.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Doğduğu Şehir</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Doğduğu Şehir"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="cityOfBirth"
                        value={values.cityOfBirth}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.cities.map((city) => <MenuItem key={"cityb" + city.id} value={city.id}>{city.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Öğrenim Durumu</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Öğrenim Durumu"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="education"
                        value={values.education}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.education_statuses.map((educationStatus) => <MenuItem key={"education" + educationStatus.id} value={educationStatus.id}>{educationStatus.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Meslek</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Meslek"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="job"
                        value={values.job}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.jobs.map((job) => <MenuItem key={"job" + job.id} value={job.id}>{job.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Gelir Durumu</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Gelir Durumu"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="incomeStatus"
                        value={values.incomeStatus}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.income_statuses.map((income_status) => <MenuItem key={"income_status" + income_status.id} value={income_status.id}>{income_status.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Araba</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Araba"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="haveCar"
                        value={values.haveCar}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        <MenuItem value={1}>Var</MenuItem>
                        <MenuItem value={0}>Yok</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Tuttuğu Takım</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Tuttuğu Takım"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="team"
                        value={values.team}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.teams.map((team) => <MenuItem key={"team" + team.id} value={team.id}>{team.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Medeni Hal</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Medeni Hal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="maritalStatus"
                        value={values.maritalStatus}
                      >
                        <MenuItem value={"-1"}>Tümü</MenuItem>
                        <MenuItem value={"Evli"}>Evli</MenuItem>
                        <MenuItem value={"Bekar"}>Bekar</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Bizi Nereden Buldun?</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Bizi Nereden Buldun?"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="findUsFrom"
                        value={values.findUsFrom}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.findable_areas.map((findable_area) => <MenuItem key={"findable_area" + findable_area.id} value={findable_area.id}>{findable_area.value}</MenuItem>)}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Hobi</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="Hobi"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="hobbyId"
                        value={values.hobbyId}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.hobbies.map((hobby) => <MenuItem key={"hobby" + hobby.id} value={hobby.id}>{hobby.value}</MenuItem>)}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth variant="filled"
                      sx={{ gridColumn: "span 2" }}>
                      <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>İlgi Alanı</InputLabel>
                      <Select
                        labelId="filter-label"
                        label="İlgi Alanı"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="interestId"
                        value={values.interestId}
                      >
                        <MenuItem value={-1}>Tümü</MenuItem>
                        {profileFields.interests.map((interest) => <MenuItem key={"interest" + interest.id} value={interest.id}>{interest.value}</MenuItem>)}
                      </Select>
                    </FormControl>

                    <Typography variant='h4' gridColumn="span 10" pt="25px">Tarih</Typography>
                    <Box gridColumn="span 2">
                      <InputLabel size='normal' error={!!touched.minDate && !!errors.minDate} htmlFor="minDate">Başlangıç Tarihi:</InputLabel>
                      <DatePickerField name="minDate" mode={theme.palette.mode} />
                    </Box>

                    <Box gridColumn="span 2">
                      <InputLabel size='normal' error={!!touched.maxDate && !!errors.maxDate} htmlFor="maxDate">Bitiş Tarihi:</InputLabel>
                      <DatePickerField name="maxDate" mode={theme.palette.mode} />
                    </Box>
                  </Box>
                  <Box m="20px 20px 0px 0px">
                    <Button type="submit" color="secondary" variant="contained">
                      <Typography variant='h6'>Uygula</Typography>
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          }
        </Box>
        {fetching &&
          <Box gridColumn="span 4" display="flex" justifyContent="center" alignItems="center" height="1000px" >
            <CircularProgress color='success' variant="indeterminate" />
          </Box>
        }
        {!fetching &&
          <Box>
            {questions.map((q) => <QuestionBox key={q.id} question={q} fetchQuestions={() => fetchQuestions(filter)} />)}
          </Box>
        }
      </Box>
      <QuestionDialog open={openDialog} handleClose={handleDialogClose} type="new" question={null} />
    </Box >

  )
}

export default Questions