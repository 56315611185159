import { IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { tokens } from '../theme';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AlertDialogSlide from './AlertDialogSlide';
import { useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import AdminDialog from '../scenes/panels/adminPanel/admins/adminDialog';

function AdminBox({ admin, fetchAdmins }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);

    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertDialogClose = () => {
        setOpenAlertDialog(false);
    }
    const clickPositiveAnswer = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/admin/deleteAdmin?token=${token}&adminId=${admin.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data && response.data.success && response.data.status === 201) {
                    fetchAdmins();
                }
            });
    }
    const clickNegativeAnswer = () => { }

    const [openDialog, setOpenDialog] = useState(false);
    const handleDialogOpen = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/admin/getAdmin?token=${token}&adminId=${admin.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data && response.data.success && response.data.status === 200) {
                    setOpenDialog(true);
                }
            });
    }
    const handleDialogClose = () => {
        setOpenDialog(false);
        fetchAdmins()
    }

    return (
        <Box display="inline-block" mr="20px" mt="20px" sx={{
            background: colors.primary[400], borderRadius: "20px"
        }}>
            <AlertDialogSlide
                open={openAlertDialog} handleClose={handleAlertDialogClose}
                clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer}
                positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                title={"Bu yöneticiyi silmek istediğinize emin misiniz?"}
                description={"Bu yönetici ile ilişkili tüm veriler silinecektir."}
            />
            <AdminDialog open={openDialog} handleClose={handleDialogClose} type="edit" admin={admin} />
            <Box display="flex">
                <Box p="20px">
                    <Box display="flex" alignItems="center" gap="10px">
                        <img
                            alt="profile-user"
                            src="../../assets/default-user.png"
                            width="55px"
                            height="55px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />
                        <Box>

                            <Box display="flex" gap="10px" alignItems="center">
                                <Tooltip title={admin.name + " " + admin.surname}>
                                    <Typography variant='h3' maxWidth="160px" noWrap>{admin.name + " " + admin.surname}</Typography>
                                </Tooltip>
                            </Box>
                            <Box display="flex" gap="10px" alignItems="center" mt="5px">
                                <Box padding="1px 10px" sx={{ background: colors.grey[700], borderRadius: "10px" }}>
                                    <Typography variant='h6'>{new Date(admin.sign_up_date).toLocaleDateString()}</Typography>
                                </Box>

                                <Box padding="1px 10px" sx={{ background: colors.greenAccent[600], borderRadius: "10px" }}>
                                    <Typography variant='h6'>{admin.email}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box height="1px" width="%100" mr="20px" my="15px" sx={{ background: "gold" }}></Box>
                    <Box alignItems="center" mt="10px">
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.dashboard_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Anasayfa"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.statistics_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Raporlar"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.brands_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Markalar"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.ads_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Reklamlar"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.ad_groups_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Reklam Grupları"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.sign_up_settings_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Kayıt Formu Ayarları"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.shortcuts_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Kısayollar"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.users_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Kullanıcılar"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.orders_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Siparişler"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.contact_messages_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"İletişim Mesajları"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.influencers_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Influencerlar"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.partners_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Partnerler"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.admins_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Yöneticiler"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.announcements_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Duyurular"}</Typography>
                        </Box>
                        <Box display="inline-block" m="5px" padding="1px 10px" sx={{ background: admin.payments_perm === 1 ? colors.greenAccent[600] : colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"Ödemeler"}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" sx={{
                    background: theme.palette.mode === "dark" ? colors.primary[600] : colors.grey[900], borderRadius: "0 20px 20px 0"
                }}>
                    <Box px="5px" pt="10px">
                        <IconButton onClick={() => { handleDialogOpen() }}>
                            <EditOutlinedIcon sx={{ height: "30px", width: "30px" }} />
                        </IconButton>
                        <br />
                        <IconButton onClick={() => { setOpenAlertDialog(true) }}>
                            <DeleteOutlinedIcon color='error' sx={{ height: "30px", width: "30px" }} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AdminBox