import { useTheme } from '@emotion/react';
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { UserContext } from '../../../../../../context/UserContext';
import { tokens } from '../../../../../../theme';
import * as yup from "yup";
import axios from 'axios';

function EditUserDialog({ open, handleClose, user }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.colors);
    const { token } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);

    const initialValues = {
        name: user.name,
        surname: user.surname,
        profilePhoto: null,
        profilePhotoLink: user.profile_photo_link
    }

    const userSchema = yup.object().shape({
        name: yup.string().required("zorunlu"),
        surname: yup.string().required("zorunlu"),
        profilePhotoLink: yup.string(),
        profilePhoto: yup.mixed().when('profilePhotoLink', {
            is: (profilePhotoLink) => !profilePhotoLink || profilePhotoLink === "",
            then: yup.string()
                .required('zorunlu')
        })
    });


    const handleFormSubmit = async (values) => {
        setIsLoading(true);
        var data = {
            name: values.name,
            surname: values.surname
        }
        if (values.profilePhoto) {
            await axios.post(`${process.env.REACT_APP_API_URL}api/alcago/user/uploadProfilePhoto?token=${token}`, { profilePhoto: values.profilePhoto }, {
                headers: {
                    "content-type": "multipart/form-data"
                },
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    data.profile_photo_path = response.data.photoPath;
                }
            });
        }
        await axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/user/updateProfile?token=${token}&userId=${user.id}`, { profile: data })
            .catch((err) => {

            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {

                }
            });

        setIsLoading(false);
        handleClose();
    }


    return (
        <Dialog open={open} onClose={handleClose}>
            <Box m="20px" mb="5px" textAlign="center">
                <Typography variant='h2'>Kullanıcıyı Düzenle</Typography>
            </Box>

            <DialogContent>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={userSchema}
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                                <Box gridColumn="span 1"></Box>
                                <Button component="label" style={{ gridColumn: "span 2", textAlign: "center" }}>
                                    <img alt='square brand logo' height="100px" width="100px" src={values.profilePhoto ? URL.createObjectURL(values.profilePhoto) : values.profilePhotoLink} />
                                    <input id="profilePhoto" name="profilePhoto" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("profilePhoto", event.target.files[0]); }} hidden />
                                </Button>
                                <Box gridColumn="span 1"></Box>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="İsim"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    name="name"
                                    error={!!touched.name && !!errors.name}
                                    helperText={touched.name && errors.name}
                                    sx={{ gridColumn: "span 2" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Soyisim"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.surname}
                                    name="surname"
                                    error={!!touched.surname && !!errors.surname}
                                    helperText={touched.surname && errors.surname}
                                    sx={{ gridColumn: "span 2" }}
                                />
                            </Box>
                            <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                <Button type="submit" color="secondary" variant="contained">
                                    Kaydet
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog >
    )
}

export default EditUserDialog