import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useContext, useState, useEffect } from 'react'
import Header from '../../../../components/Header'
import StatCard from '../../../../components/StatCard'
import { UserContext } from '../../../../context/UserContext'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import { tokens } from '../../../../theme'
import axios from 'axios'
import CommentBox from '../../../../components/CommentBox'
import NumberFormatter from '../../../global/NumberFormatter';


function Dashboard() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, token } = useContext(UserContext);
  const [comments, setComments] = useState([]);
  const [unconfirmedCouponExists, setUnconfirmedCouponExists] = useState(false);
  const [usedCouponCount, setUsedCouponCount] = useState(0);
  const [followerStatistics, setFollowerStatistics] = useState({
    today: " ",
    thisMonth: " ",
    thisYear: " ",
    total: " "
  });
  const [otherStatistics, setOtherStatistics] = useState({
    view_count: " ",
    ad_count: " ",
    received_coupons_count: " ",
    total_watch_count: " "
  });
  const [earningStatistics, setEarningStatistics] = useState({
    today: 0,
    thisMonth: 0,
    thisYear: 0,
    total: 0
  });

  const getEarningStatistics = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getDashboardEarningStatisticsWithField?token=${token}&id=${user.id}&field=brand`)
      .catch((err) => {
        console.log("err: " + err);
        setEarningStatistics({
          today: 0,
          thisMonth: 0,
          thisYear: 0,
          total: 0
        });
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setEarningStatistics(response.data.statistics);
        }
      });
  }

  const fetchUsedCouponCount = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/coupon/getGivenCouponsStatistics?token=${token}`, { filter: { status: 1, brandId: user.id } })
      .catch((err) => {
        console.log("err: " + err);
        setUsedCouponCount(0);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          let c = 0;
          response.data.coupons.forEach(({ coupon_count }) => c += coupon_count);
          setUsedCouponCount(c);
        }
      });
  }

  const fetchComments = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getComments?token=${token}&brandId=${user.id}&startIndex=0&entryCount=4`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setComments(response.data.comments)
        }
      });
  }

  const fetchFollowerStatistics = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getFollowerStatistics?token=${token}&brandId=${user.id}`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setFollowerStatistics(response.data.followerStatistics);
        }
      });
  }

  const fetchOtherStatistics = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getOtherStatistics?token=${token}&brandId=${user.id}`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setOtherStatistics(response.data.statistics);
        }
      });
  }

  const checkUnApprovedCouponsExistance = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/checkUnApprovedCouponsExistance?token=${token}&brandId=${user.id}`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response) {
          if (response.data.success === true && response.data.status === 200) {
            setUnconfirmedCouponExists(true);
          }
        }
      });
  }


  useEffect(() => {
    fetchComments();
    fetchFollowerStatistics();
    fetchOtherStatistics();
    checkUnApprovedCouponsExistance();
    fetchUsedCouponCount();
    getEarningStatistics();
  }, []);

  return (
    <Box mt="20px">
      <Box ml="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Panel" subtitle="Panele hoşgeldiniz" />
        </Box>
      </Box>
      <Box display="grid" gap="20px" gridTemplateColumns="repeat(6, minmax(0, 1fr))">
        {unconfirmedCouponExists &&
          <Box mb="20px">
            <Box width="100%" m="0 30px" p="20px" sx={{ background: colors.primary[300], borderRadius: "20px" }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box textAlign="start" >
                  <Typography variant='h5' fontStyle="italic" sx={{ color: theme.palette.mode === "dark" ? colors.grey[100] : colors.grey[900] }}>
                    Onaylanmamış kuponlarınız bulunuyor!
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        }
        <Typography variant='h4' ml="25px" sx={{ gridColumn: "span 6" }}>Kazanç İstatistikleri</Typography>
        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(earningStatistics.today.toFixed(2)) + " ₺"} content="Bugün" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(earningStatistics.thisMonth.toFixed(2)) + " ₺"} content="Bu ay" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(earningStatistics.thisYear.toFixed(2)) + " ₺"} content="Bu yıl" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(earningStatistics.total.toFixed(2)) + " ₺"} content="Toplam" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<CardMembershipOutlinedIcon sx={{ scale: "1.5" }} />} count={NumberFormatter.formatNumber(usedCouponCount) + ""} content="Kullanılan Kupon" sx={{ gridColumn: "span 1" }} />
        <Typography variant='h4' ml="25px" mt="20px" sx={{ gridColumn: "span 6" }}>Takipçi İstatistikleri</Typography>
        <StatCard icon={<FavoriteOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(followerStatistics.today)} content="Bugün" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<FavoriteOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(followerStatistics.thisMonth)} content="Bu ay" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<FavoriteOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(followerStatistics.thisYear)} content="Bu yıl" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<FavoriteOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(followerStatistics.total)} content="Toplam" sx={{ gridColumn: "span 1" }} />
        <Typography variant='h4' ml="25px" mt="20px" sx={{ gridColumn: "span 6" }}>Diğer</Typography>
        <StatCard icon={<FeaturedVideoOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(otherStatistics.ad_count)} content="Reklam" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<RemoveRedEyeOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(otherStatistics.view_count)} content="Profil Tıklaması" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<SlideshowOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(otherStatistics.total_watch_count)} content="Reklam izlemesi" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<RedeemOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(otherStatistics.received_coupons_count)} content="Dağıtılan kupon" sx={{ gridColumn: "span 1" }} />
        {comments.length > 0 && <>
          <Typography variant='h4' ml="25px" mt="20px" sx={{ gridColumn: "span 6" }}>Son Yorumlar</Typography>
          <Box ml="35px" sx={{ gridColumn: "span 6", paddingRight: "30px" }}>
            {comments.map((comment, i) => {
              if (i < 3) {
                return (
                  <CommentBox key={comment.id} comment={comment} />
                )
              } else {
                return "";
              }
            })}
          </Box>
        </>}
      </Box>
    </Box>
  )
}

export default Dashboard;