import React from 'react'
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from '../theme';

function StatCard({ icon, count, content, bgColor, contentVariant = 'h5' }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px" p="20px" sx={{ background: bgColor ? bgColor : colors.primary[400], borderRadius: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          {icon}
        </Box>
        <Box textAlign="right" >
          <Typography variant='h4' fontWeight="bold" sx={{ color: colors.grey[100] }}>
            {count}
          </Typography>
          <Typography ml="10px" variant={contentVariant} fontStyle="italic" sx={{ color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200]) }}>
            {content}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default StatCard;