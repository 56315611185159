import { Button, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { tokens } from '../theme';

function BrandBox({ brand, onDetailsButtonClick }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box display="inline-block" mr="10px" mb="20px" p="20px" sx={{ background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb="15px">
                <Box display="flex" alignItems="center">
                    <img
                        alt="profile-user"
                        src={brand.logo_link}
                        width="40px"
                        height="40px"
                        style={{ cursor: "pointer", borderRadius: "50%" }} />
                    <Typography variant='h4' ml="10px" mr="20px">{brand.name.length > 15 ? (brand.name.slice(0, 12) + "...") : brand.name}</Typography>
                </Box>
                <Button type="submit" color="secondary" variant="contained" onClick={() => onDetailsButtonClick(brand)} >
                    <Typography variant='h6'>Onayla</Typography>
                </Button>
            </Box>
            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Typography variant='h6' mt="5px" maxWidth="260px">{brand.description.length >= 200 ? (brand.description.slice(0, 197) + "...") : brand.description}</Typography>
        </Box>
    )
}

export default BrandBox