import { Button, Checkbox, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { tokens } from '../theme';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';
import AlertDialogSlide from './AlertDialogSlide';
import { useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import RegisterDialog from '../scenes/loginPages/partnerLogin/registerDialog';

function DetailedPartnerBox({ partner, fetchPartners, brandsButtonClick }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);

    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertDialogClose = () => {
        setOpenAlertDialog(false);
    }
    const clickPositiveAnswer = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/partner/deletePartner?token=${token}&partnerId=${partner.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data && response.data.success && response.data.status === 201) {
                    fetchPartners();
                }
            });
    }
    const clickNegativeAnswer = () => { }

    const [openDialog, setOpenDialog] = useState(false);
    const handleDialogOpen = () => {
        setOpenDialog(true);
    }
    const handleDialogClose = () => {
        setOpenDialog(false);
        fetchPartners()
    }

    const [active, setActive] = useState(partner ? partner.is_approved > 0 : false);
    const handleActive = () => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/partner/updatePartner?token=${token}&partnerId=${partner.id}`, {
            partner: {
                is_approved: !active
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setActive(!active);
                }
            });
    }

    return (
        <Box display="inline-block" mr="20px" mt="20px" sx={{
            background: colors.primary[400], borderRadius: "20px"
        }}>
            <AlertDialogSlide
                open={openAlertDialog} handleClose={handleAlertDialogClose}
                clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer}
                positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                title={"Bu partner'i silmek istediğinize emin misiniz?"}
                description={"Bu partner ile ilişkili tüm veriler silinecektir. (markalar, referans kodları, bakiye aktarımları vb.)"}
            />
            <RegisterDialog open={openDialog} handleClose={handleDialogClose} type="edit" partner={partner} />
            <Box display="flex">
                <Box p="20px">
                    <Box display="flex" alignItems="center" gap="10px">
                        <img
                            alt="profile-user"
                            src={partner.profile_photo_link}
                            width="55px"
                            height="55px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />
                        <Box>

                            <Box display="flex" gap="10px" alignItems="center">
                                <Tooltip title={partner.name + " " + partner.surname}>
                                    <Typography variant='h3' maxWidth="160px" noWrap>{partner.name + " " + partner.surname}</Typography>
                                </Tooltip>
                                <Box display="flex" alignItems="center" justifyContent="center" padding="1px 10px" sx={{ background: colors.greenAccent[600], borderRadius: "12px" }}>
                                    <Checkbox
                                        checked={active}
                                        size="small"
                                        sx={{
                                            padding: "5px 2px 5px 0",
                                            '&.Mui-checked': {
                                                color: (colors.greenAccent[200])
                                            }
                                        }}
                                        onClick={(event) => {
                                            handleActive();
                                        }}
                                    />
                                    <Typography variant='h6'>Onaylı</Typography>
                                </Box>
                            </Box>
                            <Box display="flex" gap="10px" alignItems="center" mt="5px">
                                <Box padding="1px 10px" sx={{ background: colors.grey[700], borderRadius: "10px" }}>
                                    <Typography variant='h6'>{new Date(partner.sign_up_date).toLocaleDateString()}</Typography>
                                </Box>

                                <Box padding="1px 10px" sx={{ background: colors.greenAccent[600], borderRadius: "10px" }}>
                                    <Typography variant='h6'>{partner.email}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box height="1px" width="%100" mr="20px" my="15px" sx={{ background: "gold" }}></Box>
                    <Box display="flex" gap="10px" alignItems="center" mt="10px">
                        <Box padding="1px 10px" sx={{ background: colors.redAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{partner.brand_count + " marka"}</Typography>
                        </Box>
                        <Box padding="1px 10px" sx={{ background: colors.blueAccent[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{"%" + partner.commission_percentage + " komisyon"}</Typography>
                        </Box>
                        <Box padding="1px 10px" sx={{ background: colors.grey[700], borderRadius: "10px" }}>
                            <Typography variant='h6'>{partner.total_amount.toFixed(2) + "₺ bakiye"}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" sx={{
                    background: theme.palette.mode === "dark" ? colors.primary[600] : colors.grey[900], borderRadius: "0 20px 20px 0"
                }}>
                    <Box px="5px">
                        <IconButton onClick={() => { handleDialogOpen() }}>
                            <EditOutlinedIcon sx={{ height: "30px", width: "30px" }} />
                        </IconButton>
                        <br />
                        <IconButton onClick={() => { setOpenAlertDialog(true) }}>
                            <DeleteOutlinedIcon color='error' sx={{ height: "30px", width: "30px" }} />
                        </IconButton>
                        <br />
                        <IconButton onClick={() => { brandsButtonClick(partner) }}>
                            <AlbumOutlinedIcon sx={{ height: "30px", width: "30px" }} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default DetailedPartnerBox