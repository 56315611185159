import { useTheme } from '@emotion/react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, Menu, MenuItem, TextField, Typography } from '@mui/material'
import React from 'react'
import { useContext } from 'react';
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useState, useEffect } from 'react';
import axios from 'axios';
import SelectAdDialog from './selectAdDialog';
import AlertDialogSlide from '../../../../components/AlertDialogSlide';
import AddNewAdGroupDialog from './addNewAdGroupDialog';
import AdDialog from '../ads/adDialog';

const AdGroupRow = ({ adGroup, fetchAdGroups, handleOpenEditDialog }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ads, setAds] = useState([]);
  const [featured, setFeatured] = useState(adGroup.is_featured > 0);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [clickedAdId, setClickedAdId] = useState(-1);
  const [dialogType, setDialogType] = useState("change");

  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const handleDialogClose = () => {
    fetchAds();
    setOpenDialog(false);
  }
  const handleEditDialogClose = (changed) => {
    if (changed) {
      //todo api call
    }

    setOpenEditDialog(false);
  }

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const handleAlertDialogClose = () => {
    setOpenAlertDialog(false);
  }
  const clickPositiveAnswer = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/adgroup/delete?token=${token}&adGroupId=${adGroup.id}`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 201) {
          fetchAdGroups();
        }
      });
  }
  const clickNegativeAnswer = () => { }



  const [openAdDeleteAlertDialog, setOpenAdDeleteAlertDialog] = useState(false);
  const [adToDelete, setAdToDelete] = useState(null);
  const handleAdDeleteAlertDialogClose = () => {
    setAdToDelete(null);
    setOpenAdDeleteAlertDialog(false);
  }
  const handleOpenAdDeleteAlertDialog = (ad) => {
    setAdToDelete(ad);
    setOpenAdDeleteAlertDialog(true);
  }
  const clickAdDeletePositiveAnswer = () => {
    if (adToDelete) {
      axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/adgroup/removeAdFromAdGroup?token=${token}&adGroupId=${adGroup.id}&adId=${adToDelete.id}&position=${adToDelete.position}&ap=${85 / 100 * adToDelete.ap_count * -1}`)
        .catch((err) => {
          console.log("err: " + err);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            fetchAds();
          }
        });
    }

  }
  const clickAdDeleteNegativeAnswer = () => { }




  const openSelectAdDialog = (id, type) => {
    setDialogType(type);
    if (type === "change") {
      setClickedAdId(id);
    }
    setOpenDialog(true);
  }

  const fetchAds = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/adgroup/getAdGroupAds?token=${token}&adGroupId=${adGroup.id}`)
      .catch((err) => {
        console.log("err: " + err);
        if (err.response.status === 404) {
          setAds([]);
        }
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setAds(response.data.ads);
        }
      });
  }

  useEffect(() => {
    fetchAds();
  }, []);

  const handleFeatured = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/adgroup/toggleIsFeatured?token=${token}&adGroupId=${adGroup.id}`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {

        }
      });
  }

  return (
    <Box mr="20px" mt="20px" display="flex">
      <AlertDialogSlide open={openAlertDialog} handleClose={handleAlertDialogClose} clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer} positiveAnswer={"Sil"} negativeAnswer={"İptal"} title={"Bu reklam grubunu silmek istediğinize emin misiniz?"} description={"Bu reklam grubu ile ilişkili tüm veriler silinecektir. (kullanıcı kuponları, izleme istatistikleri, vb.)"} />
      <AlertDialogSlide open={openAdDeleteAlertDialog} handleClose={handleAdDeleteAlertDialogClose} clickPositiveAnswer={clickAdDeletePositiveAnswer} clickNegativeAnswer={clickAdDeleteNegativeAnswer} positiveAnswer={"Çıkar"} negativeAnswer={"İptal"} title={"Bu reklamı reklam grubundan çıkarmak istediğinize emin misiniz?"} description={"Bu reklam ilişkili yarıda kalanlar da silinecektir."} />
      <SelectAdDialog open={openDialog} handleClose={handleDialogClose} adGroup={adGroup} adGroupAds={ads} currentAdId={clickedAdId} type={dialogType} />
      <Dialog open={openEditDialog} onClose={handleEditDialogClose}>
        <DialogTitle sx={{ minWidth: "500px" }}>Açıklamayı Değiştir</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {adGroup.description}
          </DialogContentText>
          <TextField
            autoFocus
            multiline
            margin="dense"
            label="Bilgilendirme yazısı"
            type="text"
            minRows={3}
            fullWidth
            variant="filled"
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => handleEditDialogClose(false)}>İptal</Button>
          <Button color="success" onClick={() => handleEditDialogClose(true)}>Kaydet</Button>
        </DialogActions>
      </Dialog>
      <Box padding="20px" width="100%" display="grid" alignItems="center" gridTemplateColumns="repeat(29, minmax(0, 1fr))" sx={{ background: colors.grey[800], borderRadius: "10px" }}>
        <Box gridColumn="span 29" sx={{ marginBottom: "20px" }}>
          <Box
            display="flex"
            gap="10px">
            <img
              alt="brand-logo"
              src={adGroup.brand_logo_link}
              width="50px"
              height="50px"
              style={{ margin: "auto 0", cursor: "pointer", borderRadius: "50%" }} />
            <Box>
              <Box display="flex" alignItems="center">
                <Typography variant='h3'>{adGroup.brand_name}</Typography>
                <IconButton id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ marginLeft: "5px" }}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => {
                    handleClose();
                    openSelectAdDialog(null, "add");
                  }}>
                    <Typography color={colors.greenAccent[500]}>Yeni Reklam Ekle</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    handleClose();
                    setOpenEditDialog(true);
                  }}>
                    <Typography color={colors.grey[100]}>Açıklamayı Değiştir</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    handleClose();
                    setOpenAlertDialog(true);
                  }}>
                    <Typography color="error">Sil</Typography>
                  </MenuItem>
                </Menu>
              </Box>
              <Typography variant='h6'>{adGroup.coupon_title}</Typography>
            </Box>
            <Box>
              <Box>
                <Typography variant='h6' sx={{ background: colors.redAccent[700], borderRadius: "10px", display: "inline", padding: "5px 10px 5px 10px" }}>{adGroup.coupon_count} Kalan Kupon</Typography>
                <Typography variant='h6' sx={{ background: colors.greenAccent[700], borderRadius: "10px", display: "inline", padding: "5px 10px 5px 10px", ml: "10px" }}>{adGroup.ap_count} Gerekli AP</Typography>
              </Box>
              <Box mt="15px">
                <Box display="inline" alignItems="center" mt="5px">
                  <Checkbox
                    checked={featured}
                    size="medium"
                    sx={{
                      '&.Mui-checked': {
                        color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200])
                      }
                    }}
                    onClick={(event) => {
                      setFeatured(!featured);
                      handleFeatured();
                    }}
                  />
                  <Typography display="inline">Öne Çıkarılmış</Typography>
                </Box>
              </Box>
            </Box>
            <Box>
            </Box>
          </Box>
        </Box>

        <Box gridColumn="span 29">
          <Box height="200px" display="inline-block">
            <Box height="80%" width="1px" sx={{ background: "white" }} display="inline-block"></Box>
            <Box display="inline-block">
              <Box display="flex" justifyContent="end" padding="20px" >
                <Box>
                  <video width="220px" controls style={{ borderRadius: "20px", border: "1px solid gold" }}>
                    <source src={adGroup.ad_video_link} type="video/mp4" />
                  </video>
                </Box>
                <Button variant='contained' color='secondary' sx={{ position: "absolute" }} onClick={() => { handleOpenEditDialog(ads.find(({ position }) => position === 1).id) }}>
                  Düzenle
                </Button>
              </Box>
            </Box>

          </Box>

          {ads.map((ad) => {
            if (ad.position === 1) {
              return "";
            }
            return (
              <Box key={adGroup.id + "" + ad.id} height="200px" display="inline-block">
                <Box height="80%" width="1px" sx={{ background: "white" }} display="inline-block"></Box>
                <Box display="inline-block">
                  <Box display="flex" justifyContent="end" padding="20px" >
                    <Box>
                      <video width="220px" controls style={{ borderRadius: "20px" }}>
                        <source src={ad.video_link} type="video/mp4" />
                      </video>
                    </Box>

                    <Box sx={{ position: "absolute" }}>
                      <Button variant='contained' color='error' sx={{ mr: "5px" }} onClick={() => handleOpenAdDeleteAlertDialog(ad)}>
                        Çıkar
                      </Button>
                      <Button variant='contained' color='primary' onClick={() => openSelectAdDialog(ad.id, "change")}>
                        Değiştir
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>

      </Box>
    </Box>
  )
}

function AdGroups() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, token } = useContext(UserContext);

  const [searchKey, setSearchKey] = useState("");
  const [adGroups, setAdGroups] = useState([]);

  const fetchAdGroups = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/adgroup/getAllAdGroupsAndDetailsWithFilter?token=${token}`, { searchKey: searchKey })
      .catch((err) => {
        console.log("err: " + err);
        if (err.response.status === 404) {
          setAdGroups([]);
        }
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setAdGroups(response.data.adGroups);
        }
      });
  }

  useEffect(() => {
    fetchAdGroups()
  }, []);

  const [openAddNewAdGroupDialog, setOpenAddNewAdGroupDialog] = useState(false);

  const handleAddNewAdGroupDialogClose = () => {
    setOpenAddNewAdGroupDialog(false);
    setAdForNewAdGroup(null);
    fetchAdGroups();
  }

  const [openDialog, setOpenDialog] = useState(false);
  const [adForNewAdGroup, setAdForNewAdGroup] = useState(null);

  const handleDialogClose = () => {
    setOpenDialog(false);
  }

  useEffect(() => {
    if (adForNewAdGroup) {
      setOpenAddNewAdGroupDialog(true);
    }
  }, [adForNewAdGroup]);

  const [openAdEditDialog, setOpenAdEditDialog] = useState(false);
  const [selectedAdForEdit, setSelectedAdForEdit] = useState(null);
  const handleOpenEditDialog = async (id) => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAdDetails?token=${token}&id=${id}`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          for (var i = 0; i < response.data.ad.bubbles.length; ++i) {
            response.data.ad.bubbles[i].appaer_time = ((response.data.ad.bubbles[i].appaer_time / 1000 / 60) < 10 ? ("0" + parseInt(response.data.ad.bubbles[i].appaer_time / 1000 / 60)) : parseInt(response.data.ad.bubbles[i].appaer_time / 1000 / 60)) + ":" + (((response.data.ad.bubbles[i].appaer_time / 1000) % 60) < 10 ? ("0" + parseInt((response.data.ad.bubbles[i].appaer_time / 1000) % 60)) : parseInt((response.data.ad.bubbles[i].appaer_time / 1000) % 60));
            response.data.ad.bubbles[i].show_time = response.data.ad.bubbles[i].show_time / 1000;
          }
          setSelectedAdForEdit(response.data.ad);
          setOpenAdEditDialog(true);
        }
      });
  }
  const handleAdEditDialogClose = () => {
    setOpenAdEditDialog(false);
    setSelectedAdForEdit(null);
  }

  return (
    <Box mt="20px">
      {adForNewAdGroup && adForNewAdGroup.id && <AddNewAdGroupDialog firstAd={adForNewAdGroup} open={openAddNewAdGroupDialog} handleClose={handleAddNewAdGroupDialogClose} />}
      <SelectAdDialog open={openDialog} handleClose={handleDialogClose} type="newAdGroup" setAdForNewAdGroup={setAdForNewAdGroup} />
      {selectedAdForEdit && selectedAdForEdit.id && <AdDialog open={openAdEditDialog} handleClose={handleAdEditDialogClose} type="edit" selectedAd={selectedAdForEdit} />}
      <Box ml="20px" display="flex" gap="20px">
        <Header title="Reklam Grupları" subtitle="Reklam grupları sayfasına hoşgeldiniz" />
        <TextField
          id="outlined-basic"
          label="Marka Adı / Kategori"
          variant="outlined"
          onChange={(event) => {
            setSearchKey(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => fetchAdGroups()}>
                  <SearchOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }} />
        <Button variant='contained' color='success' sx={{ margin: "10px 0 auto 0" }} onClick={() => setOpenDialog(true)}>
          Yeni Reklam Grubu Ekle
        </Button>
      </Box>
      {adGroups.map((adGroup) =>
        <AdGroupRow key={adGroup.id} adGroup={adGroup} fetchAdGroups={fetchAdGroups} handleOpenEditDialog={handleOpenEditDialog} />
      )}
    </Box>
  )
}

export default AdGroups