import { Box, Button } from '@mui/material'
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import AdminBox from '../../../../components/AdminBox'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import AdminDialog from './adminDialog';

function Admins() {
  const { token, user } = useContext(UserContext);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    fetchAdmins()
  }, []);

  const fetchAdmins = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/admin/getAdmins?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        setAdmins([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setAdmins(response.data.admins);
        }
      });
  }

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
    fetchAdmins()
  }

  return (
    <Box m="20px">
      <AdminDialog open={openDialog} handleClose={handleDialogClose} type="new" />
      <Box display="flex" alignItems="center">
        <Header title="Yöneticiler" subtitle="Yöneticiler sayfasına hoş geldiniz" />
        <Button variant='contained' color='success' sx={{ margin: "auto 20px" }} onClick={() => { setOpenDialog(true) }}>
          Yeni Yönetici Ekle
        </Button>
      </Box>
      <Box>
        {admins.map(admin => {
          if (admin.id !== user.id) {
            return (
              <AdminBox key={admin.id} admin={admin} fetchAdmins={fetchAdmins} />
            )
          }
        })
        }
      </Box>
    </Box>
  )
}

export default Admins