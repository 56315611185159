import React, { useEffect, useContext, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  trTR,
} from "@mui/x-data-grid";
import axios from "axios";
import { UserContext } from "../../../../../context/UserContext";
import { tokens } from "../../../../../theme";
import Header from "../../../../../components/Header";
import * as yup from "yup";
import { Formik } from "formik";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function BrandsTable() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(UserContext);
  const [influencers, setInfluencers] = useState([]);
  const [influencerBrands, setInfluencerBrands] = useState([]);
  const [influencerRefCodes, setInfluencerRefCodes] = useState([]);
  const [influencerBrandRefCodes, setInfluencerBrandRefCodes] = useState([]);

  const columns = [
    {
      field: "logo_link",
      headerName: "Logo",
      type: "image",
      headerAlign: "center",
      align: "center",
      renderCell: ({ row: { logo_link } }) => {
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <img alt="logo" src={logo_link} height="50px" />
          </Box>
        );
      },
    },
    { field: "name", headerName: "Marka Adı" },
    {
      field: "ads_count",
      headerName: "Reklam",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "total_coupon_count",
      headerName: "Toplam Kupon",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "given_coupon_count",
      headerName: "Dağıtılan Kupon",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "used_coupon_count",
      headerName: "Kullanılan Kupon",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "unused_active_coupon_count",
      headerName: "Kullanılmayan Aktif Kupon",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "unused_unactive_coupon_count",
      headerName: "Kullanılmayan Geçersiz Kupon",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "active_ncs_count",
      headerName: "Yarıda Bırakılan",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "unactive_ncs_count",
      headerName: "Geçersiz Yarıda Bırakılan",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "view_count",
      headerName: "Profil Görüntülenmesi",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "comments_count",
      headerName: "Reklam Değerlendirmesi",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "avarage_point",
      headerName: "Puan Ortalaması",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "order_comments_count",
      headerName: "Sipariş Değerlendirmesi",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "order_avarage_point",
      headerName: "Sipariş Puan Ortalaması",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "favorites_count",
      headerName: "Favori",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "total_earning",
      headerName: "Toplam Kazanç",
      type: "number",
      headerAlign: "right",
    },
    {
      field: "is_active",
      headerName: "Aktif",
      type: "boolean",
      headerAlign: "center",
    },
    {
      field: "boost_coefficient",
      headerName: "Hedefleme Katsayısı",
      type: "number",
      headerAlign: "right",
      editable: true,
    },
    {
      field: "remaining_commission_free_order_count",
      headerName: "Kalan Komisyonsuz Sipariş Sayısı",
      type: "number",
      headerAlign: "right",
      editable: true,
    },
    {
      field: "created_date",
      headerName: "Oluşturulma Tarihi",
      type: "dateTime",
      headerAlign: "center",
      align: "center",
      renderCell: ({ row: { created_date } }) => {
        return new Date(created_date).toLocaleDateString();
      },
    },
  ];

  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    setLoading(true);
    getBrands({
      influencerId: -1,
      refId: -1,
      influencerBrandId: -1,
      influencerBrandRefId: -1,
    });
    getInfluencerBrands();
    getInfluencers();
  }, []);

  const getInfluencerBrands = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/alcago/brand/getAllBrands?token=${token}`
      )
      .catch((err) => {
        console.log("err: " + err);
        setInfluencerBrands([]);
      })
      .then((response) => {
        if (
          response &&
          response.data.success === true &&
          response.data.status === 200
        ) {
          setInfluencerBrands(
            response.data.brands.filter(
              ({ is_influencer }) => is_influencer === 1
            )
          );
        }
      });
  };

  const getInfluencers = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/alcago/influencer/getInfluencers?token=${token}`
      )
      .catch((err) => {
        console.log("err: " + err);
        setInfluencers([]);
      })
      .then((response) => {
        if (
          response &&
          response.data.success === true &&
          response.data.status === 200
        ) {
          setInfluencers(response.data.influencers);
        }
      });
  };

  const getInfluencerRefCodes = (influencerId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/alcago/influencer/getInfluencerRefCodes?token=${token}&influencerId=${influencerId}`
      )
      .catch((err) => {
        console.log("err: " + err);
        setInfluencerRefCodes([]);
      })
      .then((response) => {
        if (
          response &&
          response.data.success === true &&
          response.data.status === 200
        ) {
          setInfluencerRefCodes(response.data.refCodes);
        }
      });
  };

  const getInfluencerBrandRefCodes = (brandId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandRefCodes?token=${token}&brandId=${brandId}`
      )
      .catch((err) => {
        console.log("err: " + err);
        setInfluencerBrandRefCodes([]);
      })
      .then((response) => {
        if (
          response &&
          response.data.success === true &&
          response.data.status === 200
        ) {
          setInfluencerBrandRefCodes(response.data.refCodes);
        }
      });
  };

  const getBrands = (values) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandsWithStatistics?token=${token}` +
          `&influencerId=${values.influencerId}&refId=${values.refId}&influencerBrandId=${values.influencerBrandId}&influencerBrandRefId=${values.influencerBrandRefId}`
      )
      .catch((err) => {
        console.log("err: " + err);
        setBrands([]);
        setLoading(false);
      })
      .then((response) => {
        if (
          response &&
          response.data.success === true &&
          response.data.status === 200
        ) {
          setBrands(response.data.brands);
          setLoading(false);
        }
      });
  };

  const updateBrand = (brandId, coefficient) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}api/alcago/brand/updateBoostCoef?token=${token}&brandId=${brandId}&coef=${coefficient}`
      )
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (
          response &&
          response.data.success === true &&
          response.data.status === 201
        ) {
        }
      });
  };

  const updateComissionFreeCouponCount = (brandId, count) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}api/alcago/brand/updateBrand/?token=${token}&brandId=${brandId}`,
        {
          brand: {
            remaining_commission_free_order_count: count,
          },
        }
      )
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (
          response &&
          response.data.success === true &&
          response.data.status === 201
        ) {
        }
      });
  };

  const validationSchema = yup.object().shape({
    influencerId: yup.number().required("zorunlu"),
    refId: yup.number().required("zorunlu"),
    influencerBrandId: yup.number().required("zorunlu"),
    influencerBrandRefId: yup.number().required("zorunlu"),
  });

  const initialValues = {
    influencerId: -1,
    refId: -1,
    influencerBrandId: -1,
    influencerBrandRefId: -1,
  };

  const handleFormSubmit = (values) => {
    getBrands(values);
  };

  return (
    <Box m="20px">
      <Box display="flex">
        <Box>
          <Header
            title={"Marka Tablosu"}
            subtitle="Marka istatistiklerini buradan görüntüleyebilirsiniz."
          />
        </Box>
        <Box ml="30px">
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(5, minmax(0, 1fr))"
                  mb="30px"
                >
                  <FormControl
                    fullWidth
                    variant="filled"
                    sx={{ gridColumn: "span 1" }}
                  >
                    <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>
                      Catcher Inviter
                    </InputLabel>
                    <Select
                      labelId="filter-label"
                      label="Catcher Inviter"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        if (e.target.value === -1) {
                          setInfluencerRefCodes([]);
                          setFieldValue("refId", -1);
                        } else {
                          getInfluencerRefCodes(e.target.value);
                        }
                        setFieldValue("influencerId", e.target.value);
                        setFieldValue("refId", -1);
                      }}
                      name="influencerId"
                      value={values.influencerId}
                    >
                      <MenuItem value={-1}>Tümü</MenuItem>
                      {influencers.map((inf) => (
                        <MenuItem key={"inf" + inf.id} value={inf.id}>
                          {inf.name + " " + inf.surname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {values.influencerId &&
                    values.influencerId !== -1 &&
                    influencerRefCodes &&
                    influencerRefCodes.length > 0 && (
                      <FormControl
                        fullWidth
                        variant="filled"
                        sx={{ gridColumn: "span 1" }}
                      >
                        <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>
                          Referans Kodu (Catcher Inviter)
                        </InputLabel>
                        <Select
                          labelId="filter-label"
                          label="Referans Kodu (Catcher Inviter)"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("refId", e.target.value);
                          }}
                          name="refId"
                          value={values.refId}
                        >
                          <MenuItem value={-1}>Tümü</MenuItem>
                          {influencerRefCodes.map((infRef) => (
                            <MenuItem
                              key={"infRef" + infRef.id}
                              value={infRef.id}
                            >
                              {infRef.ref_code}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  <FormControl
                    fullWidth
                    variant="filled"
                    sx={{ gridColumn: "span 1" }}
                  >
                    <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>
                      Catcher Inviter (Brand)
                    </InputLabel>
                    <Select
                      labelId="filter-label"
                      label="Catcher Inviter (Brand)"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        if (e.target.value === -1) {
                          setInfluencerBrandRefCodes([]);
                          setFieldValue("influencerBrandRefId", -1);
                        } else {
                          getInfluencerBrandRefCodes(e.target.value);
                        }
                        setFieldValue("influencerBrandId", e.target.value);
                        setFieldValue("influencerBrandRefId", -1);
                      }}
                      name="influencerBrandId"
                      value={values.influencerBrandId}
                    >
                      <MenuItem value={-1}>Tümü</MenuItem>
                      {influencerBrands.map((infBrand) => (
                        <MenuItem
                          key={"infBrand" + infBrand.id}
                          value={infBrand.id}
                        >
                          {infBrand.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {values.influencerBrandId &&
                    values.influencerBrandId !== -1 &&
                    influencerBrandRefCodes &&
                    influencerBrandRefCodes.length > 0 && (
                      <FormControl
                        fullWidth
                        variant="filled"
                        sx={{ gridColumn: "span 1" }}
                      >
                        <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>
                          Referans Kodu (Catcher Inviter (Brand))
                        </InputLabel>
                        <Select
                          labelId="filter-label"
                          label="Referans Kodu (Catcher Inviter (Brand))"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue(
                              "influencerBrandRefId",
                              e.target.value
                            );
                          }}
                          name="influencerBrandRefId"
                          value={values.influencerBrandRefId}
                        >
                          <MenuItem value={-1}>Tümü</MenuItem>
                          {influencerBrandRefCodes.map((infBrandRef) => (
                            <MenuItem
                              key={"infBrandRef" + infBrandRef.id}
                              value={infBrandRef.id}
                            >
                              {infBrandRef.ref_code}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                  <Box m="auto" ml="20px" gridColumn="span 1">
                    <Button type="submit" color="secondary" variant="contained">
                      <Typography variant="h6">Uygula</Typography>
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            background: colors.blueAccent[700],
          },
          "& .MuiDataGrid-footerContainer": {
            background: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: colors.grey[100],
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row.id}
          rows={brands}
          rowHeight={75}
          columns={columns}
          hideFooterPagination
          loading={loading}
          disableColumnMenu
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
          onCellEditCommit={(params, event) => {
            if (typeof params.value === "number") {
              if (params.field === "boost_coefficient") {
                updateBrand(params.id, params.value);
              } else {
                updateComissionFreeCouponCount(params.id, params.value);
              }
            }
          }}
        />
      </Box>
    </Box>
  );
}

export default BrandsTable;
