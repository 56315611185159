import { Rating, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import CommentBox from '../../../../components/CommentBox';
import Header from '../../../../components/Header';
import StatCard from '../../../../components/StatCard';
import CommentIcon from '@mui/icons-material/Comment';
import StarRateIcon from '@mui/icons-material/StarRate';
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import { useEffect } from 'react';

function Comments() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user, token } = useContext(UserContext);
    const [comments, setComments] = useState([]);
    const [statistics, setStatistics] = useState({});

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getComments?token=${token}&brandId=${user.id}&startIndex=0&entryCount=4`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setComments(response.data.comments)
                }
            });
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getCommentCountAndRating?token=${token}&brandId=${user.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setStatistics(response.data.commentStatistics)
                }
            });
    }, []);

    return (
        <Box m="20px 0 20px 20px">
            <Box display="flex" gap="20px" mb="20px">
                <Header title="Yorumlar" subtitle="Markanıza gelen yorumlar" />
                <Box display="grid" gridTemplateColumns="repeat(4, minmax(0, 1fr))" gap="20px">
                    <StatCard icon={<CommentIcon sx={{ scale: "1.5", ml: "10px" }} />} count={statistics.comment_count ? statistics.comment_count : "0"} content="comments" sx={{ gridColumn: "span 1" }} />
                    <StatCard icon={<StarRateIcon sx={{ scale: "1.5", ml: "10px" }} />} count={parseInt(statistics.point ? statistics.point : "0").toPrecision(2) + ""} content="rating" sx={{ gridColumn: "span 1" }} />
                </Box>
            </Box>
            {comments.length > 0 &&
                <Box marginRight="30px">
                    {comments.map((comment) => {
                        return (
                            <CommentBox key={comment.id} comment={comment} />
                        )
                    })}
                </Box>
            }
        </Box>
    )
}

export default Comments;