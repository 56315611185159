import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import WithdrawRequestBox from '../../../../components/WithdrawRequestBox';
import TabPanel, { a11yProps } from '../../../global/TabPanel';

function Payments() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(UserContext);
  const [value, setValue] = useState(0);
  const [partnerWithdraws, setPartnerWithdraws] = useState([]);
  const [influencerWithdraws, setInfluencerWithdraws] = useState([]);
  const [influencerPartnerWithdraws, setInfluencerPartnerWithdraws] = useState([]);
  const [distributeUntil, setDistributeUntil] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchWithdraws()
  }, []);

  const fetchWithdraws = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/withdraw/getWithdrawsWithFilter?token=${token}`, {
      filter: {
        userType: "partner"
      }
    })
      .catch((err) => {
        console.log("err: " + err);
        setPartnerWithdraws([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setPartnerWithdraws(response.data.withdraws);
        }
      });
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/withdraw/getWithdrawsWithFilter?token=${token}`, {
      filter: {
        userType: "influencer"
      }
    })
      .catch((err) => {
        console.log("err: " + err);
        setInfluencerWithdraws([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setInfluencerWithdraws(response.data.withdraws);
        }
      });
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/withdraw/getWithdrawsWithFilter?token=${token}`, {
      filter: {
        userType: "influencer_partner"
      }
    })
      .catch((err) => {
        console.log("err: " + err);
        setInfluencerPartnerWithdraws([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setInfluencerPartnerWithdraws(response.data.withdraws);
        }
      });
  }

  const distributeBalances = () => {
    if (distributeUntil && distributeUntil !== "" && distributeUntil.split("-").length === 3) {
      axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/admin/distributeComissions?token=${token}&distributeUntil=${distributeUntil}`)
        .catch((err) => {
          console.log("err: " + err);
          handleDialogClose()
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            handleDialogClose()
          }
        });
    }
  }

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
  }

  return (
    <Box m="20px">
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Bakiye Dağıtımı</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Son bakiye dağıtımından itibaren aşağıdaki tarihe(dahil) kadar oluşan kazançları dağıtmak istiyor musunuz?
            (YYYY-AA-GG)
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Son Tarih"
            type="text"
            variant="standard"
            value={distributeUntil}
            onChange={(e) => {
              setDistributeUntil(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={handleDialogClose}><Typography variant='h6'>İptal</Typography></Button>
          <Button variant='contained' color='success' onClick={() => {
            distributeBalances();
          }}><Typography variant='h6'>Bakiyeleri Dağıt</Typography></Button>
        </DialogActions>
      </Dialog>
      <Box display="flex" gap="30px" alignItems="center">
        <Box>
          <Header title="Ödemeler" subtitle="Ödemeleri buradan görebilir ve düzenleyebilirsiniz." />
        </Box>
        <Button variant='contained' color='success' sx={{ margin: "auto 0" }} onClick={() => { setOpenDialog(true) }}>
          Bakiyeleri dağıt
        </Button>
      </Box>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor={theme.palette.mode === "dark" ? "secondary" : "primary"}
            indicatorColor={theme.palette.mode === "dark" ? "secondary" : "primary"}>
            <Tab label="Cacher Inviter (BEKLEYEN)" {...a11yProps(0)} />
            <Tab label="Cacher Inviter (ONAYLI)" {...a11yProps(1)} />
            <Tab label="Brand Inviter (BEKLEYEN)" {...a11yProps(2)} />
            <Tab label="Brand Inviter (ONAYLI)" {...a11yProps(3)} />
            <Tab label="Influencer Inviter (BEKLEYEN)" {...a11yProps(4)} />
            <Tab label="Influencer Inviter (ONAYLI)" {...a11yProps(5)} />
          </Tabs>
        </Box>
        <Box>
          <TabPanel value={value} index={0}>
            {influencerWithdraws && influencerWithdraws.length > 0 &&
              (influencerWithdraws.map((withdraw) => {
                return withdraw.status === 0 ? (<WithdrawRequestBox key={withdraw.id} withdraw={withdraw} fetchWithdrawRequests={fetchWithdraws} isAdmin={true} />) : ""
              }
              ))}
          </TabPanel>

          <TabPanel value={value} index={1}>
            {influencerWithdraws && influencerWithdraws.length > 0 &&
              (influencerWithdraws.map((withdraw) => {
                return withdraw.status === 1 ? (<WithdrawRequestBox key={withdraw.id} withdraw={withdraw} fetchWithdrawRequests={fetchWithdraws} isAdmin={true} />) : ""
              }
              ))}
          </TabPanel>

          <TabPanel value={value} index={2}>
            {partnerWithdraws && partnerWithdraws.length > 0 &&
              (partnerWithdraws.map((withdraw) => {
                return withdraw.status === 0 ? (<WithdrawRequestBox key={withdraw.id} withdraw={withdraw} fetchWithdrawRequests={fetchWithdraws} isAdmin={true} />) : ""
              }
              ))}
          </TabPanel>

          <TabPanel value={value} index={3}>
            {partnerWithdraws && partnerWithdraws.length > 0 &&
              (partnerWithdraws.map((withdraw) => {
                return withdraw.status === 1 ? (<WithdrawRequestBox key={withdraw.id} withdraw={withdraw} fetchWithdrawRequests={fetchWithdraws} isAdmin={true} />) : ""
              }
              ))}
          </TabPanel>

          <TabPanel value={value} index={4}>
            {influencerPartnerWithdraws && influencerPartnerWithdraws.length > 0 &&
              (influencerPartnerWithdraws.map((withdraw) => {
                return withdraw.status === 0 ? (<WithdrawRequestBox key={withdraw.id} withdraw={withdraw} fetchWithdrawRequests={fetchWithdraws} isAdmin={true} />) : ""
              }
              ))}
          </TabPanel>

          <TabPanel value={value} index={5}>
            {influencerPartnerWithdraws && influencerPartnerWithdraws.length > 0 &&
              (influencerPartnerWithdraws.map((withdraw) => {
                return withdraw.status === 1 ? (<WithdrawRequestBox key={withdraw.id} withdraw={withdraw} fetchWithdrawRequests={fetchWithdraws} isAdmin={true} />) : ""
              }
              ))}
          </TabPanel>
        </Box>
      </Box>
    </Box>
  )
}

export default Payments