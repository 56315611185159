import { Button, Rating, Tooltip, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios';
import React from 'react'
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';

function MessageBox({ message, onDeletion }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);

    const handleDeleteClick = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/user/deleteMessage?token=${token}&messageId=${message.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    onDeletion();
                }
            });
    }
    return (
        <Box mb="20px" p="20px" minWidth="250px" sx={{ background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb="15px">
                <Box display="flex" alignItems="center">
                    <img
                        alt="profile-user"
                        src={message.profile_photo_link}
                        width="40px"
                        height="40px"
                        style={{ cursor: "pointer", borderRadius: "50%" }} />
                    <Tooltip title={message.username}>
                        <Typography variant='h4' ml="10px" maxWidth="140px" noWrap>{message.username}</Typography>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title={message.email}>
                        <Typography variant='h6' ml="10px" maxWidth="110px" noWrap>{message.email}</Typography>
                    </Tooltip>
                    <Tooltip title={message.phone_number}>
                        <Typography variant='h6' ml="10px" maxWidth="110px" noWrap>{message.phone_number}</Typography>
                    </Tooltip>
                </Box>
            </Box>
            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Typography variant='h6' mt="5px" minHeight="100px">{message.message}</Typography>
            <Box display="flex" justifyContent="end" mt="10px">
                <Button variant='contained' color='error' onClick={() => { handleDeleteClick() }}>
                    Sil
                </Button>
            </Box>
        </Box>
    )
}

export default MessageBox