import React, { useContext, useState } from 'react'
import { Alert, Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material';
import { Formik, useField, useFormikContext } from "formik";
import * as yup from "yup";
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../../../../index.css';
import { tokens } from '../../../../../theme';
import { UserContext } from '../../../../../context/UserContext';

const DatePickerField = ({ ...props }) => {
  const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val);
        setFieldTouched(field.name);
      }}
      className={props.mode}
    />
  );
};

function AnnouncementDialog({ open, handleClose, type, announcement }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.colors);
  const { token } = useContext(UserContext);
  const [alertMessage, setAlertMessage] = useState(null);


  var initialValues = {}

  if (type === "new") {
    initialValues = {
      value: "",
      last_show_date: "",
      type: ""
    }
  } else {
    initialValues = {
      id: announcement.id,
      type: announcement.type,
      value: announcement.value,
      last_show_date: new Date(announcement.last_show_date),
      created_date: new Date(announcement.created_date),
    }
  }

  var validationObject = {}

  if (type === "new") {
    validationObject = {
      value: yup.string().required("zorunlu"),
      last_show_date: yup.date()
        .min(new Date(), "Başlangıç tarihi bugünden eski olamaz")
        .required("zorunlu"),
      type: yup.string().required("zorunlu")
    }
  } else {
    validationObject = {
      value: yup.string().required("zorunlu"),
      last_show_date: yup.date()
        .min(new Date(), "Başlangıç tarihi bugünden eski olamaz")
        .required("zorunlu")
    }
  }
  const announcementSchema = yup.object().shape(validationObject);


  const handleFormSubmit = (values) => {
    values.last_show_date = (values.last_show_date.toISOString().split("T")[0] + " " + values.last_show_date.toISOString().split("T")[1]).split("Z")[0];
    if (values.created_date && values.created_date.toISOString().includes("T")) {
      values.created_date = (values.created_date.toISOString().split("T")[0] + " " + values.created_date.toISOString().split("T")[1]).split("Z")[0];
    }
    if (type === "new") {

      axios.post(`${process.env.REACT_APP_API_URL}api/alcago/announcement?token=${token}`, values)
        .catch((err) => {
          console.log("err: " + err);
          setAlertMessage("Bir hata oluştu");
          setTimeout(() => {
            setAlertMessage(null)
          }, 3000);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            handleClose();
          }
        });
    } else {
      axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/announcement/updateAnnouncement?token=${token}&announcementId=${announcement.id}`, values)
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            handleClose();
          }
        });
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box minWidth="500px" m="20px" mb="5px" textAlign="center">
        {type === "new" &&
          <Typography variant='h2'>Yeni Duyuru</Typography>
        }
        {type !== "new" &&
          <Typography variant='h2'>Duyuruyu Düzenle</Typography>
        }

        {alertMessage && <Alert severity="error" sx={{ marginTop: "20px" }}>{alertMessage}</Alert>}

      </Box>

      <DialogContent>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={announcementSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  variant="filled"
                  type="text"
                  label="Duyuru"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.value}
                  name="value"
                  error={!!touched.value && !!errors.value}
                  helperText={touched.value && errors.value}
                  sx={{ gridColumn: "span 4" }}
                />
                <Box gridColumn="span 2">
                  <InputLabel size='normal' error={!!touched.last_show_date && !!errors.last_show_date} htmlFor="last_show_date">Son Gösterim Tarihi:</InputLabel>
                  <DatePickerField name="last_show_date" mode={theme.palette.mode} />
                </Box>
                {(type === "new") &&
                  <FormControl fullWidth variant="filled"
                    sx={{ gridColumn: "span 2", mt: "18px" }}>
                    <InputLabel id="filter-label">Gönderilecek Grup</InputLabel>
                    <Select
                      labelId="filter-label"
                      label="Age"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="type"
                      value={values.type}
                      error={!!touched.type && !!errors.type}
                    >
                      <MenuItem value={"influencer"}>Cacher Inviters</MenuItem>
                      <MenuItem value={"partner"}>Brand Inviters</MenuItem>
                      <MenuItem value={"influencer_partner"}>Influencer Inviters</MenuItem>
                      <MenuItem value={"brand"}>Brands</MenuItem>
                    </Select>
                  </FormControl>
                }
              </Box>
              <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                <Button type="submit" color="secondary" variant="contained">
                  {type === "new" &&
                    <Typography variant='h5'>Gönder</Typography>
                  }
                  {type !== "new" &&
                    <Typography variant='h5'>Kaydet</Typography>
                  }
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog >
  )
}

export default AnnouncementDialog;