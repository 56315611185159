import { Box, Button, IconButton, InputAdornment, TextField, useTheme } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DetailedInfluencerPartnerBox from '../../../../components/DetailedInfluencerPartnerBox';
import RegisterDialog from '../../../loginPages/influencerPartnerLogin/registerDialog';
import { useNavigate } from 'react-router-dom';

function InfluencerPartners() {
  const theme = useTheme();
  const { token } = useContext(UserContext);
  const [influencerPartners, setInfluencerPartners] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchInfluencerPartners()
  }, []);

  const fetchInfluencerPartners = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencerPartner/getInfluencerPartners?token=${token}&searchKey=${searchKey}`)
      .catch((err) => {
        console.log("err: " + err);
        setInfluencerPartners([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setInfluencerPartners(response.data.influencerPartners);
        }
      });
  }

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
    fetchInfluencerPartners()
  }

  const influencersButtonClick = (influencerPartner) => {
    navigate(`/admin-panel/influencers/${influencerPartner.id}/${influencerPartner.name + " " + influencerPartner.surname}`);
  }

  return (
    <Box m="20px">
      <RegisterDialog open={openDialog} handleClose={handleDialogClose} type="new" isAddingFromAdminPanel={true} />
      <Box display="flex" gap="30px" alignItems="center">
        <Box>
          <Header title="Influencer Inviterlar" subtitle="Influencer Inviterları buradan görebilir ve düzenleyebilirsiniz." />
        </Box>
        <TextField
          id="outlined-basic"
          label="Influencer Inviter Ara"
          variant="outlined"
          onChange={(event) => {
            setSearchKey(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setSearchKey(event.target.value);
              fetchInfluencerPartners()
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => {
                  fetchInfluencerPartners()
                }}
                  sx={{ marginLeft: "10px" }}>
                  <SearchOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }} />
        <Button variant='contained' color='success' sx={{ margin: "auto 0" }} onClick={() => { setOpenDialog(true) }}>
          YENI INFLUENCER INVITER EKLE
        </Button>
      </Box>
      <Box>
        {influencerPartners.map(influencerPartner =>
          <DetailedInfluencerPartnerBox key={influencerPartner.id} influencerPartner={influencerPartner} fetchInfluencerPartners={fetchInfluencerPartners} influencersButtonClick={influencersButtonClick} />)
        }
      </Box>
    </Box>
  )
}

export default InfluencerPartners