import { Box, Tab, Tabs, useTheme } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import MessageBox from '../../../../components/MessageBox';
import TabPanel, { a11yProps } from '../../../global/TabPanel';
import WordPressMessageBox from '../../../../components/WordPressMessageBox';
import DemoRequestBox from '../../../../components/DemoRequestBox';

function ContactMessages() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(UserContext);
  const [messages, setMessages] = useState([]);
  const [wordPressMessages, setWordPressMessages] = useState([]);
  const [demoRequests, setDemoRequests] = useState([]);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchDatas()
  }, []);

  const fetchDatas = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/user/getMessages?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        setMessages([])
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setMessages(response.data.messages);
        }
      });
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/thirdPartyRequest/getWordPressContactMessages?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        setWordPressMessages([])
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setWordPressMessages(response.data.messages);
        }
      });
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/thirdPartyRequest/getDemoRequests?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        setDemoRequests([])
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setDemoRequests(response.data.requests);
        }
      });
  }

  return (
    <Box m="20px">
      <Box display="flex" gap="20px" alignItems="center">
        <Header title="İletişim Mesajları" subtitle="Uygulamadaki iletişim alanından gönderilen mesajları, web sitesinden gönderilen mesajları ve demo taleplerini buradan görüntüleyebilir, silebilirsiniz." />
      </Box>
      <Box>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor={theme.palette.mode === "dark" ? "secondary" : "primary"}
              indicatorColor={theme.palette.mode === "dark" ? "secondary" : "primary"}>
              <Tab label="Uygulama İletişim Mesajları" {...a11yProps(0)} />
              <Tab label="Web Sitesi İletişim Mesajları" {...a11yProps(1)} />
              <Tab label="Demo Talebi (Marka Temsilcisi)" {...a11yProps(2)} />
              <Tab label="Demo Talebi (Influencer)" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <Box>
            <TabPanel value={value} index={0}>
              {messages.map((message, i) =>
                <Box key={i} display="inline-block" p="20px" mr="20px" mt="20px" sx={{ borderRadius: "20px", width: "400px" }}>
                  <MessageBox message={message} onDeletion={() => fetchDatas()} />
                </Box>
              )}
            </TabPanel>

            <TabPanel value={value} index={1}>
              {wordPressMessages.map((message, i) =>
                <Box key={i} display="inline-block" p="20px" mr="20px" mt="20px" sx={{ borderRadius: "20px", width: "400px" }}>
                  <WordPressMessageBox message={message} onDeletion={() => fetchDatas()} />
                </Box>
              )}
            </TabPanel>

            <TabPanel value={value} index={2}>
              {demoRequests.map((request, i) => {
                if (request.type === "Marka Temsilcisi") {
                  return (
                    <Box key={i} display="inline-block" p="20px" mr="20px" mt="20px" sx={{ borderRadius: "20px", width: "400px" }}>
                      <DemoRequestBox request={request} onDeletion={() => fetchDatas()} />
                    </Box>
                  )
                } else {
                  return "";
                }
              }
              )}
            </TabPanel>

            <TabPanel value={value} index={3}>
              {demoRequests.map((request, i) => {
                if (request.type === "Influencer") {
                  return (
                    <Box key={i} display="inline-block" p="20px" mr="20px" mt="20px" sx={{ borderRadius: "20px", width: "400px" }}>
                      <DemoRequestBox request={request} onDeletion={() => fetchDatas()} />
                    </Box>
                  )
                } else {
                  return "";
                }
              }
              )}
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Box >
  )
}

export default ContactMessages