import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import axios from 'axios';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function OrderBox({ order }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const [orderDetails, setOrderDetails] = useState([]);
    const [orderDetailsDialogOpen, setOrderDetailsDialogOpen] = useState(false);

    const fetchOrderDetails = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getOrderDetails?token=${token}&orderId=${order.id}`)
            .catch((err) => {
                console.log("err: " + err);
                setOrderDetails([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setOrderDetails(response.data.orderDetails);
                }
            });
    }

    useEffect(() => {
        fetchOrderDetails();
    }, [])

    return (
        <Box mb="20px" p="20px" minWidth="250px" sx={{ background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Dialog
                open={orderDetailsDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOrderDetailsDialogOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle variant='h3' mb="20px" >Sipariş Detayları</DialogTitle>
                <DialogContent>
                    <Box>
                        {orderDetails.map((detail) => <Typography key={"detail" + detail.id} variant="h6">{detail.quantity + "x " + detail.product_name + ": " + detail.selling_price + "₺"}</Typography>)}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOrderDetailsDialogOpen(false)}><Typography variant='h6' color="error">Kapat</Typography></Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" justifyContent="space-between">
                <Box display="flex">
                    <img
                        alt="profile-user"
                        src={order.user_profile_photo_link}
                        width="40px"
                        height="40px"
                        style={{ cursor: "pointer", borderRadius: "50%" }} />
                    <Box ml="10px" mb="5px">
                        <Typography variant='h4'>{order.user_name}</Typography>
                        <Typography variant='h6'>{new Date(order.created_date).toLocaleString()}</Typography>
                    </Box>
                </Box>
                <Box>
                    <IconButton onClick={() => { setOrderDetailsDialogOpen(true) }}>
                        <ReceiptLongOutlinedIcon sx={{ height: "30px", width: "30px" }} />
                    </IconButton>
                </Box>
            </Box>
            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Box display="flex" mt="20px">
                <Box minWidth="190px" maxWidth="190px" textAlign="center" m="0 10px" p="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                    <Typography variant='h5' mb="5px">Brand</Typography>

                    <img
                        alt="profile-brand"
                        src={order.brand_profile_photo_link}
                        width="40px"
                        height="40px"
                        style={{ cursor: "pointer", borderRadius: "50%" }} />
                    <Typography variant='h4' mt="5px" noWrap>{order.brand_name}</Typography>

                </Box>
                {order.influencer_name &&
                    <Box minWidth="190px" maxWidth="190px" textAlign="center" m="0 10px" p="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                        <Typography variant='h5' mb="5px">Catcher Inviter</Typography>
                        <img
                            alt="profile-influencer"
                            src={order.influencer_profile_photo_link}
                            width="40px"
                            height="40px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />
                        <Typography variant='h4' mt="5px" noWrap>{order.influencer_name}</Typography>

                    </Box>
                }
                {order.influencer_brand_name &&
                    <Box minWidth="190px" maxWidth="190px" textAlign="center" m="0 10px" p="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                        <Typography variant='h5'>Catcher Inviter</Typography>
                        <Typography variant='h6' mb="5px">(Brand)</Typography>
                        <img
                            alt="profile-influencer"
                            src={order.influencer_brand_profile_photo_link}
                            width="40px"
                            height="40px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />
                        <Typography variant='h4' mt="5px" noWrap>{order.influencer_brand_name}</Typography>

                    </Box>
                }
                <Box minWidth="190px" maxWidth="190px" textAlign="center" m="0 10px" p="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                    <Typography variant='h5' mb="5px">Brand Inviter</Typography>
                    {order.partner_name &&
                        <img
                            alt="profile-partner"
                            src={order.partner_profile_photo_link}
                            width="40px"
                            height="40px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />}
                    <Typography variant='h4' mt="5px" noWrap>{order.partner_name ? order.partner_name : "-"}</Typography>

                </Box>
                <Box minWidth="190px" maxWidth="190px" textAlign="center" m="0 10px" p="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                    <Typography variant='h5' mb="5px">Influencer Inviter</Typography>
                    {order.influencer_partner_name &&
                        <img
                            alt="profile-partner"
                            src={order.influencer_partner_profile_photo_link}
                            width="40px"
                            height="40px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />}
                    <Typography variant='h4' mt="5px" noWrap>{order.influencer_partner_name ? order.influencer_partner_name : "-"}</Typography>

                </Box>
                <Box minWidth="190px" maxWidth="190px" m="0 10px" p="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
                    <Box display="flex" height="100%" justifyContent="space-between" alignItems="center">
                        <Box>
                            <PaidOutlinedIcon sx={{ scale: "2" }} />
                        </Box>
                        <Box textAlign="right" >
                            <Typography variant='h4' fontWeight="bold" sx={{ color: colors.grey[100] }}>
                                {order.total_price.toFixed(2) + " ₺"}
                            </Typography>
                            <Typography variant="h5" fontStyle="italic" sx={{ color: (order.state === 1 ? colors.greenAccent[500] : colors.redAccent[500]) }}>
                                {(order.state === 1 ? "Satış" : order.state === 2 ? "İptal" : "İade")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default OrderBox