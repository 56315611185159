import { Box, Button, IconButton, InputAdornment, TextField, useTheme } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DetailedInfluencerBox from '../../../../components/DetailedInfluencerBox';
import RegisterDialog from '../../../loginPages/influencerLogin/registerDialog';
import { useNavigate } from 'react-router-dom';

function Influencers() {
  const theme = useTheme();
  const { token } = useContext(UserContext);
  const [influencers, setInfluencers] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchInfluencers()
  }, []);

  const fetchInfluencers = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencer/getInfluencers?token=${token}&searchKey=${searchKey}`)
      .catch((err) => {
        console.log("err: " + err);
        setInfluencers([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setInfluencers(response.data.influencers);
        }
      });
  }

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => {
    setOpenDialog(false);
    fetchInfluencers()
  }

  const usersButtonClick = (influencer) => {
    navigate(`/admin-panel/users/${influencer.id}/${influencer.name + " " + influencer.surname}`);
  }

  return (
    <Box m="20px">
      <RegisterDialog open={openDialog} handleClose={handleDialogClose} type="new" isAddingFromAdminPanel={true} />
      <Box display="flex" gap="30px" alignItems="center">
        <Box>
          <Header title="Influencerlar" subtitle="Influencerları buradan görebilir ve düzenleyebilirsiniz." />
        </Box>
        <TextField
          id="outlined-basic"
          label="Influencer ara"
          variant="outlined"
          onChange={(event) => {
            setSearchKey(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setSearchKey(event.target.value);
              fetchInfluencers()
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => {
                  fetchInfluencers()
                }}
                  sx={{ marginLeft: "10px" }}>
                  <SearchOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }} />
        <Button variant='contained' color='success' sx={{ margin: "auto 0" }} onClick={() => { setOpenDialog(true) }}>
          Yeni Influencer Ekle
        </Button>
      </Box>
      <Box>
        {influencers.map(influencer =>
          <DetailedInfluencerBox key={influencer.id} influencer={influencer} fetchInfluencers={fetchInfluencers} usersButtonClick={usersButtonClick} />)
        }
      </Box>
    </Box>
  )
}

export default Influencers