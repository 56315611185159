import React, { useState, useContext } from 'react'
import { UserContext } from '../../../../../context/UserContext';
import { Alert, Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Formik } from "formik";
import * as yup from "yup";
import YupPassword from 'yup-password';
YupPassword(yup);

function AddBrandAccountDialog({ open, handleClose }) {
  const { token, user } = useContext(UserContext);
  const [alertMessage, setAlertMessage] = useState(null);

  const initialValues = {
    name: "",
    surname: "",
    email: "",
    password: "",
    passwordCheck: ""
  }

  const brandSchema = yup.object().shape({
    name: yup.string().required("zorunlu"),
    surname: yup.string().required("zorunlu"),
    email: yup.string().email("Geçersiz eposta adresi").required("zorunlu"),
    password: yup.string()
      .required('zorunlu')
      .min(8, 'Şifre çok kısa - en az 8 karakter olmalı')
      .minLowercase(1, 'Şifreniz en az 1 küçük harf içermelidir')
      .minUppercase(1, 'Şifreniz en az 1 büyük harf içermelidir')
      .minNumbers(1, 'Şifreniz en az 1 rakam içermelidir')
      .minSymbols(1, 'Şifreniz en az 1 özel karakter içermelidir'),
    passwordCheck: yup.string()
      .oneOf([yup.ref('password'), null], 'Şifreler uyuşmuyor')
  });


  const handleFormSubmit = (values) => {
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/validateUserData`, {
      email: values.email
    })
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          if (response.data.isValid) {
            axios.post(`${process.env.REACT_APP_API_URL}api/alcago/brand/createAccount?token=${token}&brandId=${user.id}`, {
              email: values.email,
              name: values.name,
              surname: values.surname,
              password: values.password,
              is_approved: 1,
              user_agreement: 1
            })
              .catch((err) => {
                console.log("err: " + err);
                setAlertMessage("Bir hata oluştu.");
              })
              .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {

                }
              });
            handleClose();
          } else {
            setAlertMessage(response.data.unvalidField + ' ("' + response.data.unvalidValue + '") zaten kullanılıyor.');
          }
        }
      });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box minWidth="500px" m="20px" mb="5px" textAlign="center">
        <Typography variant='h2'>
          Yönetici Ekle
        </Typography>
        {alertMessage && <Alert severity="error" sx={{ marginTop: "20px" }}>{alertMessage}</Alert>}
      </Box>

      <DialogContent>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={brandSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="İsim"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Soyisim"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.surname}
                  name="surname"
                  error={!!touched.surname && !!errors.surname}
                  helperText={touched.surname && errors.surname}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Eposta"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />
                <br />
                <TextField
                  fullWidth
                  variant="filled"
                  type="password"
                  label="Şifre"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="password"
                  label="Şifre"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.passwordCheck}
                  name="passwordCheck"
                  error={!!touched.passwordCheck && !!errors.passwordCheck}
                  helperText={touched.passwordCheck && errors.passwordCheck}
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                <Button type="submit" color="secondary" variant="contained" >
                  <Typography variant='h5'>Kaydet</Typography>
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default AddBrandAccountDialog;