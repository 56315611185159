import React, { useContext, useState } from 'react'
import { Box, Button, Typography, useTheme } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';
import { tokens } from '../theme';
import axios from 'axios';
import { UserContext } from '../context/UserContext';
import AlertDialogSlide from './AlertDialogSlide';
import AnnouncementDialog from '../scenes/panels/adminPanel/announcements/announcementDialog';

function AnnouncementBox({ announcement: { id, type, value, last_show_date, created_date }, fetchAnnouncements }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token, user } = useContext(UserContext);

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const handleAlertDialogClose = () => {
    setOpenAlertDialog(false);
  }
  const clickPositiveAnswer = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/announcement/deleteAnnouncement?token=${token}&announcementId=${id}`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 201) {
          fetchAnnouncements();
        }
      });
  }
  const clickNegativeAnswer = () => { }

  const handleDelete = () => {
    setOpenAlertDialog(true);
  }


  const [openEditDialog, setOpenEditDialog] = useState(false);
  const handleEditDialogClose = () => {
    fetchAnnouncements();
    setOpenEditDialog(false);
  }
  const handleEdit = () => {
    setOpenEditDialog(true);
  }

  return (
    <Box width="100%" p="20px" marginBottom="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
      <AlertDialogSlide
        open={openAlertDialog} handleClose={handleAlertDialogClose}
        clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer}
        positiveAnswer={"Sil"} negativeAnswer={"İptal"}
        title={"Bu duyuruyu silmek istediğinize emin misiniz?"}
      />
      <AnnouncementDialog open={openEditDialog} handleClose={handleEditDialogClose} type="edit" announcement={{ id, type, value, last_show_date, created_date }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box>
            <CampaignIcon sx={{ scale: "3", ml: "20px", mr: "50px" }} />
          </Box>
          <Box mr="20px">
            <Typography variant="h5" fontWeight="bold" sx={{ color: colors.grey[100] }}>
              {value}
            </Typography>
            <Typography fontStyle="italic" sx={{ color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200]) }}>
              Duyuru Tarihi: {new Date(created_date).toLocaleDateString()}
            </Typography>
            {user.type === "admin" &&
              <Typography fontStyle="italic" sx={{ color: (theme.palette.mode === "dark" ? colors.greenAccent[500] : colors.greenAccent[200]) }}>
                Son Gösterim Tarihi: {new Date(last_show_date).toLocaleDateString()}
              </Typography>
            }
          </Box>
        </Box>
        {user.type === "admin" &&
          < Box textAlign="center">
            <Button variant='contained' color='info' sx={{ mb: "5px" }} onClick={handleEdit}>Düzenle</Button>
            <br />
            <Button variant='contained' color='error' sx={{ mt: "5px" }} onClick={handleDelete}>Sil</Button>
          </Box>
        }
      </Box>
    </Box >
  )
}

export default AnnouncementBox;