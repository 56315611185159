import { Button, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { tokens } from '../theme';

function PartnerBox({ partner, onPartnerDetailsButtonClick }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box display="inline-block" mr="10px" mb="20px" p="20px" sx={{ background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <img
                        alt="profile-user"
                        src={partner.profile_photo_link}
                        width="40px"
                        height="40px"
                        style={{ cursor: "pointer", borderRadius: "50%" }} />
                    <Typography variant='h4' ml="10px">{(partner.name + " " + partner.surname).length >= 15 ? ((partner.name + " " + partner.surname).slice(0, 12) + "...") : (partner.name + " " + partner.surname)}</Typography>
                </Box>
                <Button type="submit" color="secondary" variant="contained" sx={{ ml: "10px" }} onClick={() => onPartnerDetailsButtonClick(partner)}>
                    <Typography variant='h6'>Onayla</Typography>
                </Button>
            </Box>
        </Box>
    )
}

export default PartnerBox