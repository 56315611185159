import { CircularProgress, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';

function BubbleBox({ bubble }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const [bubbleDetails, setBubbleDetails] = useState(null);

    const getBubbleDetails = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getBubbleDetails?token=${token}&bubbleId=${bubble.id}`)
            .catch((err) => {
                console.log("err: " + err);
                setBubbleDetails(null)
            })
            .then((response) => {
                if (response && response.data && response.data.success && response.data.status === 200) {
                    setBubbleDetails(response.data.bubbleDetails);
                }
            });
    }

    useEffect(() => {
        getBubbleDetails();
    }, []);

    return (
        <Box m="10px" mb="20px" p="20px" display="inline-block"
            sx={{
                background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500],
                borderRadius: "20px"
            }}
        >

            {!bubbleDetails &&
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" />
                </Box>
            }

            {bubbleDetails &&
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center" >
                        <Box sx={{ background: "white", p: "5px" }}>
                            <img
                                alt="bubble"
                                src={bubbleDetails.image_link}
                                width="40px"
                                height="40px"
                                style={{ cursor: "pointer" }} />
                        </Box>
                        <Box ml="10px">
                            <Box display="flex" gap="5px">
                                <Typography variant='h4'>{"Balouncuk - " + bubble.id}</Typography>
                            </Box>
                            <Box display="inline-block" padding="1px 10px" sx={{ background: bubble.type === 1 ? colors.greenAccent[600] : colors.redAccent[600], borderRadius: "10px" }}>
                                <Typography variant='h6'>{bubble.type === 1 ? "Puan Veren" : "Puan Düşüren"}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
            {bubbleDetails &&
                <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            }
            {bubbleDetails &&
                <Box>
                    <Typography variant='h6' >{bubble.show_time / 1000 + " saniye ekranda kalır."}</Typography>
                    <Typography variant='h6' >{bubble.appaer_time / 1000 + ". saniye ekranda belirir"}</Typography>
                    <Typography variant='h6' >{(bubbleDetails.touched_count + bubbleDetails.not_touched_count) + " defa gösterildi."}</Typography>
                    <Typography variant='h6' >{(bubbleDetails.touched_count + " defa tıklandı. (%" + (100 * bubbleDetails.touched_count / (bubbleDetails.touched_count + bubbleDetails.not_touched_count)).toFixed(1) + ")").replace("NaN", 0)}</Typography>
                    <Typography variant='h6' >{(bubbleDetails.not_touched_count + " defa kaçırıldı. (%" + (100 * bubbleDetails.not_touched_count / (bubbleDetails.touched_count + bubbleDetails.not_touched_count)).toFixed(1) + ")").replace("NaN", 0)}</Typography>
                    {bubble.user_touched !== undefined && bubble.user_touched !== null &&
                        <Typography variant='h6' color={(bubble.user_touched === 1 && bubble.type === 1) ? colors.greenAccent[600] : colors.redAccent[600]} >{bubble.user_touched === 1 ? "Kullanıcı tıkladı." : "Kullanıcı kaçırdı."}</Typography>
                    }
                    {!(bubble.user_touched !== undefined && bubble.user_touched !== null) &&
                        <Typography variant='h6'>&nbsp;</Typography>
                    }
                </Box>
            }
        </Box >
    )
}

export default BubbleBox