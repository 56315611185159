import { Box, Button, useTheme } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import axios from 'axios';
import AdditionalApCampaignBox from '../../../../components/AdditionalApCampaignBox';
import AddAdditionalApCampaignDialog from './addAdditionalApCampaignDialog';
//import AddCampaignDialog from './addCampaignDialog';

function AdditionalApCampaigns() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, token } = useContext(UserContext);
  const [campaigns, setCampaigns] = useState([]);

  const fetchCampaigns = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAdditionalApCampaigns?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        if (err.response.data.status === 404) {
          setCampaigns([]);
        }
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setCampaigns(response.data.campaigns);
        }
      });
  }

  useEffect(() => {
    fetchCampaigns()
  }, []);


  const [openAddCampaignDialog, setOpenAddCampaignDialog] = useState(false);


  const handleAddCampaignDialogClose = () => {
    setOpenAddCampaignDialog(false);
    fetchCampaigns();
  }

  return (
    <Box m="20px 0 20px 20px">
      <AddAdditionalApCampaignDialog open={openAddCampaignDialog} handleClose={handleAddCampaignDialogClose} />
      <Box ml="20px" display="flex" gap="20px" pr="20px">
        <Header title="Ek AP Kampanyaları" subtitle="Yeni kampanya ekleyebilir, daha önceden eklenmiş kampanyaları görüntüleyebilirsiniz." />

        <Button variant='contained' color='success' sx={{ margin: "auto 0" }} onClick={() => {
          setOpenAddCampaignDialog(true);
        }}>
          Yeni Kampanya Ekle
        </Button>
      </Box>
      <Box>
        <Box mt="10px">
          {campaigns.map((campaign) => <AdditionalApCampaignBox key={"apCampaign" + campaign.id} campaign={campaign} fetchCampaigns={fetchCampaigns} />)}
        </Box>
      </Box>
    </Box>

  )
}

export default AdditionalApCampaigns