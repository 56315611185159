import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios';
export const UserContext = createContext();

export function UserProvider(props) {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const endPoints = {
        admin: "admin/getAdmin",
        influencer: "influencer/getInfluencer",
        brand: "brand/getBrandAndAccount",
        partner: "partner/getPartner",
        influencer_partner: "influencerPartner/getInfluencerPartner"
    }

    const handleToken = (newToken) => {
        localStorage.setItem('token', newToken);
    }

    const handleUser = async (token) => {
        setIsLoading(true);
        var userType = localStorage.getItem('userType');
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/${endPoints[userType]}?token=${token}`)
            .catch((err) => {
                setUser(null);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    response.data.user.type = userType;
                    setUser(response.data.user);
                }
                setIsLoading(false);
            });

    }

    useEffect(() => {
        handleToken(token);
        if (token) {
            handleUser(token);
        } else {
            setUser(null);
            localStorage.setItem("userType", null);
        }
    }, [token])

    return (
        <UserContext.Provider value={{ token, setToken, user, setUser, isLoading }}>
            {props.children}
        </UserContext.Provider>
    )
}