import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography, useTheme } from '@mui/material'
import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react'
import Header from '../../../../components/Header'
import OrderBox from '../../../../components/OrderBox';
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Formik } from 'formik';



function Orders() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [influencers, setInfluencers] = useState([]);
  const [influencerBrands, setInfluencerBrands] = useState([]);
  const [brands, setBrands] = useState([]);
  const [partners, setPartners] = useState([]);
  const [influencerPartners, setInfluencerPartners] = useState([]);
  const [hasMore, setHasMore] = useState(true);


  const fetchOrders = (filter, startIndex) => {
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/order/getOrdersWithFilter?token=${token}&startIndex=${startIndex}&limit=50`, {
      filter: filter
    })
      .catch((err) => {
        console.log("err: " + err);
        setHasMore(false);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setHasMore(response.data.orders && response.data.orders.length === 50);
          if (startIndex === 0) {
            setOrders([...response.data.orders]);
          } else {
            setOrders((prev) => [...prev, ...response.data.orders]);
          }
        }
      });
  }

  const getInfluencers = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencer/getInfluencers?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        setInfluencers([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setInfluencers(response.data.influencers);
        }
      });
  }

  const getBrands = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getAllBrands?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        setBrands([]);
        setInfluencerBrands([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setBrands(response.data.brands);
          setInfluencerBrands(response.data.brands.filter(({ is_influencer }) => is_influencer === 1));
        }
      });
  }

  const getPartners = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/partner/getPartners?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        setPartners([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setPartners(response.data.partners);
        }
      });
  }

  const getInfluencerPartners = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencerPartner/getInfluencerPartners?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        setInfluencerPartners([]);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setInfluencerPartners(response.data.influencerPartners);
        }
      });
  }

  const initialValues = {
    influencerId: -1,
    influencerBrandId: -1,
    partnerId: -1,
    brandId: -1,
    influencerPartnerId: -1
  }

  const handleFormSubmit = (values) => {
    setOrders([]);
    fetchOrders(values, 0);
  }

  useEffect(() => {
    fetchOrders(initialValues, 0);
    getInfluencers();
    getBrands();
    getPartners();
    getInfluencerPartners();
  }, []);



  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Siparişler" subtitle="Siparişler sayfasına hoş geldiniz" />
      </Box>
      <Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="30px" gridTemplateColumns="repeat(12, minmax(0, 1fr))" mb="30px">
                <FormControl fullWidth variant="filled"
                  sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Brand</InputLabel>
                  <Select
                    labelId="filter-label"
                    label="Brand"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="brandId"
                    value={values.brandId}
                  >
                    <MenuItem value={-1}>Tümü</MenuItem>
                    {brands.map((brand) => <MenuItem key={"brand" + brand.id} value={brand.id}>{brand.name}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="filled"
                  sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Catcher Inviter</InputLabel>
                  <Select
                    labelId="filter-label"
                    label="Catcher Inviter"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="influencerId"
                    value={values.influencerId}
                  >
                    <MenuItem value={-1}>Tümü</MenuItem>
                    {influencers.map((influencer) => <MenuItem key={"influencer" + influencer.id} value={influencer.id}>{influencer.name + " " + influencer.surname}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="filled"
                  sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Catcher Inviter (Brand)</InputLabel>
                  <Select
                    labelId="filter-label"
                    label="Catcher Inviter (Brand)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="influencerBrandId"
                    value={values.influencerBrandId}
                  >
                    <MenuItem value={-1}>Tümü</MenuItem>
                    {influencerBrands.map((influencerBrand) => <MenuItem key={"influencerBrand" + influencerBrand.id} value={influencerBrand.id}>{influencerBrand.name}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="filled"
                  sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Brand Inviter</InputLabel>
                  <Select
                    labelId="filter-label"
                    label="Brand Inviter"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="partnerId"
                    value={values.partnerId}
                  >
                    <MenuItem value={-1}>Tümü</MenuItem>
                    {partners.map((partner) => <MenuItem key={"partner" + partner.id} value={partner.id}>{partner.name + " " + partner.surname}</MenuItem>)}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="filled"
                  sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="filter-label" sx={{ fontSize: "10px" }}>Influencer Inviter</InputLabel>
                  <Select
                    labelId="filter-label"
                    label="Influencer Inviter"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="influencerPartnerId"
                    value={values.influencerPartnerId}
                  >
                    <MenuItem value={-1}>Tümü</MenuItem>
                    {influencerPartners.map((influencerPartner) => <MenuItem key={"influencerPartner" + influencerPartner.id} value={influencerPartner.id}>{influencerPartner.name + " " + influencerPartner.surname}</MenuItem>)}
                  </Select>
                </FormControl>
                <Button type="submit" color="secondary" variant="contained" sx={{ my: "10px" }}>
                  <Typography variant='h6'>Uygula</Typography>
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
      <Box>
        <InfiniteScroll
          dataLength={orders.length}
          next={() => fetchOrders(initialValues, orders.length)}
          hasMore={hasMore}
          loader={<h4>Yükleniyor...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Tüm siparişleri görüntülüyorsunuz.</b>
            </p>
          }
        >
          {orders.map((order) => <OrderBox key={"order" + order.id} order={order} />)}
        </InfiniteScroll>
      </Box>
    </Box>
  )
}

export default Orders