import { Button, Rating, Tooltip, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios';
import React from 'react'
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';

function WordPressMessageBox({ message, onDeletion }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);

    const handleDeleteClick = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/thirdPartyRequest/deleteWordPressMessage?token=${token}&messageId=${message.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    onDeletion();
                }
            });
    }
    return (
        <Box mb="20px" p="20px" minWidth="250px" sx={{ background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box display="flex" justifyContent="space-between" mb="15px">
                <Box>
                    <Tooltip title={message.name + " " + message.surname}>
                        <Typography variant='h4' ml="10px" maxWidth="250px" noWrap>{message.name + " " + message.surname}</Typography>
                    </Tooltip>
                    <br />
                    <Tooltip title={message.phone_number}>
                        <Typography variant='h6' ml="10px" maxWidth="250px" noWrap>{message.phone_number}</Typography>
                    </Tooltip>
                    <Tooltip title={message.email}>
                        <Typography variant='h6' ml="10px" maxWidth="250px" noWrap>{message.email}</Typography>
                    </Tooltip>
                    <Tooltip title={new Date(message.created_date).toLocaleTimeString("tr-TR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}>
                        <Typography variant='h6' ml="10px" maxWidth="250px" noWrap>{new Date(message.created_date).toLocaleTimeString("tr-TR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
                    </Tooltip>
                </Box>
            </Box>
            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Typography variant='h6' mt="5px" minHeight="100px">{message.message}</Typography>
            <Box display="flex" justifyContent="end" mt="10px">
                <Button variant='contained' color='error' onClick={() => { handleDeleteClick() }}>
                    Sil
                </Button>
            </Box>
        </Box>
    )
}

export default WordPressMessageBox