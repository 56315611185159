import React, { useContext, useState } from 'react'
import { Alert, Box, Button, Checkbox, Dialog, DialogContent, FormControlLabel, TextField, Typography, useTheme } from '@mui/material';
import { Formik } from "formik";
import * as yup from "yup";
import YupPassword from 'yup-password';
import axios from 'axios';
import { tokens } from '../../../../../theme';
import { UserContext } from '../../../../../context/UserContext';
YupPassword(yup);

function AdminDialog({ open, handleClose, type, admin }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.colors);
  const { token } = useContext(UserContext);
  const [alertMessage, setAlertMessage] = useState(null);

  var initialValues = {}

  if (type === "new") {
    initialValues = {
      name: "",
      surname: "",
      email: "",
      password: "",
      passwordCheck: "",
      dashboard_perm: false,
      statistics_perm: false,
      brands_perm: false,
      ads_perm: false,
      ad_groups_perm: false,
      sign_up_settings_perm: false,
      shortcuts_perm: false,
      users_perm: false,
      orders_perm: false,
      contact_messages_perm: false,
      influencers_perm: false,
      partners_perm: false,
      admins_perm: false,
      announcements_perm: false,
      payments_perm: false
    }
  }

  if (type === "edit") {
    initialValues = {
      name: admin.name,
      surname: admin.surname,
      email: admin.email,
      dashboard_perm: (admin.dashboard_perm === 1),
      statistics_perm: (admin.statistics_perm === 1),
      brands_perm: (admin.brands_perm === 1),
      ads_perm: (admin.ads_perm === 1),
      ad_groups_perm: (admin.ad_groups_perm === 1),
      sign_up_settings_perm: (admin.sign_up_settings_perm === 1),
      shortcuts_perm: (admin.shortcuts_perm === 1),
      users_perm: (admin.users_perm === 1),
      orders_perm: (admin.orders_perm === 1),
      contact_messages_perm: (admin.contact_messages_perm === 1),
      influencers_perm: (admin.influencers_perm === 1),
      partners_perm: (admin.partners_perm === 1),
      admins_perm: (admin.admins_perm === 1),
      announcements_perm: (admin.announcements_perm === 1),
      payments_perm: (admin.payments_perm === 1)
    }
  }

  var validationObject = {}

  if (type === "new") {
    validationObject = {
      name: yup.string().required("zorunlu"),
      surname: yup.string().required("zorunlu"),
      email: yup.string().email("Geçersiz eposta adresi").required("zorunlu"),
      password: yup.string()
        .required('zorunlu')
        .min(8, 'Şifre çok kısa - en az 8 karakter olmalı')
        .minLowercase(1, 'Şifreniz en az 1 küçük harf içermelidir')
        .minUppercase(1, 'Şifreniz en az 1 büyük harf içermelidir')
        .minNumbers(1, 'Şifreniz en az 1 rakam içermelidir')
        .minSymbols(1, 'Şifreniz en az 1 özel karakter içermelidir'),
      passwordCheck: yup.string()
        .oneOf([yup.ref('password'), null], 'Şifreler uyuşmuyor'),
      dashboard_perm: yup.bool().required("zorunlu"),
      statistics_perm: yup.bool().required("zorunlu"),
      brands_perm: yup.bool().required("zorunlu"),
      ads_perm: yup.bool().required("zorunlu"),
      ad_groups_perm: yup.bool().required("zorunlu"),
      sign_up_settings_perm: yup.bool().required("zorunlu"),
      shortcuts_perm: yup.bool().required("zorunlu"),
      users_perm: yup.bool().required("zorunlu"),
      orders_perm: yup.bool().required("zorunlu"),
      contact_messages_perm: yup.bool().required("zorunlu"),
      influencers_perm: yup.bool().required("zorunlu"),
      partners_perm: yup.bool().required("zorunlu"),
      admins_perm: yup.bool().required("zorunlu"),
      announcements_perm: yup.bool().required("zorunlu"),
      payments_perm: yup.bool().required("zorunlu")
    }
  }

  if (type === "edit") {
    validationObject = {
      name: yup.string().required("zorunlu"),
      surname: yup.string().required("zorunlu"),
      dashboard_perm: yup.bool().required("zorunlu"),
      statistics_perm: yup.bool().required("zorunlu"),
      brands_perm: yup.bool().required("zorunlu"),
      ads_perm: yup.bool().required("zorunlu"),
      ad_groups_perm: yup.bool().required("zorunlu"),
      sign_up_settings_perm: yup.bool().required("zorunlu"),
      shortcuts_perm: yup.bool().required("zorunlu"),
      users_perm: yup.bool().required("zorunlu"),
      orders_perm: yup.bool().required("zorunlu"),
      contact_messages_perm: yup.bool().required("zorunlu"),
      influencers_perm: yup.bool().required("zorunlu"),
      partners_perm: yup.bool().required("zorunlu"),
      admins_perm: yup.bool().required("zorunlu"),
      announcements_perm: yup.bool().required("zorunlu"),
      payments_perm: yup.bool().required("zorunlu")
    }
  }
  const adminSchema = yup.object().shape(validationObject);


  const handleFormSubmit = (values) => {
    if (type === "new") {
      const data = {
        name: values.name,
        surname: values.surname,
        email: values.email,
        password: values.password,
        dashboard_perm: values.dashboard_perm === true ? 1 : 0,
        statistics_perm: values.statistics_perm === true ? 1 : 0,
        brands_perm: values.brands_perm === true ? 1 : 0,
        ads_perm: values.ads_perm === true ? 1 : 0,
        ad_groups_perm: values.ad_groups_perm === true ? 1 : 0,
        sign_up_settings_perm: values.sign_up_settings_perm === true ? 1 : 0,
        shortcuts_perm: values.shortcuts_perm === true ? 1 : 0,
        users_perm: values.users_perm === true ? 1 : 0,
        orders_perm: values.orders_perm === true ? 1 : 0,
        contact_messages_perm: values.contact_messages_perm === true ? 1 : 0,
        influencers_perm: values.influencers_perm === true ? 1 : 0,
        partners_perm: values.partners_perm === true ? 1 : 0,
        admins_perm: values.admins_perm === true ? 1 : 0,
        announcements_perm: values.announcements_perm === true ? 1 : 0,
        payments_perm: values.payments_perm === true ? 1 : 0
      };

      axios.post(`${process.env.REACT_APP_API_URL}api/alcago/admin/signup`, data)
        .catch((err) => {
          console.log("err: " + err);
          setAlertMessage("Bir hata oluştu");
          setTimeout(() => {
            setAlertMessage(null)
          }, 3000);
        })
        .then((response) => {
          if (response && response.data.success === false && response.data.status === 406 && response.data.message === "Email already exists.") {
            setAlertMessage("Bu e-posta adresi zaten kullanılıyor.");
            setTimeout(() => {
              setAlertMessage(null)
            }, 3000);
          }
          if (response && response.data.success === true && response.data.status === 201) {
            handleClose();
          }
        });
    } else if (type === "edit") {
      const data = {
        admin: {
          name: values.name,
          surname: values.surname,
          dashboard_perm: values.dashboard_perm === true ? 1 : 0,
          statistics_perm: values.statistics_perm === true ? 1 : 0,
          brands_perm: values.brands_perm === true ? 1 : 0,
          ads_perm: values.ads_perm === true ? 1 : 0,
          ad_groups_perm: values.ad_groups_perm === true ? 1 : 0,
          sign_up_settings_perm: values.sign_up_settings_perm === true ? 1 : 0,
          shortcuts_perm: values.shortcuts_perm === true ? 1 : 0,
          users_perm: values.users_perm === true ? 1 : 0,
          orders_perm: values.orders_perm === true ? 1 : 0,
          contact_messages_perm: values.contact_messages_perm === true ? 1 : 0,
          influencers_perm: values.influencers_perm === true ? 1 : 0,
          partners_perm: values.partners_perm === true ? 1 : 0,
          admins_perm: values.admins_perm === true ? 1 : 0,
          announcements_perm: values.announcements_perm === true ? 1 : 0,
          payments_perm: values.payments_perm === true ? 1 : 0
        }
      }
      axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/admin/updateAdmin?token=${token}&adminId=${admin.id}`, data)
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            handleClose();
          }
        });
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box minWidth="500px" m="20px" mb="5px" textAlign="center">
        {type === "new" &&
          <Typography variant='h2'>Yeni Yönetici</Typography>
        }
        {type === "edit" &&
          <Typography variant='h2'>Yönetici Hesabını Düzenle</Typography>
        }
        {alertMessage && <Alert severity="error" sx={{ marginTop: "20px" }}>{alertMessage}</Alert>}

      </Box>

      <DialogContent>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={adminSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="İsim"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Soyisim"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.surname}
                  name="surname"
                  error={!!touched.surname && !!errors.surname}
                  helperText={touched.surname && errors.surname}
                  sx={{ gridColumn: "span 2" }}
                />
                {type === "new" &&
                  <>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="email"
                      label="Eposta"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <br />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="password"
                      label="Şifre"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="password"
                      label="Şifre(Tekrar)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.passwordCheck}
                      name="passwordCheck"
                      error={!!touched.passwordCheck && !!errors.passwordCheck}
                      helperText={touched.passwordCheck && errors.passwordCheck}
                      sx={{ gridColumn: "span 2" }}
                    />
                  </>
                }
                {(type === "edit") &&
                  <Box gridColumn="span 2"></Box>
                }

                <Typography variant='h3' gridColumn="span 4">Yetkiler</Typography>
                <FormControlLabel
                  control={<Checkbox checked={values.dashboard_perm} />}
                  label="Anasayfa"
                  name="dashboard_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.statistics_perm} />}
                  label="Raporlar"
                  name="statistics_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.brands_perm} />}
                  label="Markalar"
                  name="brands_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.ads_perm} />}
                  label="Reklamlar"
                  name="ads_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.ad_groups_perm} />}
                  label="Reklam Grupları"
                  name="ad_groups_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.sign_up_settings_perm} />}
                  label="Kayıt Formu Ayarları"
                  name="sign_up_settings_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.shortcuts_perm} />}
                  label="Kısayollar"
                  name="shortcuts_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.users_perm} />}
                  label="Kullanıcılar"
                  name="users_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.orders_perm} />}
                  label="Siparişler"
                  name="orders_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.contact_messages_perm} />}
                  label="İletişim Mesajları"
                  name="contact_messages_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.influencers_perm} />}
                  label="Influencerlar"
                  name="influencers_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.partners_perm} />}
                  label="Partnerler"
                  name="partners_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.admins_perm} />}
                  label="Yöneticiler"
                  name="admins_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.announcements_perm} />}
                  label="Duyurular"
                  name="announcements_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

                <FormControlLabel
                  control={<Checkbox checked={values.payments_perm} />}
                  label="Ödemeler"
                  name="payments_perm"
                  onChange={handleChange}
                  sx={{ gridColumn: "span 1" }}
                />

              </Box>
              <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                <Button type="submit" color="secondary" variant="contained">
                  <Typography variant='h5'>Kaydet</Typography>
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog >
  )
}

export default AdminDialog;