import { Box, Button, Checkbox, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import axios from 'axios';
import AdDialog from './adDialog';
import BrandSelectionDialog from './brandSelectionDialog';
import { useNavigate } from 'react-router-dom';
import AdBoxForAdmin from '../../../../components/AdBoxForAdmin';
import TargetPointSettingsDialog from './targetPointSettingsDialog';

function Ads() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, token } = useContext(UserContext);
  const navigate = useNavigate();

  const [searchKey, setSearchKey] = useState("");
  const [filter, setFilter] = useState("all");
  const [dialogType, setDialogType] = useState("edit");
  const [ads, setAds] = useState([]);

  const fetchAds = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAdsWithFilter?token=${token}&filter=${filter}&searchKey=${searchKey}`)
      .catch((err) => {
        console.log("err: " + err);
        if (err.response.data.status === 404) {
          setAds([]);
        }
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setAds(response.data.ads);
        }
      });
  }

  useEffect(() => {
    fetchAds()
  }, []);


  const [openBrandSelectionDialog, setOpenBrandSelectionDialog] = useState(false);


  const handleBrandSelectionDialogClose = (ad) => {
    if (ad) {
      setDialogType("add")
      ad.is_active = 1;
      setSelectedAd(ad);
      setOpenBrandSelectionDialog(false);
      setOpenAdDialog(true);
    }
  }


  const [openTargetPointSettingsDialog, setOpenTargetPointSettingsDialog] = useState(false);


  const handleTargetPointSettingsDialogClose = () => {
    setOpenTargetPointSettingsDialog(false);
  }

  const [openAdDialog, setOpenAdDialog] = useState(false);
  const [selectedAd, setSelectedAd] = useState({});

  const handleAdDialogClose = () => {
    setOpenAdDialog(false);
    setSelectedAd({});
    fetchAds();
  }

  const handleAdSelection = (ad) => {
    setDialogType(ad.is_active ? "edit" : "approve")
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/ad/getAdDetails?token=${token}&id=${ad.id}`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          ad.bubbles = response.data.ad.bubbles;
          ad.questions = response.data.ad.questions;
          for (var i = 0; i < ad.bubbles.length; ++i) {
            ad.bubbles[i].appaer_time = ((ad.bubbles[i].appaer_time / 1000 / 60) < 10 ? ("0" + parseInt(ad.bubbles[i].appaer_time / 1000 / 60)) : parseInt(ad.bubbles[i].appaer_time / 1000 / 60)) + ":" + (((ad.bubbles[i].appaer_time / 1000) % 60) < 10 ? ("0" + parseInt((ad.bubbles[i].appaer_time / 1000) % 60)) : parseInt((ad.bubbles[i].appaer_time / 1000) % 60));
            ad.bubbles[i].show_time = ad.bubbles[i].show_time / 1000;
          }
          setSelectedAd(ad);
          setOpenAdDialog(true);
        }
      });

  }

  return (
    <Box m="20px 0 20px 20px">
      {selectedAd && selectedAd.brand_id && <AdDialog open={openAdDialog} handleClose={handleAdDialogClose} type={dialogType} selectedAd={selectedAd} />}
      <BrandSelectionDialog open={openBrandSelectionDialog} handleClose={handleBrandSelectionDialogClose} />
      <TargetPointSettingsDialog open={openTargetPointSettingsDialog} handleClose={handleTargetPointSettingsDialogClose} />
      <Box ml="20px" display="flex" gap="20px">
        <Box width="20%">
          <Header title="Reklamlar" subtitle="Yeni reklam ekleyebilir, onaylanmamış reklamları onaylayabilir, mevcut reklamları düzenleyebilirsiniz." />
        </Box>
        <TextField
          id="outlined-basic"
          label="Marka Adı / Kategori"
          variant="outlined"
          onChange={(event) => {
            setSearchKey(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FormControl fullWidth variant="filled">
                  <InputLabel id="filter-label">Filtrele</InputLabel>
                  <Select
                    labelId="filter-label"
                    id="filter-select"
                    label="Age"
                    value={filter}
                    onChange={(event) => setFilter(event.target.value)}
                  >
                    <MenuItem value={"all"}>Tümü</MenuItem>
                    <MenuItem value={"active"}>Aktif</MenuItem>
                    <MenuItem value={"passive"}>Onay Bekleyen</MenuItem>
                  </Select>
                </FormControl>
                <IconButton onClick={() => fetchAds()} sx={{ marginLeft: "10px" }}>
                  <SearchOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }} />
        <Button variant='contained' color='success' sx={{ margin: "10px 0 auto 0" }} onClick={() => {
          setOpenBrandSelectionDialog(true);
        }}>
          Yeni Reklam Ekle
        </Button>

        <Button variant='contained' color='warning' sx={{ margin: "10px 0 auto 0" }} onClick={() => {
          setOpenTargetPointSettingsDialog(true);
        }}>
          Hedefleme Ayarları
        </Button>

        <Button variant='contained' color='warning' sx={{ margin: "10px 0 auto 0" }} onClick={() =>
          navigate(`/admin-panel/additional-ap-campaigns`)
        }>
          Ek AP Kampanyaları
        </Button>
      </Box>
      <Box>
        <Box mt="10px">
          {ads.map((ad) => <AdBoxForAdmin key={"ad" + ad.id} ad={ad} handleAdSelection={handleAdSelection} />)}
        </Box>
      </Box>
    </Box>

  )
}

export default Ads