import { Box, Button, useTheme } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext';
import { tokens } from '../../../../theme';
import ShortcutBox from '../../../../components/ShortcutBox';

function Shortcuts() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(UserContext);
  const [shortcuts, setShortcuts] = useState([]);

  useEffect(() => {
    fetchShortcuts()
  }, []);

  const fetchShortcuts = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/shortcut/getShortcuts?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
        setShortcuts([])
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setShortcuts([]);
          setTimeout(() => {
            setShortcuts(response.data.shortcuts);
          }, 100);

        }
      });
  }

  const handleAddition = () => {
    var newSh = Array.from(shortcuts);
    newSh.unshift({
      value: ""
    });
    setShortcuts([]);
    setTimeout(() => {
      setShortcuts(newSh);
    }, 100);
  }

  const deleteAdditionBox = () => {
    var newSh = Array.from(shortcuts);
    newSh.shift();
    setShortcuts([]);
    setTimeout(() => {
      setShortcuts(newSh);
    }, 100);
  }

  return (
    <Box m="20px">
      <Box display="flex" gap="20px" alignItems="center">
        <Header title="Kısayollar" subtitle="Uygulamada anasayfada bulunan kısayolları buradan görüntüleyebilir, düzenleyebilir ve silebilirsiniz." />
      </Box>
      <Button variant='contained' color='success' sx={{ ml: "20px" }} onClick={handleAddition}>
        Yeni Ekle
      </Button>
      <Box>
        {shortcuts.map((shortcut, i) =>
          <Box key={i} display="inline-block" p="20px" mr="10px" mt="10px" sx={{ borderRadius: "20px" }}>
            <ShortcutBox shortcut={shortcut} fetchShortcuts={fetchShortcuts} deleteAddition={deleteAdditionBox} />
          </Box>
        )}
      </Box>
    </Box >
  )
}

export default Shortcuts