import { IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { tokens } from '../theme';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import AlertDialogSlide from './AlertDialogSlide';
import { useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

function WithdrawRequestBox({ withdraw, fetchWithdrawRequests, isAdmin }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);

    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertDialogClose = () => {
        setOpenAlertDialog(false);
    }
    const clickPositiveAnswer = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/withdraw/deleteWithdraw?token=${token}&withdrawId=${withdraw.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data && response.data.success && response.data.status === 201) {
                    fetchWithdrawRequests();
                }
            });
    }
    const clickNegativeAnswer = () => { }

    const [openApproveAlertDialog, setOpenApproveAlertDialog] = useState(false);
    const handleApproveAlertDialogClose = () => {
        setOpenApproveAlertDialog(false);
    }
    const clickApproveAlertPositiveAnswer = () => {
        axios.post(`${process.env.REACT_APP_API_URL}api/alcago/withdraw/approveWithdraw?token=${token}&withdrawId=${withdraw.id}`, { withdraw: withdraw })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data && response.data.success && response.data.status === 201) {
                    fetchWithdrawRequests();
                }
            });
    }
    const clickApproveAlertNegativeAnswer = () => { }
    return (
        <Box display="inline-block" mr="20px" mt="20px" sx={{
            background: isAdmin ? colors.primary[400] : theme.palette.mode === "dark" ? colors.primary[500] : colors.primary[900], borderRadius: "20px"
        }}>
            <AlertDialogSlide
                open={openAlertDialog} handleClose={handleAlertDialogClose}
                clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer}
                positiveAnswer={"Talebi iptal et"} negativeAnswer={"Vazgeç"}
                title={"Çekim talebini iptal etmek istediğinize emin misiniz?"}
                description={"Daha sonra tekrar talep oluşturabilirsiniz."}
            />
            <AlertDialogSlide
                open={openApproveAlertDialog} handleClose={handleApproveAlertDialogClose}
                clickPositiveAnswer={clickApproveAlertPositiveAnswer} clickNegativeAnswer={clickApproveAlertNegativeAnswer}
                positiveAnswer={"Talebi onayla"} negativeAnswer={"Vazgeç"}
                title={"Çekim talebini onaylamak istediğinize emin misiniz?"}
                description={"Ödemeyi yaptıysanız talebi onaylayınız."}
            />
            <Box display="flex">
                <Box p="20px">
                    <Box display="flex" alignItems="center" gap="10px">
                        <img
                            alt="profile-user"
                            src={withdraw.profile_photo_link}
                            width="55px"
                            height="55px"
                            style={{ cursor: "pointer", borderRadius: "50%" }} />
                        <Box>

                            <Box display="flex" gap="10px" alignItems="center">
                                <Tooltip title={withdraw.owner_name + " " + withdraw.owner_surname}>
                                    <Typography variant='h3' maxWidth="160px" noWrap>{withdraw.owner_name + " " + withdraw.owner_surname}</Typography>
                                </Tooltip>
                            </Box>
                            <Box display="flex" gap="10px" alignItems="center" mt="5px">
                                <Box padding="1px 10px" sx={{ borderRadius: "10px" }}>
                                    <Typography variant='h6'>{new Date(withdraw.created_date).toLocaleString()}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box height="1px" width="%100" mr="20px" my="15px" sx={{ background: isAdmin ? "gold" : theme.palette.mode === "dark" ? "gold" : "black" }}></Box>
                    <Box mt="10px">
                        <Typography variant='h4' color="success">{withdraw.amount}₺</Typography>
                        <Typography variant='h6'>IBAN: {withdraw.iban}</Typography>
                        <Typography variant='h6'>Banka: {withdraw.bank}</Typography>
                        <Typography variant='h6'>Şirket/Şahıs Adı: {withdraw.company_name}</Typography>
                        <Typography variant='h6'>Vergi/TC no: {withdraw.tax_no}</Typography>
                        <Typography variant='h6'>Vergi Dairesi: {withdraw.tax_office}</Typography>
                        <Typography variant='h6'>Adres: {withdraw.address}</Typography>
                    </Box>
                </Box>
                {isAdmin && withdraw.status === 0 &&
                    <Box display="flex" alignItems="center" sx={{
                        background: theme.palette.mode === "dark" ? colors.primary[600] : colors.grey[900], borderRadius: "0 20px 20px 0"
                    }}>
                        <Box px="5px">
                            <IconButton onClick={() => { setOpenApproveAlertDialog(true) }}>
                                <CheckBoxOutlinedIcon sx={{ height: "30px", width: "30px" }} />
                            </IconButton>
                            <br />
                            <IconButton onClick={() => { setOpenAlertDialog(true) }}>
                                <DeleteOutlinedIcon color='error' sx={{ height: "30px", width: "30px" }} />
                            </IconButton>
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default WithdrawRequestBox