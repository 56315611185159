import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './dashboard';
import Sidebar from '../../global/Sidebar';
import Topbar from '../../global/Topbar';
import RefCodes from './refCodes';
import Announcements from '../../global/Announcements';
import Payments from '../../global/Payments';
import EarningStatistics from './statistics/earningStatistics';

function PartnerPanel() {

    return (
        <div className="app">
            <Sidebar />

            <main className="content" style={{ marginLeft: "100px" }} >
                <Topbar />
                <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route exact path="/ref-codes" element={<RefCodes />} />
                    <Route exact path="/announcements" element={<Announcements announcementType="partner" />} />
                    <Route exact path="/payments" element={<Payments paymentType="partner" />} />
                    <Route exact path="/statistics/earnings" element={<EarningStatistics />} />
                </Routes>
            </main>
        </div>
    )
}

export default PartnerPanel;