exports.formatNumber = (number) => {
    var numberString = String(number);

    numberString = numberString.replace(',', '.');

    var parts = numberString.split('.');
    var integerPart = parts[0];
    var decimalPart = parts[1] || '';

    var formattedNumber = '';
    var integerLength = integerPart.length;

    for (var i = 0; i < integerLength; i++) {
        formattedNumber += integerPart[i];
        if ((integerLength - i - 1) % 3 === 0 && i !== integerLength - 1) {
            formattedNumber += '.';
        }
    }

    // Ondalık kısmı tekrar ekleyin
    if (decimalPart !== '') {
        formattedNumber += ',' + decimalPart;
    }

    return formattedNumber;
}