import { Box, Typography, useTheme } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import Header from '../../../../components/Header'
import StatCard from '../../../../components/StatCard'
import { UserContext } from '../../../../context/UserContext'
import { tokens } from '../../../../theme'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PercentIcon from '@mui/icons-material/Percent';
import axios from 'axios'
import NumberFormatter from '../../../global/NumberFormatter';

function Dashboard() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, token } = useContext(UserContext);
  const [userStatistics, setUserStatistics] = useState({
    today: 0,
    thisMonth: 0,
    thisYear: 0,
    total: 0
  });
  const [earningStatistics, setEarningStatistics] = useState({
    today: 0,
    thisMonth: 0,
    thisYear: 0,
    total: 0
  });
  const [bestBrandStatistics, setBestBrandStatistics] = useState({
    today: null,
    thisMonth: null,
    thisYear: null,
    total: null
  });

  const getUserStatistics = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencerPartner/getUserStatistics?token=${token}`)
      .catch((err) => {
        console.log("err: " + err);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setUserStatistics(response.data.userStatistics);
        }
      });
  }

  const getEarningStatistics = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getDashboardEarningStatisticsWithField?token=${token}&field=influencer_partner`)
      .catch((err) => {
        console.log("err: " + err);
        setEarningStatistics({
          today: 0,
          thisMonth: 0,
          thisYear: 0,
          total: 0
        });
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setEarningStatistics(response.data.statistics);
        }
      });
  }

  const getBestBrandStatistics = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getDashboardBestBrandStatistics?token=${token}&field=influencer_partner`)
      .catch((err) => {
        console.log("err: " + err);
        setBestBrandStatistics({
          today: null,
          thisMonth: null,
          thisYear: null,
          total: null
        });
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setBestBrandStatistics(response.data.statistics);
        }
      });
  }

  useEffect(() => {
    getUserStatistics();
    getEarningStatistics();
    getBestBrandStatistics();
  }, []);

  return (
    <Box mt="20px">
      <Box ml="20px" display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Panel" subtitle="Panele hoşgeldiniz" />
      </Box>
      <Box display="grid" gap="20px" gridTemplateColumns="repeat(6, minmax(0, 1fr))">
        <Typography variant='h4' ml="25px" sx={{ gridColumn: "span 6" }}>Kazanç İstatistikleri</Typography>
        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(earningStatistics.today.toFixed(2)) + " ₺"} content="Bugün" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(earningStatistics.thisMonth.toFixed(2)) + " ₺"} content="Bu ay" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(earningStatistics.thisYear.toFixed(2)) + " ₺"} content="Bu yıl" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(earningStatistics.total.toFixed(2)) + " ₺"} content="Toplam" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<PercentIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(user.commission_percentage) + ""} content="Komisyon Yüzdesi" sx={{ gridColumn: "span 1" }} />
        <Typography variant='h4' ml="25px" mt="20px" sx={{ gridColumn: "span 6" }}>Marka İstatistikleri</Typography>
        <StatCard icon={<PersonIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(userStatistics.today) * + ""} content="Bugün" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<PersonIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(userStatistics.thisMonth) * + ""} content="Bu ay" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<PersonIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(userStatistics.thisYear) * + ""} content="Bu yıl" sx={{ gridColumn: "span 1" }} />
        <StatCard icon={<PersonIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(userStatistics.total) * + ""} content="Toplam" sx={{ gridColumn: "span 1" }} />
        <Typography variant='h4' ml="25px" mt="20px" sx={{ gridColumn: "span 6" }}>En Çok Kazandıran Markalar</Typography>
        <StatCard icon={<Box textAlign="center">{bestBrandStatistics.today && <img
          alt="profile-user"
          src={bestBrandStatistics.today.logo_link}
          width="40px"
          height="40px"
          style={{ margin: "auto 0", cursor: "pointer", borderRadius: "50%" }} />}<Typography>{bestBrandStatistics.today ? bestBrandStatistics.today.brand_name : "-"}</Typography></Box>} count={(bestBrandStatistics.today ? (bestBrandStatistics.today.total_price).toFixed(2) : "0") + " ₺"} content="Bugün" sx={{ gridColumn: "span 1" }} />

        <StatCard icon={<Box textAlign="center">{bestBrandStatistics.thisMonth && <img
          alt="profile-user"
          src={bestBrandStatistics.thisMonth.logo_link}
          width="40px"
          height="40px"
          style={{ margin: "auto 0", cursor: "pointer", borderRadius: "50%" }} />}<Typography>{bestBrandStatistics.thisMonth ? bestBrandStatistics.thisMonth.brand_name : "-"}</Typography></Box>} count={(bestBrandStatistics.thisMonth ? (bestBrandStatistics.thisMonth.total_price).toFixed(2) : "0") + " ₺"} content="Bu ay" sx={{ gridColumn: "span 1" }} />

        <StatCard icon={<Box textAlign="center">{bestBrandStatistics.thisYear && <img
          alt="profile-user"
          src={bestBrandStatistics.thisYear.logo_link}
          width="40px"
          height="40px"
          style={{ margin: "auto 0", cursor: "pointer", borderRadius: "50%" }} />}<Typography>{bestBrandStatistics.thisYear ? bestBrandStatistics.thisYear.brand_name : "-"}</Typography></Box>} count={(bestBrandStatistics.thisYear ? (bestBrandStatistics.thisYear.total_price).toFixed(2) : "0") + " ₺"} content="Bu yıl" sx={{ gridColumn: "span 1" }} />

        <StatCard icon={<Box textAlign="center">{bestBrandStatistics.total && <img
          alt="profile-user"
          src={bestBrandStatistics.total.logo_link}
          width="40px"
          height="40px"
          style={{ margin: "auto 0", cursor: "pointer", borderRadius: "50%" }} />}<Typography>{bestBrandStatistics.total ? bestBrandStatistics.total.brand_name : "-"}</Typography></Box>} count={(bestBrandStatistics.total ? (bestBrandStatistics.total.total_price).toFixed(2) : "0") + " ₺"} content="Tüm Zamanlar" sx={{ gridColumn: "span 1" }} />
      </Box>
    </Box>
  )
}

export default Dashboard;