import { IconButton, Slide, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import axios from 'axios';
import AlertDialogSlide from './AlertDialogSlide';
import QuestionDialog from '../scenes/panels/brandPanel/questions/questionDialog';
import QuestionDialogGeneral from '../scenes/panels/adminPanel/questions/questionDialog';

function QuestionBox({ question, fetchQuestions }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const type = (!question.brand_id && !question.ad_id) ? "general" : (question.ad_id ? "ad" : "brand");

    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertDialogClose = () => {
        setOpenAlertDialog(false);
    }
    const clickPositiveAnswer = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/${type === "general" ? "ad/deleteQuestion" : "brand/deleteBrandQuestion"}?token=${token}&questionId=${question.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data && response.data.success && response.data.status === 201) {
                    fetchQuestions();
                }
            });
    }
    const clickNegativeAnswer = () => { }

    const [openDialog, setOpenDialog] = useState(false);
    const handleDialogOpen = () => {
        setOpenDialog(true);
    }
    const handleDialogClose = () => {
        setOpenDialog(false);
        fetchQuestions();
    }

    return (
        <Box m="10px" mb="20px" p="20px" maxWidth="800px" display={type === "ad" ? "inline-block" : ""}
            sx={{
                background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500],
                borderRadius: "20px"
            }}
        >
            <AlertDialogSlide
                open={openAlertDialog} handleClose={handleAlertDialogClose}
                clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer}
                positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                title={"Bu soruyu silmek istediğinize emin misiniz?"}
                description={"Bu soru ile ilişkili tüm veriler silinecektir."}
            />
            {type === "brand" &&
                <QuestionDialog open={openDialog} handleClose={handleDialogClose} type="edit" question={question} />
            }
            {type === "general" &&
                <QuestionDialogGeneral open={openDialog} handleClose={handleDialogClose} type="edit" question={question} />
            }
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <Typography variant='h3'>{question.question}</Typography>
                    {type !== "brand" &&
                        <Box display="flex">
                            <Typography variant='h7'><b>{question.answer_count}</b> defa soruldu.</Typography>
                            {type === "general" &&
                                <Typography variant='h7' sx={{ color: question.targetable === 1 ? "green" : "red", ml: "10px" }}>{(question.targetable === 1 ? "Hedeflenebilir" : "Hedeflenemez")}</Typography>
                            }
                            {question.user_answer &&
                                <Typography variant='h7' sx={{ color: (question.correct_answer && question.user_answer === question.correct_answer) ? "green" : "red", ml: "10px" }}>Kullanıcı "{question.user_answer}" cevabını verdi.</Typography>
                            }
                        </Box>
                    }
                </Box>
                {type !== "ad" &&
                    <Box display="flex">
                        <Box>
                            <IconButton onClick={() => { handleDialogOpen() }}>
                                <EditOutlinedIcon sx={{ height: "25px", width: "25px" }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton onClick={() => { setOpenAlertDialog(true) }}>
                                <DeleteOutlinedIcon sx={{ height: "25px", width: "25px" }} />
                            </IconButton>
                        </Box>
                    </Box>
                }
            </Box>

            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Box display="flex" gap="10px" mt="20px">
                <Box padding="1px 10px" sx={{ background: type !== "general" ? (question.correct_answer === question.answer1 ? colors.greenAccent[600] : colors.redAccent[600]) : colors.blueAccent[700], borderRadius: "10px" }}>
                    <Typography variant='h6'>{question.answer1 + ((type === "general" || type === "ad") ? (" - " + question.answer1_count + " (%" + (question.answer1_count * 100 / question.answer_count).toFixed(2) + ")") : "").replace("NaN", "0")}</Typography>
                </Box>
                <Box padding="1px 10px" sx={{ background: type !== "general" ? (question.correct_answer === question.answer2 ? colors.greenAccent[600] : colors.redAccent[600]) : colors.blueAccent[700], borderRadius: "10px" }}>
                    <Typography variant='h6'>{question.answer2 + ((type === "general" || type === "ad") ? (" - " + question.answer2_count + " (%" + (question.answer2_count * 100 / question.answer_count).toFixed(2) + ")") : "").replace("NaN", "0")}</Typography>
                </Box>
                <Box padding="1px 10px" sx={{ background: type !== "general" ? (question.correct_answer === question.answer3 ? colors.greenAccent[600] : colors.redAccent[600]) : colors.blueAccent[700], borderRadius: "10px" }}>
                    <Typography variant='h6'>{question.answer3 + ((type === "general" || type === "ad") ? (" - " + question.answer3_count + " (%" + (question.answer3_count * 100 / question.answer_count).toFixed(2) + ")") : "").replace("NaN", "0")}</Typography>
                </Box>
            </Box>
        </Box >
    )
}

export default QuestionBox



/**
 * import { IconButton, Slide, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import axios from 'axios';
import AlertDialogSlide from './AlertDialogSlide';
import QuestionDialog from '../scenes/panels/brandPanel/questions/questionDialog';
import QuestionDialogGeneral from '../scenes/panels/adminPanel/questions/questionDialog';

function QuestionBox({ question, fetchQuestions }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);

    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertDialogClose = () => {
        setOpenAlertDialog(false);
    }
    const clickPositiveAnswer = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}api/alcago/ad/deleteQuestion?token=${token}&questionId=${question.id}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data && response.data.success && response.data.status === 201) {
                    fetchQuestions();
                }
            });
    }
    const clickNegativeAnswer = () => { }

    const [openDialog, setOpenDialog] = useState(false);
    const handleDialogOpen = () => {
        setOpenDialog(true);
    }
    const handleDialogClose = () => {
        setOpenDialog(false);
        fetchQuestions();
    }

    return (
        <Box m="10px" mb="20px" p="20px" maxWidth="800px"
            sx={{
                background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500],
                borderRadius: "20px"
            }}
        >
            <AlertDialogSlide
                open={openAlertDialog} handleClose={handleAlertDialogClose}
                clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer}
                positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                title={"Bu soruyu silmek istediğinize emin misiniz?"}
                description={"Bu soru ile ilişkili tüm veriler silinecektir."}
            />
            <QuestionDialog open={openDialog} handleClose={handleDialogClose} type="edit" question={question} />
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <Typography variant='h4'>{question.question}</Typography>
                    <Typography variant='h6' sx={{ color: question.targetable === 1 ? "green" : "red" }}>{question.targetable === 1 ? "Hedeflenebilir" : "Hedeflenemez"}</Typography>
                </Box>
                <Box display="flex">
                    <Box>
                        <IconButton onClick={() => { handleDialogOpen() }}>
                            <EditOutlinedIcon sx={{ height: "25px", width: "25px" }} />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton onClick={() => { setOpenAlertDialog(true) }}>
                            <DeleteOutlinedIcon sx={{ height: "25px", width: "25px" }} />
                        </IconButton>
                    </Box>
                </Box>
            </Box>

            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Box display="flex" gap="10px" mt="20px">
                <Box padding="1px 10px" sx={{ background: colors.blueAccent[700], borderRadius: "10px" }}>
                    <Typography variant='h6'>{question.answer1}</Typography>
                </Box>
                <Box padding="1px 10px" sx={{ background: colors.blueAccent[700], borderRadius: "10px" }}>
                    <Typography variant='h6'>{question.answer2}</Typography>
                </Box>
                <Box padding="1px 10px" sx={{ background: colors.blueAccent[700], borderRadius: "10px" }}>
                    <Typography variant='h6'>{question.answer3}</Typography>
                </Box>
            </Box>
        </Box >
    )
}

export default QuestionBox
 * 
 */