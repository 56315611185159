import { Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { tokens } from '../theme';
import StatCard from './StatCard';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

function PartnerRefCodeBox({ refCode, field }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { token } = useContext(UserContext);
    const [earning, setEarning] = useState(0);
    const [best, setBest] = useState(field === "partner" ?
        {
            brand_name: "",
            total_price: null,
            logo_link: "",
        } : {
            name: "",
            total_price: null,
            profile_photo_link: "",
        });

    const getEarningStatistics = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getDashboardEarningStatisticsWithField?token=${token}&field=${field}&refId=${refCode.id}`)
            .catch((err) => {
                console.log("err: " + err);
                setEarning(0);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setEarning(response.data.statistics.total);
                }
            });
    }

    const getBestStatistics = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getDashboardBest${field === "partner" ? "Brand" : "Influencer"}Statistics?token=${token}&field=${field}&refId=${refCode.id}`)
            .catch((err) => {
                console.log("err: " + err);
                setBest(field === "partner" ?
                    {
                        brand_name: "",
                        total_price: null,
                        logo_link: "",
                    } : {
                        name: "",
                        total_price: null,
                        profile_photo_link: "",
                    });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setBest(response.data.statistics.total);
                }
            });
    }

    useEffect(() => {
        getBestStatistics();
        getEarningStatistics();
    }, [])

    return (
        <Box mb="20px" p="20px" minWidth="250px" sx={{ background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb="15px">
                <Typography variant='h4'>{refCode.ref_code}</Typography>
                <Typography variant='h6'>{new Date(refCode.created_date).toLocaleDateString()}</Typography>
            </Box>
            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Box display="flex" mt="20px">
                {field === "partner" && <StatCard icon={<PersonIcon sx={{ scale: "1.5" }} />} count={refCode.brandCount + ""} content={"Marka"} />}
                {field === "influencer_partner" && <StatCard icon={<PersonIcon sx={{ scale: "1.5" }} />} count={refCode.influencerCount + ""} content={"Influencer"} />}
                <StatCard icon={<Box textAlign="center">{best && ((field === "partner" && best.logo_link !== "") || (field === "influencer_partner" && best.profile_photo_link !== "")) && <img
                    alt="profile-user"
                    src={field === "partner" ? best.logo_link : best.profile_photo_link}
                    width="40px"
                    height="40px"
                    style={{ margin: "auto 0", cursor: "pointer", borderRadius: "50%" }} />}<Typography>{best && best.name !== "" ? best.name : best && best.brand_name !== "" ? best.brand_name : "-"}</Typography></Box>} count={(best && best.total_price ? best.total_price.toFixed(2) : "0") + " ₺"} content="En Çok Kazandıran" sx={{ gridColumn: "span 1" }} />
                <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5" }} />} count={earning.toFixed(2) + " ₺"} content="Toplam Kazanç" />
            </Box>
        </Box>
    )
}

export default PartnerRefCodeBox