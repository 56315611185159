import { Alert, AlertTitle, Box, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useContext } from 'react'
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext'
import { tokens } from '../../../../theme'
import axios from 'axios'
import { useEffect } from 'react'
import StatCard from '../../../../components/StatCard'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import { useState } from 'react'
import BrandBox from '../../../../components/BrandBox'
import InfluencerBox from '../../../../components/InfluencerBox'
import RegisterDialog from '../../../loginPages/brandLogin/registerDialog'
import InfluencerRegisterDialog from '../../../loginPages/influencerLogin/registerDialog'
import PartnerRegisterDialog from '../../../loginPages/partnerLogin/registerDialog'
import InfluencerPartnerRegisterDialog from '../../../loginPages/influencerPartnerLogin/registerDialog'
import PartnerBox from '../../../../components/PartnerBox'
import NumberFormatter from '../../../global/NumberFormatter';

function Dashboard() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user, token } = useContext(UserContext);
    const [brands, setBrands] = useState([]);
    const [influencers, setInfluencers] = useState([]);
    const [partners, setPartners] = useState([]);
    const [influencerPartners, setInfluencerPartners] = useState([]);
    const [usedCouponCount, setUsedCouponCount] = useState(0);
    const [userSignupStatistics, setUserSignupStatistics] = useState({
        today: " ",
        thisMonth: " ",
        thisYear: " ",
        total: " "
    });
    const [earningStatistics, setEarningStatistics] = useState({
        today: 0,
        thisMonth: 0,
        thisYear: 0,
        total: 0
    });

    const fetchUsedCouponCount = () => {
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/coupon/getGivenCouponsStatistics?token=${token}`, { filter: { status: 1 } })
            .catch((err) => {
                console.log("err: " + err);
                setUsedCouponCount(0);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    let c = 0;
                    response.data.coupons.forEach(({ coupon_count }) => c += coupon_count);
                    setUsedCouponCount(c);
                }
            });
    }

    const fetchUnActiveBrands = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getUnactiveBrands?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                if (err.response.data.status === 404) {
                    setBrands([]);
                }
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setBrands(response.data.brands);
                }
            });
    }

    const fetchUnActiveInfluencers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencer/getUnactiveInfluencers?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                if (err.response.data.status === 404) {
                    setInfluencers([]);
                }
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setInfluencers(response.data.influencers);
                }
            });
    }

    const fetchUnActivePartners = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/partner/getUnactivePartners?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                if (err.response.data.status === 404) {
                    setPartners([]);
                }
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setPartners(response.data.partners);
                }
            });
    }

    const fetchUnActiveInfluencerPartners = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/influencerPartner/getUnactiveInfluencerPartners?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                if (err.response.data.status === 404) {
                    setInfluencerPartners([]);
                }
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setInfluencerPartners(response.data.influencerPartners);
                }
            });
    }

    const fetchUserSignupStatistics = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/user/getSignupStatistics?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setUserSignupStatistics({
                    today: " ",
                    thisMonth: " ",
                    thisYear: " ",
                    total: " "
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUserSignupStatistics(response.data.signupStatistics);
                }
            });
    }

    const fetchEarningStatistics = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/order/getDashboardEarningStatistics?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setEarningStatistics({
                    today: " ",
                    thisMonth: " ",
                    thisYear: " ",
                    total: " "
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setEarningStatistics(response.data.statistics);
                }
            });
    }

    useEffect(() => {
        fetchEarningStatistics();
        fetchUserSignupStatistics();
        fetchUnActiveBrands();
        fetchUnActiveInfluencers();
        fetchUnActivePartners();
        fetchUnActiveInfluencerPartners();
        fetchUsedCouponCount();
    }, []);

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState({});

    const handleDialogClose = () => {
        setSelectedBrand({});
        setOpenDialog(false);
        fetchUnActiveBrands();
    };

    const onDetailsButtonClick = (brand) => {
        setSelectedBrand(brand);
        setOpenDialog(true);
    }

    const [openInfluencerDialog, setOpenInfluencerDialog] = useState(false);
    const [selectedInfluencer, setSelectedInfluencer] = useState({});

    const handleInfluencerDialogClose = () => {
        setSelectedInfluencer({});
        setOpenInfluencerDialog(false);
        fetchUnActiveInfluencers();
    };

    const onInfluencerDetailsButtonClick = (influencer) => {
        setSelectedInfluencer(influencer);
        setOpenInfluencerDialog(true);
    }

    const [openPartnerDialog, setOpenPartnerDialog] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState({});

    const handlePartnerDialogClose = () => {
        setSelectedPartner({});
        setOpenPartnerDialog(false);
        fetchUnActivePartners();
    };

    const onPartnerDetailsButtonClick = (partner) => {
        setSelectedPartner(partner);
        setOpenPartnerDialog(true);
    }

    const [openInfluencerPartnerDialog, setOpenInfluencerPartnerDialog] = useState(false);
    const [selectedInfluencerPartner, setSelectedInfluencerPartner] = useState({});

    const handleInfluencerPartnerDialogClose = () => {
        setSelectedInfluencerPartner({});
        setOpenInfluencerPartnerDialog(false);
        fetchUnActiveInfluencerPartners();
    };

    const onInfluencerPartnerDetailsButtonClick = (influencerPartner) => {
        setSelectedInfluencerPartner(influencerPartner);
        setOpenInfluencerPartnerDialog(true);
    }

    return (
        <Box mt="20px">
            {selectedBrand && selectedBrand.id && <RegisterDialog open={openDialog} handleClose={handleDialogClose} type="approve" brand={selectedBrand} />}
            {selectedInfluencer && selectedInfluencer.id && <InfluencerRegisterDialog open={openInfluencerDialog} handleClose={handleInfluencerDialogClose} type="approve" influencer={selectedInfluencer} />}
            {selectedPartner && selectedPartner.id && <PartnerRegisterDialog open={openPartnerDialog} handleClose={handlePartnerDialogClose} type="approve" partner={selectedPartner} />}
            {selectedInfluencerPartner && selectedInfluencerPartner.id && <InfluencerPartnerRegisterDialog open={openInfluencerPartnerDialog} handleClose={handleInfluencerPartnerDialogClose} type="approve" influencerPartner={selectedInfluencerPartner} />}
            <Box ml="20px" display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Panel" subtitle="Panele hoş geldiniz" />
            </Box>
            <Box display="grid" gap="20px" gridTemplateColumns="repeat(6, minmax(0, 1fr))">
                <Typography variant='h4' ml="25px" sx={{ gridColumn: "span 6" }}>Kazanç İstatistikleri</Typography>
                <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5" }} />} count={NumberFormatter.formatNumber(earningStatistics.today.toFixed(2)) + " ₺"} content="Bugün" sx={{ gridColumn: "span 1" }} />
                <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5" }} />} count={NumberFormatter.formatNumber(earningStatistics.thisMonth.toFixed(2)) + " ₺"} content="Bu ay" sx={{ gridColumn: "span 1" }} />
                <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5" }} />} count={NumberFormatter.formatNumber(earningStatistics.thisYear.toFixed(2)) + " ₺"} content="Bu yıl" sx={{ gridColumn: "span 1" }} />
                <StatCard icon={<PaidOutlinedIcon sx={{ scale: "1.5" }} />} count={NumberFormatter.formatNumber(earningStatistics.total.toFixed(2)) + " ₺"} content="Toplam" sx={{ gridColumn: "span 1" }} />
                <StatCard icon={<CardMembershipOutlinedIcon sx={{ scale: "1.5" }} />} count={NumberFormatter.formatNumber(usedCouponCount) + ""} content="Kullanılan Kupon" sx={{ gridColumn: "span 1" }} />
                <Typography variant='h4' ml="25px" mt="20px" sx={{ gridColumn: "span 6" }}>Kullanıcı Kayıt İstatistikleri</Typography>
                <StatCard icon={<HowToRegIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(userSignupStatistics.today)} content="Bugün" sx={{ gridColumn: "span 1" }} />
                <StatCard icon={<HowToRegIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(userSignupStatistics.thisMonth)} content="Bu ay" sx={{ gridColumn: "span 1" }} />
                <StatCard icon={<HowToRegIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(userSignupStatistics.thisYear)} content="Bu yıl" sx={{ gridColumn: "span 1" }} />
                <StatCard icon={<HowToRegIcon sx={{ scale: "1.5", ml: "10px" }} />} count={NumberFormatter.formatNumber(userSignupStatistics.total)} content="Toplam" sx={{ gridColumn: "span 1" }} />
                <Typography variant='h4' ml="25px" mt="20px" sx={{ gridColumn: "span 6" }}>Alcago Ads Başvuruları</Typography>
                {brands.length <= 0 &&
                    <Alert severity="success" sx={{ ml: "35px", gridColumn: "span 5", mb: "20px" }}>
                        <AlertTitle>Başarılı</AlertTitle>
                        Kontrol edilmemiş alcago ads başvurusu <strong>bulunmuyor</strong>.
                    </Alert>
                }
                {brands.length > 0 && <>
                    <Box ml="35px" sx={{ gridColumn: "span 6", paddingRight: "30px" }}>
                        {brands.map((brand) => {
                            return (
                                <BrandBox key={brand.id} brand={brand} onDetailsButtonClick={onDetailsButtonClick} />
                            )
                        })}
                    </Box>
                </>}
                <Typography variant='h4' ml="25px" sx={{ gridColumn: "span 6" }}>Catcher Inviter Başvuruları</Typography>
                {influencers.length <= 0 &&
                    <Alert severity="success" sx={{ ml: "35px", gridColumn: "span 5", mb: "20px" }}>
                        <AlertTitle>Başarılı</AlertTitle>
                        Kontrol edilmemiş catcher inviter başvurusu <strong>bulunmuyor</strong>.
                    </Alert>
                }
                {influencers.length > 0 && <>
                    <Box ml="35px" sx={{ gridColumn: "span 6", paddingRight: "30px" }}>
                        {influencers.map((influencer) => {
                            return (
                                <InfluencerBox key={influencer.id} influencer={influencer} onInfluencerDetailsButtonClick={onInfluencerDetailsButtonClick} />
                            )
                        })}
                    </Box>
                </>}

                <Typography variant='h4' ml="25px" sx={{ gridColumn: "span 6" }}>Brand Inviter Başvuruları</Typography>
                {partners.length <= 0 &&
                    <Alert severity="success" sx={{ ml: "35px", gridColumn: "span 5", mb: "20px" }}>
                        <AlertTitle>Başarılı</AlertTitle>
                        Kontrol edilmemiş brand inviter başvurusu <strong>bulunmuyor</strong>.
                    </Alert>
                }
                {partners.length > 0 && <>
                    <Box ml="35px" sx={{ gridColumn: "span 6", paddingRight: "30px" }}>
                        {partners.map((partner) => {
                            return (
                                <PartnerBox key={partner.id} partner={partner} onPartnerDetailsButtonClick={onPartnerDetailsButtonClick} />
                            )
                        })}
                    </Box>
                </>}

                <Typography variant='h4' ml="25px" sx={{ gridColumn: "span 6" }}>Influencer Inviter Başvuruları</Typography>
                {influencerPartners.length <= 0 &&
                    <Alert severity="success" sx={{ ml: "35px", gridColumn: "span 5", mb: "20px" }}>
                        <AlertTitle>Başarılı</AlertTitle>
                        Kontrol edilmemiş influencer inviter başvurusu <strong>bulunmuyor</strong>.
                    </Alert>
                }
                {influencerPartners.length > 0 && <>
                    <Box ml="35px" sx={{ gridColumn: "span 6", paddingRight: "30px" }}>
                        {influencerPartners.map((influencerPartner) => {
                            return (
                                <PartnerBox key={influencerPartner.id} partner={influencerPartner} onPartnerDetailsButtonClick={onInfluencerPartnerDetailsButtonClick} />
                            )
                        })}
                    </Box>
                </>}
            </Box>
        </Box>
    )
}

export default Dashboard;