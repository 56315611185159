import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography, useTheme } from '@mui/material'
import React, { useContext, useState, useEffect } from 'react'
import Header from '../../components/Header'
import { UserContext } from '../../context/UserContext'
import { tokens } from '../../theme'
import axios from 'axios'
import { Formik } from 'formik'
import * as yup from "yup";
import AlertDialogSlide from '../../components/AlertDialogSlide'
import WithdrawRequestBox from '../../components/WithdrawRequestBox'


function Payments({ paymentType }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token, user, setUser } = useContext(UserContext);
  const [message, setMessage] = useState(null);
  const [withdrawRequest, setWithdrawRequest] = useState(null);
  const [balance, setBalance] = useState(0);

  const fetchWithdrawRequest = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/withdraw/getWithdrawsWithFilter?token=${token}`, {
      filter: {
        status: 0,
        userType: paymentType,
        ownerId: user.id
      }
    })
      .catch((err) => {
        if (err && err.data && err.data.status !== 404) {
          setMessage({ type: "error", message: "Bir hata oluştu." });
          setTimeout(() => {
            setMessage(null)
          }, 3000);
        }
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200 && response.data.withdraws.length > 0) {
          setWithdrawRequest(response.data.withdraws[0]);
        }
      });
  }

  const fetchBalance = () => {
    axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/withdraw/getBalance?token=${token}`, {
      filter: {
        userType: paymentType,
        ownerId: user.id
      }
    })
      .catch((err) => {
        if (err && err.data && err.data.status !== 404) {
          setMessage({ type: "error", message: "Bir hata oluştu." });
          setTimeout(() => {
            setMessage(null)
          }, 3000);
        }
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setBalance(response.data.balance.toFixed(2));
        }
      });
  }

  useEffect(() => {
    fetchWithdrawRequest();
    fetchBalance();
  }, []);

  const bankAccountInitialValues = {
    iban: user.iban ? user.iban : "",
    bank: user.bank ? user.bank : "",
    tax_office: user.tax_office ? user.tax_office : "",
    tax_no: user.tax_no ? user.tax_no : "",
    company_name: user.company_name ? user.company_name : "",
    address: user.address ? user.address : "",
  }


  const bankAccountValidationSchema = yup.object().shape({
    iban: yup.string().matches(/TR[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){3}([a-zA-Z0-9]{2})\s?/, 'Lütfen geçerli bir iban girin').required("zorunlu"),
    bank: yup.string().required("zorunlu"),
    tax_office: yup.string().required("zorunlu"),
    tax_no: yup.string().required("zorunlu"),
    company_name: yup.string().required("zorunlu"),
    address: yup.string().required("zorunlu"),
  }
  );

  const handleBankAccountFormSubmit = (values) => {
    if (paymentType === "partner") {
      axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/partner/updatePartner?token=${token}`, { partner: values })
        .catch((err) => {
          setMessage({ type: "error", message: "Bir hata oluştu." });
          setTimeout(() => {
            setMessage(null)
          }, 3000);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            setUser({ ...user, ...values });
            setMessage({ type: "success", message: "Hesap bilgileriniz başarıyla güncellendi." });
            setTimeout(() => {
              setMessage(null)
            }, 3000);
            fetchWithdrawRequest();
          }
        });
    } else {
      axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/influencer/updateInfluencer?token=${token}`, { influencer: values })
        .catch((err) => {
          setMessage({ type: "error", message: "Bir hata oluştu." });
          setTimeout(() => {
            setMessage(null)
          }, 3000);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 201) {
            setUser({ ...user, ...values });
            setMessage({ type: "success", message: "Hesap bilgileriniz başarıyla güncellendi." });
            setTimeout(() => {
              setMessage(null)
            }, 3000);
            fetchWithdrawRequest();
          }
        });
    }
  }

  const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false);

  const clickWithdrawRequestPositiveAnswer = (values) => {
    axios.post(`${process.env.REACT_APP_API_URL}api/alcago/withdraw/?token=${token}`, {
      withdraw: {
        owner_id: user.id,
        amount: values.withdrawAmount,
        user_type: paymentType
      }
    })
      .catch((err) => {
        setMessage({ type: "error", message: "Bir hata oluştu." });
        setTimeout(() => {
          setMessage(null)
        }, 3000);
        setWithdrawDialogOpen(false);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 201) {
          fetchWithdrawRequest();
          setWithdrawDialogOpen(false);
        }
      });
  }

  const clickWithdrawRequestNegativeAnswer = () => {
    setWithdrawDialogOpen(false);
  }

  const handleOpenWithdrawDialog = () => {
    if (user.iban) {
      if (withdrawRequest) {
        setMessage({ type: "warning", message: "Önceki çekim talebiniz sonuçlanmadan yeni çekim talebi oluşturamazsınız." });
        setTimeout(() => {
          setMessage(null)
        }, 3000);
      } else {
        if (!balance || balance < 200) {
          setMessage({ type: "warning", message: "Yetersiz bakiye! Minimum talep limiti: 200₺" });
          setTimeout(() => {
            setMessage(null)
          }, 3000);
        } else {
          setWithdrawDialogOpen(true);
        }
      }
    } else {
      setMessage({ type: "warning", message: "Çekim talebi yapmadan önce lütfen hesap bilgilerinizi doldurunuz." });
      setTimeout(() => {
        setMessage(null)
      }, 3000);
    }
  }

  yup.addMethod(yup.number, "remainderZero", function (errorMessage) {
    return this.test(`test-remainder`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        (value && value % 100 === 0) ||
        createError({ path, message: errorMessage })
      );
    });
  });


  const validationSchema = yup.object().shape({
    withdrawAmount: yup.number().min(200, "Çekim tutarı en az 200₺ olmalıdır.").max(balance, "Çekim tutarı bakiyenizden daha fazla olamaz.").remainderZero("100₺ ve katları çekilebilir.").required("zorunlu"),
  });

  const initialValues = {
    withdrawAmount: 0
  }

  return (
    <Box mt="20px">
      <Dialog open={withdrawDialogOpen} onClose={() => setWithdrawDialogOpen(false)}>
        <DialogTitle>Çeklim Talebi Oluştur</DialogTitle>
        <Formik
          onSubmit={clickWithdrawRequestPositiveAnswer}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>

                <DialogContentText maxWidth="300px">
                  Lütfen çekmek istediğiniz miktarı giriniz. Talebiniz en kısa sürede işleme alınacaktır. < br /> {`Tüm Bakiye : ${balance}₺`}
                </DialogContentText>
                <TextField
                  variant="filled"
                  type="number"
                  label="Miktar"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.withdrawAmount}
                  name="withdrawAmount"
                  error={!!touched.withdrawAmount && !!errors.withdrawAmount}
                  helperText={touched.withdrawAmount && errors.withdrawAmount}
                  sx={{ gridColumn: "span 2", mt: "10px" }}
                />
              </DialogContent>
              <DialogActions>

                <Box m="10px 10px 0px 0px" display="flex" gap="10px">
                  <Button type="submit" color="secondary" variant="contained">
                    <Typography variant='h6'>Onayla</Typography>
                  </Button>
                  <Button type="cancel" color="error" variant="contained" onClick={clickWithdrawRequestNegativeAnswer}>
                    <Typography variant='h6'>Vazgeç</Typography>
                  </Button>
                </Box>
              </DialogActions>

            </form>
          )}
        </Formik>
      </Dialog>
      {/* <AlertDialogSlide
        open={withdrawDialogOpen} handleClose={() => setWithdrawDialogOpen(false)}
        clickPositiveAnswer={clickWithdrawRequestPositiveAnswer} clickNegativeAnswer={clickWithdrawRequestNegativeAnswer}
        positiveAnswer={"Onayla"} negativeAnswer={"Vazgeç"}
        title={`Tüm bakineyinizi(${balance}₺) çekmek istediğinize emin misin?`}
        description={"Talebiniz incelenip en kısa sürede işleme alınacaktır."}
      /> */}
      <Box ml="20px">
        <Header title="Ödemeler" subtitle="Ödeme ayarları ve çekim talepleri" />
      </Box>
      {message && <Alert severity={message.type} sx={{ width: "50%" }}>{message.message}</Alert>}
      <Box mt="50px" mx="20px" pb="20px">
        <Box display="flex">
          <Typography variant='h3'>Hesap Bakiyesi: {balance}₺</Typography>
          <Button variant='contained' color='success' sx={{ ml: "30px" }} onClick={handleOpenWithdrawDialog}>Çekim Talebi Oluştur</Button>
        </Box>
        <Box mt="20px" p="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb="15px">
            <Typography variant='h4'>Hesap Bilgileri</Typography>
          </Box>
          <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
          <Box mt="20px">
            <Formik
              onSubmit={handleBankAccountFormSubmit}
              initialValues={bankAccountInitialValues}
              validationSchema={bankAccountValidationSchema}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <Box display="grid" gap="30px" gridTemplateColumns="repeat(5, minmax(0, 1fr))">
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Iban"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.iban}
                      name="iban"
                      error={!!touched.iban && !!errors.iban}
                      helperText={touched.iban && errors.iban}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Banka Adı"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.bank}
                      name="bank"
                      error={!!touched.bank && !!errors.bank}
                      helperText={touched.bank && errors.bank}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Vergi Dairesi"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.tax_office}
                      name="tax_office"
                      error={!!touched.tax_office && !!errors.tax_office}
                      helperText={touched.tax_office && errors.tax_office}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="T.C. no / Vergi no"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.tax_no}
                      name="tax_no"
                      error={!!touched.tax_no && !!errors.tax_no}
                      helperText={touched.tax_no && errors.tax_no}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Firma Adı / Ad Soyad"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.company_name}
                      name="company_name"
                      error={!!touched.company_name && !!errors.company_name}
                      helperText={touched.company_name && errors.company_name}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      multiline
                      minRows={2}
                      variant="filled"
                      type="text"
                      label="Adres"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address}
                      name="address"
                      error={!!touched.address && !!errors.address}
                      helperText={touched.address && errors.address}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="end">
                    <Button type="submit" color="secondary" variant="contained" sx={{ mt: "20px" }}>
                      <Typography variant='h6'>Kaydet</Typography>
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb="15px" mt="20px">
              <Typography variant='h4'>Bekleyen Talepler</Typography>
            </Box>
            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            <Box mt="15px">
              {!withdrawRequest && <Typography variant='h5'>Aktif çekim talebiniz bulunmamaktadır.</Typography>}
              {withdrawRequest && <WithdrawRequestBox withdraw={withdrawRequest} fetchWithdrawRequests={fetchWithdrawRequest} isAdmin={false} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box >
  )
}

export default Payments;