
import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material'
import { tokens } from '../theme';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

const CustomDoughnutChart = ({ filter, graph, profileFields }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user, token } = useContext(UserContext);
    const [data, setData] = useState({
        labels: ['Veri Yok'],
        datasets: [
            {
                label: '#',
                data: [0],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    });

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "bottom"
            }
        }
    };


    const getData = (responseData) => {
        let data = null;
        if (graph.type === "user") {
            data = responseData.users.map((user) => user.user_count);
        } else if (graph.type === "order") {
            if (graph.orderDataType === "total_earnings") {
                data = responseData.orders.totalOrders.map((order) => order.total_earning);
            } else if (graph.orderDataType === "alcago_comission") {
                data = responseData.orders.alcagoOrders.map((order) => order.total_earning);
            } else if (graph.orderDataType === "influencer_comission") {
                data = responseData.orders.influencerOrders.map((order) => order.total_earning);
            } else if (graph.orderDataType === "influencer_brand_comission") {
                data = responseData.orders.influencerBrandOrders.map((order) => order.total_earning);
            } else if (graph.orderDataType === "partner_comission") {
                data = responseData.orders.partnerOrders.map((order) => order.total_earning);
            } else if (graph.orderDataType === "influencer_partner_comission") {
                data = responseData.orders.influencerPartnerOrders.map((order) => order.total_earning);
            }
        } else if (graph.type === "userProfit") {
            data = responseData.orders.map((order) => order.total_profit);
        } else if (graph.type === "brandFavorites") {
            data = responseData.favorites.map((fav) => fav.user_count);
        } else if (graph.type === "coupon") {
            data = responseData.coupons.map((coupon) => coupon.coupon_count);
        } else if (graph.type === "ad") {
            data = responseData.watchLogs.map((watchLog) => watchLog.user_count);
        }



        if (data === undefined || data === null || data.length < 1) {
            return [0];
        } else {
            return data;
        }
    }

    const getLabels = (responseData) => {
        let data = null;
        if (graph.type === "user") {
            data = responseData.users
        } else if (graph.type === "order") {
            if (graph.orderDataType === "total_earnings") {
                data = responseData.orders.totalOrders;
            } else if (graph.orderDataType === "alcago_comission") {
                data = responseData.orders.alcagoOrders;
            } else if (graph.orderDataType === "influencer_comission") {
                data = responseData.orders.influencerOrders;
            } else if (graph.orderDataType === "influencer_brand_comission") {
                data = responseData.orders.influencerBrandOrders;
            } else if (graph.orderDataType === "partner_comission") {
                data = responseData.orders.partnerOrders;
            } else if (graph.orderDataType === "influencer_partner_comission") {
                data = responseData.orders.influencerPartnerOrders;
            }
        } else if (graph.type === "userProfit") {
            data = responseData.orders;
        } else if (graph.type === "brandFavorites") {
            data = responseData.favorites;
        } else if (graph.type === "coupon") {
            data = responseData.coupons;
        } else if (graph.type === "ad") {
            data = responseData.watchLogs;
        }


        if (data === undefined || data === null || data.length < 1) {
            return ['Veri Yok'];
        } else {
            return data.map((e) => {
                let rawLabels = graph.comparisons.split(", ").map((splittedStr) => splittedStr.split(".")[1]);
                let labelStr = "";
                for (let i = 0; i < rawLabels.length; i++) {
                    const element = rawLabels[i];
                    let value = e[element];
                    if (element === "have_a_car") {
                        value = value == 1 ? "Arabası Var" : "Arabası Yok"
                    }
                    if (element === "education") {
                        value = profileFields.education_statuses.find((e) => e.id === value).value;
                    }
                    if (element === "city_of_residence") {
                        value = profileFields.cities.find((e) => e.id === value).value;
                    }
                    if (element === "city_of_birth") {
                        value = profileFields.cities.find((e) => e.id === value).value;
                    }
                    if (element === "job") {
                        value = profileFields.jobs.find((e) => e.id === value).value;
                    }
                    if (element === "income_status") {
                        value = profileFields.income_statuses.find((e) => e.id === value).value;
                    }
                    if (element === "find_us_from") {
                        value = profileFields.findable_areas.find((e) => e.id === value).value;
                    }
                    if (element === "team") {
                        value = profileFields.teams.find((e) => e.id === value).value;
                    }
                    labelStr += value;
                    if (i !== rawLabels.length - 1) {
                        labelStr += " - "
                    }
                }
                return labelStr;
            });
        }

    }


    useEffect(() => {
        let path;
        if (graph.type === "user") {
            path = "user/getUserDoughnutStatistics";
        } else if (graph.type === "order") {
            path = "order/getOrderDoughnutStatistics";
        } else if (graph.type === "userProfit") {
            path = "order/getUserProfitsDoughnutStatistics";
        } else if (graph.type === "brandFavorites") {
            path = "brand/getFavoritedUserDoughnutStatistics";
        } else if (graph.type === "coupon") {
            path = "coupon/getGivenCouponsDoughnutStatistics";
        } else if (graph.type === "ad") {
            path = "ad/getAdWatchDoughnutStatistics";
        }
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/${path}?token=${token}`, { filter: filter, comparisonGraph: graph })
            .catch((err) => {
                console.log("err: " + err);
                setData({
                    labels: ['Veri Yok'],
                    datasets: [
                        {
                            label: '#',
                            data: [0],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.5)',
                                'rgba(54, 162, 235, 0.5)',
                                'rgba(255, 206, 86, 0.5)',
                                'rgba(75, 192, 192, 0.5)',
                                'rgba(153, 102, 255, 0.5)',
                                'rgba(255, 159, 64, 0.5)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setData({
                        labels: getLabels(response.data),
                        datasets: [
                            {
                                data: getData(response.data),
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.5)',
                                    'rgba(54, 162, 235, 0.5)',
                                    'rgba(255, 206, 86, 0.5)',
                                    'rgba(75, 192, 192, 0.5)',
                                    'rgba(153, 102, 255, 0.5)',
                                    'rgba(255, 159, 64, 0.5)',
                                ],
                                borderColor: [
                                    'rgba(255, 99, 132, 1)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 206, 86, 1)',
                                    'rgba(75, 192, 192, 1)',
                                    'rgba(153, 102, 255, 1)',
                                    'rgba(255, 159, 64, 1)',
                                ],
                                borderWidth: 1,
                            },
                        ],
                    });
                }
            });
    }, [filter]);

    return (
        <Box p="20px" sx={{ background: colors.primary[400], borderRadius: "20px" }}>
            <Typography variant='h5' mb="5px" textAlign="center">{graph.title}</Typography>
            {graph.type === "order" && user.type === "admin" &&
                <Typography variant='h6' mb="15px" textAlign="center">({
                    graph.orderDataType === "total_earnings" ?
                        "Sepet Tutarı" :
                        graph.orderDataType === "alcago_comission" ?
                            "Alcago" :
                            graph.orderDataType === "influencer_comission" ?
                                "Catcher Inviter" :
                                graph.orderDataType === "influencer_brand_comission" ?
                                    "Catcher Inviter(Brand)" :
                                    graph.orderDataType === "partner_comission" ?
                                        "Brand Inviter" : "Influencer Inviter"})</Typography>
            }
            {data.labels[0] === "Veri Yok" &&
                <Typography variant='h3' color={colors.redAccent[400]} textAlign="center">Veri Yok</Typography>
            }
            {data.labels[0] !== "Veri Yok" &&
                <Doughnut data={data} options={options} />
            }
        </Box>
    )
}

export default CustomDoughnutChart