import { Alert, Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { tokens } from '../../../theme';
import { useContext, useState } from 'react';
import { UserContext } from "../../../context/UserContext";
import AdminPanel from "../../panels/adminPanel";
import axios from "axios";
function AdminLogin() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { setToken, user, token, isLoading } = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState("");
    if (localStorage.getItem("userType") !== "admin") {
        setToken(null);
        localStorage.setItem("userType", "admin");
    }

    if (user && token) {
        return (<AdminPanel />)
    } else {
        const initialValues = {
            email: "",
            password: ""
        }

        const loginSchema = yup.object().shape({
            email: yup.string().email("Geçersiz eposta adresi").required("zorunlu"),
            password: yup.string().required("zorunlu")
        });

        const handleFormSubmit = (values) => {
            axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/admin/login`, values)
                .catch((err) => {
                    console.log("err: " + err);
                    if (err.response.data.status === 404) {
                        setErrorMessage("Kullanıcı bulunamadı");
                    } else if (err.response.data.status === 401) {
                        setErrorMessage("Parola hatalı");
                    }
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 200) {
                        setToken(response.data.token);
                    }
                });
        }

        if (isLoading) {
            return (
                <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" />
            )
        }
        return (
            <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                <Box p="10px 20px" borderRadius="5px" sx={{ background: colors.grey[600] }}>
                    <Typography variant='h3' color={colors.grey[100]} m="5px 0 20px 0"> Giriş </Typography>
                    {errorMessage !== "" && <Alert variant="outlined" severity="error" sx={{ mb: "10px" }} onClick={() => (setErrorMessage(""))}>{errorMessage}</Alert>}

                    <Box>
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={loginSchema}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="email"
                                            label="Eposta"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            name="email"
                                            error={!!touched.email && !!errors.email}
                                            helperText={touched.email && errors.email}
                                        />
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="password"
                                            label="Şifre"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                            name="password"
                                            error={!!touched.password && !!errors.password}
                                            helperText={touched.password && errors.password}
                                            sx={{ margin: "10px 0 0 0" }}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="end" m="20px 10px 5px 0">
                                        <Button type="submit" color="secondary" variant="contained">
                                            Giriş
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default AdminLogin;