import { Box, Button, Tab, Tabs, Typography, useTheme } from '@mui/material'
import axios from 'axios';
import React, { useContext, useState, useEffect } from 'react'
import AnnouncementBox from '../../../../components/AnnouncementBox';
import Header from '../../../../components/Header'
import { UserContext } from '../../../../context/UserContext'
import { tokens } from '../../../../theme'
import TabPanel, { a11yProps } from '../../../global/TabPanel';
import AnnouncementDialog from './announcementDialog';

function Announcements() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user, token } = useContext(UserContext);
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const [influencerAnnouncements, setInfluencerAnnouncements] = useState([]);
    const [partnerAnnouncements, setPartnerAnnouncements] = useState([]);
    const [influencerPartnerAnnouncements, setInfluencerPartnerAnnouncements] = useState([]);
    const [brandAnnouncements, setBrandAnnouncements] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchAnnouncements = () => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/announcement/getAllAnnouncements?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setInfluencerAnnouncements([]);
                setPartnerAnnouncements([]);
                setInfluencerPartnerAnnouncements([]);
                setBrandAnnouncements([]);
                setLoading(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setInfluencerAnnouncements(response.data.announcements.filter(({ type }) => type === "influencer"));
                    setPartnerAnnouncements(response.data.announcements.filter(({ type }) => type === "partner"));
                    setInfluencerPartnerAnnouncements(response.data.announcements.filter(({ type }) => type === "influencer_partner"));
                    setBrandAnnouncements(response.data.announcements.filter(({ type }) => type === "brand"));
                    setLoading(false);
                }
            });
    }

    useEffect(() => {
        fetchAnnouncements();
    }, []);


    const [openNewAnnouncementDialog, setOpenNewAnnouncementDialog] = useState(false);
    const handleNewAnnouncementDialogClose = () => {
        fetchAnnouncements();
        setOpenNewAnnouncementDialog(false);
    }
    const handleNewAnnouncementOpen = () => {
        setOpenNewAnnouncementDialog(true);
    }

    return (
        <Box mt="20px">
            <AnnouncementDialog open={openNewAnnouncementDialog} handleClose={handleNewAnnouncementDialogClose} type="new" />
            <Box ml="20px" display="flex">
                <Box>
                    <Header title="Duyurular" subtitle="Partnerlere, markalara ve influencerlara topluca duyuru yapabilirsiniz." />
                </Box>
                <Button variant='contained' color='success' sx={{ margin: "auto 20px" }} onClick={() => {
                    handleNewAnnouncementOpen();
                }}>
                    Yeni Duyuru
                </Button>
            </Box>
            <Box ml="20px" mr="100px">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor={theme.palette.mode === "dark" ? "secondary" : "primary"}
                        indicatorColor={theme.palette.mode === "dark" ? "secondary" : "primary"}>
                        <Tab label="CACHER INVITERS" {...a11yProps(0)} />
                        <Tab label="BRAND INVITERS" {...a11yProps(1)} />
                        <Tab label="INFLUENCER INVITERS" {...a11yProps(2)} />
                        <Tab label="BRANDS" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                {loading &&
                    <Box>
                    </Box>
                }
                {!loading &&
                    <Box>
                        <TabPanel value={value} index={0}>
                            {influencerAnnouncements && influencerAnnouncements.length > 0 &&
                                (influencerAnnouncements.map((announcement) =>
                                    <AnnouncementBox key={announcement.id} announcement={announcement} fetchAnnouncements={fetchAnnouncements} />
                                ))}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {partnerAnnouncements && partnerAnnouncements.length > 0 &&
                                (partnerAnnouncements.map((announcement) =>
                                    <AnnouncementBox key={announcement.id} announcement={announcement} fetchAnnouncements={fetchAnnouncements} />
                                ))}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {influencerPartnerAnnouncements && influencerPartnerAnnouncements.length > 0 &&
                                (influencerPartnerAnnouncements.map((announcement) =>
                                    <AnnouncementBox key={announcement.id} announcement={announcement} fetchAnnouncements={fetchAnnouncements} />
                                ))}
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            {brandAnnouncements && brandAnnouncements.length > 0 &&
                                (brandAnnouncements.map((announcement) =>
                                    <AnnouncementBox key={announcement.id} announcement={announcement} fetchAnnouncements={fetchAnnouncements} />
                                ))}
                        </TabPanel>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default Announcements;