import { Button, Rating, Tooltip, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { tokens } from '../theme';

function OrderCommentBox({ comment, isEditable, approveComment, deleteComment }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box display="inline-block" mr="10px" mb="15px" p="20px" sx={{ float: "left", background: (theme.palette.mode === "dark" ? colors.primary[500] : "white"), border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb="15px">
                <Box display="flex" alignItems="center">
                    <img
                        alt="profile-user"
                        src={comment.profile_photo_link}
                        width="40px"
                        height="40px"
                        style={{ cursor: "pointer", borderRadius: "50%" }} />
                    <Tooltip title={comment.username}>
                        <Typography variant='h4' ml="10px" maxWidth="140px" noWrap>{comment.name ? comment.name.charAt(0) + "*** " + comment.surname.charAt(0) + "***" : comment.username}</Typography>
                    </Tooltip>
                </Box>
                <Rating name="read-only" value={comment.point} precision={0.5} readOnly />
            </Box>
            <hr style={{ border: "1px solid " + (theme.palette.mode === "dark" ? "white" : "black") }} />
            {comment.comment.length > 240 &&
                <Tooltip title={comment.comment}>
                    <Typography variant='h6' mt="5px" minHeight="150px" width="280px" >{comment.comment.slice(0, 237) + "..."}</Typography>
                </Tooltip>
            }

            {comment.comment.length <= 240 &&
                <Typography variant='h6' mt="5px" minHeight="150px" width="280px" >{comment.comment}</Typography>
            }
            {isEditable &&
                <Box>
                    {comment.is_approved === 0 &&
                        <Button variant='contained' color="success" onClick={() => approveComment(comment.id)}>Onayla</Button>
                    }
                    <Button variant='contained' color="error" sx={{ ml: "10px" }} onClick={() => deleteComment(comment.id)}>Sil</Button>
                </Box>
            }
        </Box>
    )
}

export default OrderCommentBox