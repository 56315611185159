import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../../components/Header'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, trTR } from '@mui/x-data-grid'
import { tokens } from '../../../../theme';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../../../context/UserContext';
import RegisterDialog from '../../../loginPages/brandLogin/registerDialog';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function Brands() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { token } = useContext(UserContext);
  const navigate = useNavigate();
  const partner = window.location.pathname.split("/") && window.location.pathname.split("/").length === 5 ? { id: window.location.pathname.split("/")[window.location.pathname.split("/").length - 2], name: window.location.pathname.split("/")[window.location.pathname.split("/").length - 1].replaceAll("%20", " ") } : null;


  const columns = [
    {
      field: "logo_link", headerName: "Logo", headerAlign: "center", align: "center", width: 75,
      renderCell: ({ row: { logo_link } }) => {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center">
            <img
              alt="logo"
              src={logo_link}
              height="50px"
            />
          </Box>
        );
      }
    },
    { field: "name", headerName: "Marka Adı" },
    { field: "description", headerName: "Tanıtım", flex: 0.3 },
    { field: "view_count", headerName: "Görüntülenme Sayısı", flex: 0.1, type: "number", headerAlign: "right" },
    { field: "is_featured", headerName: "Öne Çıkarılmış", flex: 0.1, type: "boolean", headerAlign: "center" },
    { field: "is_active", headerName: "Aktif", flex: 0.1, type: "boolean", headerAlign: "center" },
    {
      field: "created_date", headerName: "Oluşturulma Tarihi", flex: 0.1, type: "dateTime", headerAlign: "center", align: "center",
      renderCell: ({ row: { created_date } }) => {
        return new Date(created_date).toLocaleDateString()
      }
    },
    {
      field: "id",
      headerName: "Detaylar",
      flex: 0.2,
      headerAlign: "center",
      renderCell: ({ row: { id } }) => {
        return (
          <Box width="50%" m="0 auto" p="5px" display="flex" justifyContent="center" backgroundColor={colors.greenAccent[600]} borderRadius="4px"
            onClick={() => {
              navigate(`/admin-panel/brand/${id}`);
            }}
            sx={{ cursor: "pointer" }}>
            <Typography variant='h5' color={colors.grey[100]}>
              Detay
            </Typography>
          </Box>
        );
      }
    }
  ];

  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false)
  const [brands, setBrands] = useState([]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setLoading(true);
    if (partner) {
      axios.get(`${process.env.REACT_APP_API_URL}api/alcago/partner/getPartnerBrands?token=${token}&partnerId=${partner.id}`)
        .catch((err) => {
          console.log("err: " + err);
          setBrands([]);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 200) {
            setBrands(response.data.brands);
            setLoading(false);
          }
        });
    } else {
      axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getAllBrands?token=${token}`)
        .catch((err) => {
          console.log("err: " + err);
          setBrands([]);
        })
        .then((response) => {
          if (response && response.data.success === true && response.data.status === 200) {
            setBrands(response.data.brands);
            setLoading(false);
          }
        });
    }

  }, [])


  return (
    <Box m="20px">
      <Box display="flex">
        <Box width="30%">
          <Header title={partner ? (partner.name + " Markaları") : "Markalar"} subtitle="Markaları buradan görebilir ve düzenleyebilirsiniz." />
        </Box>
        <Button variant='contained' color='success' sx={{ margin: "auto 0" }} onClick={() => {
          handleDialogOpen();
        }}>
          Yeni Marka Ekle
        </Button>
      </Box>
      <Box m="10px 0 0 0" height="75vh" sx={{
        "& .MuiDataGrid-columnHeaders": {
          background: colors.blueAccent[700]
        },
        "& .MuiDataGrid-footerContainer": {
          background: colors.blueAccent[700]
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: colors.grey[100]
        }
      }}>
        <DataGrid
          getRowId={(row) => row.name}
          rows={brands}
          rowHeight={75}
          columns={columns}
          hideFooterPagination
          loading={loading}
          disableColumnMenu
          components={{
            Toolbar: CustomToolbar
          }}
          localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
      <RegisterDialog open={openDialog} handleClose={handleDialogClose} type="new" isAddingFromAdminPanel={true} />
    </Box>
  )
}

export default Brands;