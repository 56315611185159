import React from 'react'
import { useState, useEffect, useContext } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogContent, TextField, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import * as yup from "yup";
import { Formik } from "formik";
import { tokens } from '../../../../theme';
import { UserContext } from '../../../../context/UserContext';

function AccessDialog({ open, handleClose, brandData }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.colors);
    const { token } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    function generateRandomString(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (var i = 0; i < length; i++) {
            var randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }

        return result;
    }

    var initialValues = {
        client_id: "",
        client_secret: "",
        ideasoft_code: generateRandomString(16)
    }
    var shapeObject = {
        client_id: yup.string().required("zorunlu"),
        client_secret: yup.string().required("zorunlu"),
        ideasoft_code: yup.string().required("zorunlu")
    }

    const brandSchema = yup.object().shape(shapeObject);

    const handleFormSubmit = async (values) => {
        setLoading(true);
        setData(values);
        axios.patch(`${process.env.REACT_APP_API_URL}api/alcago/brand/updateBrandWithName?name=${brandData.brand.name}`, { brand: values }).catch((err) => {
            console.log("err: " + err);
            setLoading(false);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                setTimeout(function () {
                    openIdeaSoftAprrovementPage(values);
                }, 2000);
                //fetch brand countinously
                fetchBrandAndCheckData();
            }
        });
    }

    const fetchBrand = async (cb) => {
        axios.get(`${process.env.REACT_APP_API_URL}api/alcago/brand/getBrandWithName?name=${brandData.brand.name}`).catch((err) => {
            console.log("err: " + err);
            cb(err, null);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 200) {
                cb(null, response.data.brand);
            } else {
                cb(null, null);
            }
        });
    }

    const fetchBrandAndCheckData = async () => {
        fetchBrand((err, brand) => {
            if (err || !brand) {
                //TODO: show error
                setLoading(false);
            } else {
                //check access_token and refresh_token is exists
                if (brand.access_token && brand.access_token !== "" && brand.refresh_token && brand.refresh_token !== "") {
                    //if exists check user and brand is_active
                    if (brand.is_active) {
                        //if active close dialog and login
                        handleClose(true);
                    } else {
                        //if not active close dialog and show error
                        handleClose(false);
                    }
                } else {
                    setTimeout(function () {
                        fetchBrandAndCheckData();
                    }, 3000);
                }
            }
        });
    }

    const openIdeaSoftAprrovementPage = async (values) => {
        window.open(`${brandData.brand.website}admin/user/auth/?client_id=${values.client_id}&state=${values.ideasoft_code}&response_type=code&redirect_uri=${process.env.REACT_APP_API_URL}api/alcago/brand/ideasoft-result`, "_blank")
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={open}>
            <Box m="20px" mb="0" textAlign="center">
                <Typography variant='h3'>
                    IdeaShop Kullanıcı Entegrasyonu
                </Typography>
            </Box>
            <DialogContent>
                {loading &&
                    <Box maxWidth="300px" mx="auto" textAlign="center">
                        <p>Eğer paneliniz bir kaç saniye içerisinde açılmadıysa <a href={`${brandData.brand.website}admin/user/auth/?client_id=${data.client_id}&state=${data.ideasoft_code}&response_type=code&redirect_uri=${process.env.REACT_APP_API_URL}api/alcago/brand/ideasoft-result`} target="_blank" rel="noreferrer"><u style={{ color: colors.blueAccent[600], cursor: "pointer" }}>buraya</u></a> tıklayarak panele ulaşabilirsiniz.</p>
                        <Box my="30px" display="flex" justifyContent="center">
                            <CircularProgress color="secondary" />
                        </Box>
                    </Box>
                }
                {!loading &&
                    <Box>
                        <p>
                            Hesabınızın oluşturulabilmesi ve Alcago ile tam entegrasyonun tamamlanabilmesi için sitenizin Admin panelinden <b>Entegrasyonlar-{">"}API-{">"}Ekle yolunu izleyerek</b> Alcago'ya yetki vermeniz gerekmektedir. <br />(Redirect URI: {`${process.env.REACT_APP_API_URL}api/alcago/brand/ideasoft-result`});
                        </p>
                        <p>
                            Yetkilendirme işlemini tamamladıktan sonra Admin panelinizde oluşan client_id ve client_secret kodlarını girerek işleme devam edin.
                        </p>
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={brandSchema}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px">
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="string"
                                            label="Client ID"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"client_id"}
                                            value={values.client_id}
                                            error={!!touched.client_id && !!errors.client_id}
                                            helperText={touched.client_id && errors.client_id}
                                            sx={{ gridColumn: "span 1" }}
                                        />

                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="string"
                                            label="Client Secret"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"client_secret"}
                                            value={values.client_secret}
                                            error={!!touched.client_secret && !!errors.client_secret}
                                            helperText={touched.client_secret && errors.client_secret}
                                            sx={{ gridColumn: "span 1" }}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="center" m="20px 20px 0px 20px">
                                        <Button type="submit" color="secondary" variant="contained">
                                            <Typography variant='h5'>Devam</Typography>
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                }
            </DialogContent>
        </Dialog>
    )
}

export default AccessDialog;